import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'
import { PriceField } from '../../../../components/formik/PriceField'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'
import { DepopAiListingSuggestionDefaultsConfig } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'

export const DepopListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    const { values } = props

    const suggestionDefaults = values.domainDetails.depop?.suggestionDefaults
    // as any is fine here because it gets parsed on form submit - we need access to the non-generic version of the config
    const unsafeSuggestionDefaults = suggestionDefaults as any as DepopAiListingSuggestionDefaultsConfig

    const isBuyerPays = unsafeSuggestionDefaults?.requiredDetails.shipWithDepop === false

    return (
        <>
            <RadioBoolGroupField
                label='Ship with Depop'
                name='domainDetails.depop.suggestionDefaults.requiredDetails.shipWithDepop'
                iconType='Radio'
                options={[
                    [true, 'Depop Shipping'],
                    [false, 'Other'] // TODO: Fix text
                ]}
                logOnChange
            />
            {isBuyerPays && (
                <PriceField
                    name='domainDetails.depop.suggestionDefaults.optionalDetails.shippingPrice'
                    label='Shipping Price'
                    logOnBlur
                    initialValue={unsafeSuggestionDefaults?.optionalDetails.shippingPrice}
                />
            )}
        </>
    )
}
