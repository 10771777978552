import { Box } from '@mui/material'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxButton } from '../components/common/FoxButton'
import { FallbackRender } from '@sentry/react'
import { FoxImage } from '../components/common/FoxImage'
import { CommonImages } from '../theme/CommonImages'
import { Logger } from '../lib/clients/Logger'
import * as Intercom from '@intercom/messenger-js-sdk'

// This will only show up in production
// In development, the error gets re-thrown and logged to the console
export const ErrorFallbackScreen: FallbackRender = ({ resetError, error, eventId, componentStack }) => {
    const openIntercom = () => {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'ShowIntercom',
                entry: {
                    eventId,
                    componentStack
                }
            },
            message: 'Opening Intercom from error fallback screen'
        })
        Intercom.show()
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', maxWidth: '600px' }}>
                <FoxImage src={CommonImages.general.foxtailLogo} />

                <FoxTypography variant='h5' style={{ marginBottom: '16px' }}>
                    Sorry, something went wrong.
                </FoxTypography>

                <FoxTypography variant='body2' light style={{ marginBottom: '32px', textAlign: 'center' }}>
                    Our development team has been notified and we will fix it as soon as possible.
                </FoxTypography>

                <FoxButton
                    primary
                    text={'Go back to dashboard'}
                    onFoxClick={{
                        kind: 'internal',
                        to: '/app/home'
                    }}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '16px',
                        width: '100%'
                    }}
                />
                <FoxButton
                    grey
                    text={'Contact us'}
                    onFoxClick={{
                        onClick: openIntercom,
                        kind: 'button'
                    }}
                    sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                />
            </Box>
        </Box>
    )
}
