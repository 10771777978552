import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { ListingInstance } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingInstance'
import { useNavigate } from 'react-router-dom'

type ListingCreatedNotificationProps = {
    listing?: ListingSchema
    listingInstanceId: string
    imageId?: string
    logError: (message: string, error?: any) => void
}

export const ListingSuccessfulPublishNotification = (props: ListingCreatedNotificationProps): JSX.Element => {
    const { listing, listingInstanceId, imageId, logError } = props
    const navigate = useNavigate()

    const handleGoToListing = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    if (!listing || !imageId || listing.listingDescription.kind === 'draft') {
        return <></>
    }

    if (!listingInstanceId) {
        logError('Listing instance id should exist')
        return <></>
    }

    const listingInstance = listing.listingDescription.listingInstances.find(li => {
        return li._id === listingInstanceId
    })

    if (!listingInstance) {
        logError('Listing instance should exist')
        return <></>
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FoxImage src={CommonImages.markets[listingInstance.domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {listing.listingDescription.listingDetails.commonDetails?.title} has been published to {Domains[listingInstance.domainName].displayName}!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                Foxtail will monitor your listing for sales until inventory depletes; View the summary by clicking the product image in the listing tab.
            </FoxTypography>
            <FoxButton text='View listing' onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
