import { EngagementBoosterSpecificFieldsProps } from '../EngagementBoosterSpecificFieldsMap'
import { RadioBoolGroupField } from '../../../components/formik/RadioGroupField'
import { round } from 'lodash'
import { NumberField } from '../../../components/formik/NumberField'
import { useFormikContext } from 'formik'
import { EngagementBoostersData } from '../../../screens/engagementBoosters/EngagementBoostersScreen'

export const PoshmarkShareClosetFields = (props: EngagementBoosterSpecificFieldsProps) => {
    const { values } = useFormikContext<EngagementBoostersData>()
    const poshmarkShareClosetValues = values.poshmarkShareCloset
    const sharePeriodMs = poshmarkShareClosetValues?.sharePeriodMs

    const convertSharePeriodToHours = (valueInMs: string) => {
        const number = parseFloat(valueInMs)
        if (isNaN(number)) {
            return '0'
        }

        return round(number / 1000 / 60 / 60, 2).toString()
    }

    const convertSharePeriodToMs = (value: string) => {
        const number = parseFloat(value)
        if (isNaN(number)) {
            return '0'
        }

        return (number * 60 * 60 * 1000).toString()
    }

    return (
        <>
            <RadioBoolGroupField
                name={'poshmarkShareCloset.shouldShareUsersCloset'}
                options={[
                    [true, 'Yes'],
                    [false, 'No']
                ]}
                iconType={'Radio'}
                label='Share your closet?'
                logOnChange
            />
            <NumberField
                name={'poshmarkShareCloset.sharePeriodMs'}
                label='Share period'
                labelVariant='body1'
                suffix='hour(s)'
                initialValue={sharePeriodMs?.toString()}
                transformText={convertSharePeriodToMs}
                transformDisplayedText={convertSharePeriodToHours}
                logOnBlur
            />
        </>
    )
}
