import { assert, DomainCommonName } from '@foxtail-dev/datacontracts'
import { FoxButton } from '../../components/common/FoxButton'
import { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import {
    ListingTabKind,
    listingTabKindToAttributeMap,
    ListingTrackedTabKind,
    selectLoggedInDomains,
    selectUserId,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { Header } from '../../components/common/Header'
import {
    CachedAdvancedQueryMetadataResponse,
    DEFAULT_LISTING_PAGE_SIZE,
    getListingsByTitle,
    getPageOfListings,
    refreshCurrentListingQuery,
    refreshUntrackedListings,
    selectUntrackedListingIds
} from '@foxtail-dev/user-clients/dist/lib/redux/reducers/listings'
import { ListingTable } from '../../containers/listing/ListingTable'
import { sharedStyles } from '../../theme/SharedStyling'
import { FoxSearchBar } from '../../components/common/FoxSearchBar'
import { ZeroListings } from '../../components/listing/ZeroListings'
import { Logger } from '../../lib/clients/Logger'
import { generateToast } from '../../lib/clients/ToastClient'
import { ListingsMarketFilterMenu } from '../../components/listing/ListingsMarketFilterMenu'
import { FlexGrow } from '../../components/common/FlexGrow'
import { ListingMoreMenu } from '../../components/listing/ListingMoreMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    AdvancedListingQueries_ByTitleRequest,
    AdvancedListingQueries_SearchRequest
} from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxIconButton } from '../../components/common/FoxIconButton'
import { Refresh } from '@mui/icons-material'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { ListingTableRow } from '../../containers/listing/ListingTableRow'

// TODO: Use listing tab kind
const TAB_MAPPING: Record<ListingTabKind, string> = {
    active: 'Active',
    draft: 'Draft',
    sold: 'Sold',
    deleted: 'Deleted',
    untracked: 'Untracked'
}

export const ListingsScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const loggedInDomains = useAppSelector(selectLoggedInDomains)

    const queryParams = new URLSearchParams(location.search)

    const queryTab = queryParams.get('tab')

    const changeTab = (newTab: ListingTabKind, replace: boolean = false) => {
        const newParams = new URLSearchParams(location.search)
        newParams.set('tab', newTab)
        navigate(`${location.pathname}?${newParams.toString()}`, { replace })
    }

    useEffect(() => {
        const parseResult = ListingTabKind.safeParse(queryParams.get('tab'))

        if (parseResult.success) {
            setTab(parseResult.data)
            setHasTabBeenSet(true)
        } else {
            changeTab('active', true)
        }
    }, [queryTab])

    const [tab, setTab] = useState<ListingTabKind>('active')
    const [hasTabBeenSet, setHasTabBeenSet] = useState<boolean>(false) // Used to prevent double fetching of listings when the page initially loads

    const [currentQuery, setCurrentQuery] = useState<CachedAdvancedQueryMetadataResponse | null>(null)
    const [isLoadingListings, setIsLoadingListings] = useState<boolean>(false)

    // This has to be store here and not the listing table so that search can reset the page
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(DEFAULT_LISTING_PAGE_SIZE)
    const [syntheticIsLoading, setSyntheticIsLoading] = useState<boolean>(true)

    const [search, setSearch] = useState('')
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null)
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const usingSearchedListings = search !== ''
    const untrackedListingIds = useAppSelector(selectUntrackedListingIds)

    const listingIdsShown: string[] = tab === 'untracked' ? untrackedListingIds : currentQuery?.listingIds ?? []
    const userId = useAppSelector(selectUserId) ?? ''

    const [activeMarkets, setActiveMarkets] = useState<DomainCommonName[]>(DomainCommonName.options)

    const getPageOfListingsAsync = async (query: AdvancedListingQueries_SearchRequest) => {
        setIsLoadingListings(true)
        setSyntheticIsLoading(true)

        try {
            const result = await dispatch(getPageOfListings(query)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User got listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        query,
                        result
                    }
                }
            })

            setCurrentQuery(result)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error getting listings',
                    payload: {
                        kind: 'errorGettingListings',
                        entry: {
                            query
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error getting listings' })
        }
        setIsLoadingListings(false)
    }

    const searchListingsByTitleAsync = async (query: AdvancedListingQueries_ByTitleRequest) => {
        try {
            Logger.I().log({
                level: 'info',
                message: 'User searched listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        query
                    }
                }
            })

            const result = await dispatch(getListingsByTitle(query)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User searched listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        query,
                        result
                    }
                }
            })

            setCurrentQuery(result)
            setIsSearching(false)
            setIsLoadingListings(false)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error searching listings',
                    payload: {
                        kind: 'errorSearchingListings',
                        entry: {
                            search
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error searching listings' })
        }
    }

    const refreshListings = async () => {
        if (tab === 'untracked') {
            await refreshUntrackedListingsAsync()
        } else {
            await refreshTrackedListings(tab)
        }
    }

    const refreshTrackedListings = async (tabKind: ListingTrackedTabKind) => {
        setIsLoadingListings(true)
        setSyntheticIsLoading(true)

        setIsSearching(false)
        try {
            assert(currentQuery, 'Current query is null')
            let result: CachedAdvancedQueryMetadataResponse
            const shouldSearchByTitle = currentQuery.searchingByTitle && search !== ''
            if (shouldSearchByTitle) {
                const searchQuery: AdvancedListingQueries_ByTitleRequest = {
                    title: search,
                    userId,
                    activeOnAtLeastOneOfDomains: activeMarkets,
                    attributeKind: listingTabKindToAttributeMap[tabKind]
                }
                result = await dispatch(getListingsByTitle(searchQuery)).unwrap()
                return
            } else {
                result = await dispatch(refreshCurrentListingQuery(currentQuery.currentQuery)).unwrap()
            }

            Logger.I().log({
                level: 'info',
                message: 'User refreshed listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        currentQuery: currentQuery.currentQuery,
                        searchingByTitle: currentQuery.searchingByTitle,
                        result
                    }
                }
            })
            setCurrentQuery(result)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error refreshing listings',
                    payload: {
                        kind: 'errorRefreshingListings',
                        entry: {
                            currentQuery
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error refreshing listings' })
        }
        setIsLoadingListings(false)
    }

    useEffect(() => {
        setTimeout(() => {
            setSyntheticIsLoading(false)
        }, 1500)
    }, [currentPage, tab, search, activeMarkets, isLoadingListings])

    const refreshUntrackedListingsAsync = async () => {
        try {
            const result: ListingSchema[] = await dispatch(refreshUntrackedListings()).unwrap()
            const listingIds = result.map((listing) => listing._id)

            Logger.I().log({
                level: 'info',
                message: 'User got untracked listings',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        count: listingIds.length,
                        listingIds
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error getting untracked listings',
                    payload: {
                        kind: 'errorGettingUntrackedListings'
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Error getting untracked listings' })
        }
    }

    useEffect(() => {
        if (usingSearchedListings || tab === 'untracked' || !hasTabBeenSet) {
            return
        }

        const query: AdvancedListingQueries_SearchRequest = {
            pageNumber: currentPage,
            pageSize: pageSize,
            request: {
                userId: userId,
                activeOnAtLeastOneOfDomains: activeMarkets,
                attributeKind: listingTabKindToAttributeMap[tab]
            }
        }

        getPageOfListingsAsync(query)
    }, [tab, hasTabBeenSet, activeMarkets, pageSize, currentPage, usingSearchedListings])

    useEffect(() => {
        setCurrentPage(0)
        if (search === '') {
            setIsSearching(false)
            if (searchTimeout) {
                clearTimeout(searchTimeout)
            }
            return
        }

        setIsSearching(true)
        setIsLoadingListings(true)

        // Clear any existing timeout
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        // Set a new timeout for the search
        const newTimeout = setTimeout(async () => {
            if (tab === 'untracked') {
                return
            }

            const query: AdvancedListingQueries_ByTitleRequest = {
                title: search,
                userId,
                activeOnAtLeastOneOfDomains: activeMarkets,
                attributeKind: listingTabKindToAttributeMap[tab]
            }

            await searchListingsByTitleAsync(query)
        }, 500)

        // Save the timeout so it can be cleared later
        setSearchTimeout(newTimeout)

        return () => {
            if (searchTimeout) {
                clearTimeout(searchTimeout)
            }
        }
    }, [search, tab, activeMarkets])

    const updateActiveMarkets = (market: DomainCommonName) => {
        const isAlreadyActive = activeMarkets.includes(market)
        if (isAlreadyActive && activeMarkets.length > 1) {
            setActiveMarkets([...activeMarkets.filter((m) => m !== market)])
        } else if (!isAlreadyActive) {
            setActiveMarkets([...activeMarkets, market])
        }
        setCurrentPage(0)
    }

    const onSetTab = (event: any, tab: string) => {
        setSyntheticIsLoading(true)
        const tabKind = ListingTabKind.parse(tab)
        setTab(tabKind)
        setCurrentPage(0)
    }

    return (
        <Box sx={[sharedStyles.flexColumn, sharedStyles.fullSize]}>
            <Header
                title='Listings'
                rightComponent={
                    <FoxButton
                        primary
                        variant='contained'
                        text={loggedInDomains.length > 0 ? '+ New Listing' : '+ Connect Markets'}
                        onFoxClick={{
                            kind: 'internal',
                            to: loggedInDomains.length > 0 ? '/app/create-listing/new' : '/app/account/marketplace-connections'
                        }}
                    />
                }
                rightComponent2={<ListingMoreMenu />}
            />
            <Tabs value={tab} onChange={onSetTab}>
                {Object.entries(TAB_MAPPING).map(([tabKey, tabValue]) => {
                    if (untrackedListingIds.length === 0 && tabKey === 'untracked') {
                        return null
                    }
                    return <Tab key={tabKey} value={tabKey} label={tabValue} />
                })}
            </Tabs>
            <Box sx={{ display: 'flex', marginTop: '28px', marginBottom: '28px', alignItems: 'center' }}>
                <FoxSearchBar label={'Search listings'} value={search} onChange={setSearch} loading={isSearching} sx={{ margin: 0 }} />
                <FlexGrow />
                {currentQuery && (
                    <FoxIconButton
                        grey
                        onFoxClick={{
                            onClick: refreshListings,
                            kind: 'button',
                            preventDoubleClick: true
                        }}
                        sx={{ height: '48px', width: '48px', marginRight: '16px', borderRadius: '10px' }}>
                        <Refresh />
                    </FoxIconButton>
                )}

                {tab === 'untracked' && (
                    <FoxButton
                        grey
                        variant='contained'
                        text={'What are these listings?'}
                        onFoxClick={{
                            kind: 'external',
                            href: 'https://help.foxtail.ai/en/articles/9803586-untracked-listings'
                        }}
                    />
                )}
                {tab !== 'untracked' && (
                    <ListingsMarketFilterMenu
                        availableMarkets={DomainCommonName.options}
                        activeMarkets={activeMarkets}
                        updateActiveMarkets={updateActiveMarkets}
                    />
                )}
            </Box>
            {!currentQuery && !isLoadingListings && syntheticIsLoading === false ? (
                <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FoxTypography variant='body1' sx={{ color: 'text.secondary' }}>
                        Failed to find listings
                    </FoxTypography>
                    <FoxButton
                        text={'Try again'}
                        disabled={isLoadingListings}
                        onFoxClick={{
                            onClick: refreshListings,
                            kind: 'button',
                            preventDoubleClick: true
                        }}
                    />
                </Box>
            ) : listingIdsShown.length === 0 && !isLoadingListings && syntheticIsLoading === false ? (
                <ZeroListings listingKind={TAB_MAPPING[tab]} />
            ) : (
                <>
                    {tab === 'untracked' && (
                        <FoxTypography light variant='body1' sx={{ marginBottom: '16px' }}>
                            These listings are not currently tracked by Foxtail. You may relist each listing on its original markets, edit, or delete the
                            listing.
                        </FoxTypography>
                    )}
                    <ListingTable
                        listingIds={listingIdsShown}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        hasMoreListings={currentQuery?.isMoreToFetch ?? false}
                        showAllListings={usingSearchedListings}
                        isLoading={isLoadingListings || syntheticIsLoading}
                        listingRowComponent={ListingTableRow}
                        tab={tab}
                    />
                </>
            )}
        </Box>
    )
}
