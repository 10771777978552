import { Box } from '@mui/material'
import {
    copyListing,
    deleteActiveListing,
    generateListingHistory,
    getListingById,
    getPublicListingUrlFromListing,
    getSelectedMarkets,
    relistListing,
    selectListingById,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { assert, DomainCommonName } from '@foxtail-dev/datacontracts'
import { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Logger } from '../../lib/clients/Logger'
import { generateToast } from '../../lib/clients/ToastClient'
import { DeleteDraftListingConfirmationModal } from '../../modals/listing/DeleteDraftListingConfirmationModal'
import { BackToListingHeader } from '../../components/listing/listingSummary/BackToListingsHeader'
import { ItemInformation } from '../../components/listing/listingSummary/ItemInformation'
import { ListingSummaryTitleRow } from '../../components/listing/listingSummary/ListingSummaryTitleRow'
import { ListingHistory } from '../../components/listing/listingSummary/ListingHistory'
import { ListingMedia } from '../../components/listing/listingSummary/ListingMedia'
import { LiveListingTable } from '../../components/listing/listingSummary/LiveListingTable'

export const ListingSummaryScreen = (): JSX.Element => {
    const params = useParams()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const listingId = params.listingId

    const listing = useAppSelector(selectListingById(listingId ?? ''))

    useEffect(() => {
        if (!listingId) {
            return
        }

        // If the listing is not in redux, fetch it from the backend
        if (!listing) {
            dispatch(getListingById(listingId))
        }
    }, [listingId, listing])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const activeMarkets = getSelectedMarkets(listing.listingDescription.listingDetails)
    const listingDetails = listing?.listingDescription.listingDetails

    const commonDetails = listingDetails.commonDetails
    const history = generateListingHistory(listing)

    const imageIds = listingDetails?.imageIds ?? []

    const [isCopyingAsNew, setIsCopyingAsNew] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    // TODO: Get the link preemptively and make the button a real <a> link element
    const onViewLiveListing = (domainName: DomainCommonName) => {
        const url = getPublicListingUrlFromListing(ListingSchema.parse(listing), domainName)

        if (url) {
            window.open(url, '_blank')
        } else {
            generateToast({ kind: 'info', message: 'Cannot navigate to listing at this time.' })
        }
    }

    const onEdit = async () => {
        navigate(`/app/edit-listing/${listingId}`)
    }

    const onCopyAsNew = async () => {
        if (isCopyingAsNew || !listingId) return
        setIsCopyingAsNew(true)
        try {
            const newListingId = await dispatch(copyListing(listingId)).unwrap()
            navigate(`/app/create-listing/${newListingId}`)

            Logger.I().log({
                level: 'info',
                message: 'User copied listing as new',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        previousListingId: listingId,
                        newListingId
                    }
                }
            })
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Threw while trying to copy listing',
                    payload: {
                        kind: 'CopyAsNewError',
                        entry: {
                            previousListingId: listingId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Unable to copy as new' })
        }
        setIsCopyingAsNew(false)
    }
    const onDeleteInitiated = () => {
        setIsDeleteModalOpen(true)
    }

    const onDeleteModalClose = (result: boolean) => {
        setIsDeleteModalOpen(false)
        if (result) {
            deleteListing()
        }
    }

    const deleteListing = async () => {
        if (isDeleting || !listingId) {
            return
        }

        setIsDeleting(true)
        try {
            await dispatch(deleteActiveListing(listingId)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User deleted active listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId
                    }
                }
            })

            navigate('/app/listings')
        } catch (error) {
            generateToast({ kind: 'info', message: 'Unable to delete listing', subText: 'Please try again later or contact support' })

            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to delete active listing',
                    payload: {
                        kind: 'DeleteActiveListingError',
                        entry: {
                            listingId
                        }
                    }
                },
                error
            )
        }
        setIsDeleting(false)
    }

    const onRelistListing = async () => {
        try {
            assert(listingId, 'Listing ID is not defined')
            await dispatch(relistListing(listingId)).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User relisted listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        listingKind: listing.listingDescription.kind,
                        selectedMarkets: activeMarkets
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to relist listing',
                    payload: {
                        kind: 'RelistListingError',
                        entry: {
                            listingId,
                            listingKind: listing.listingDescription.kind,
                            selectedMarkets: activeMarkets
                        }
                    }
                },
                error
            )
        }
    }

    const inventory = useMemo(() => {
        if (listing && listing.listingDescription.kind === 'active') {
            const soldListings = listing.listingDescription.listingInstances.filter((listingInstance) => listingInstance.completionKind === 'sold').length
            return listing.listingDescription.listingDetails.commonDetails.quantity - soldListings
        } else return null
    }, [listing])

    return (
        <Box sx={styles.container}>
            <BackToListingHeader />

            <ListingSummaryTitleRow
                commonDetails={commonDetails}
                listingId={listing._id}
                listingKind={listing.listingDescription.kind}
                isUntracked={activeMarkets.length === 0}
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                handleClick={handleClick}
                onCopyAsNew={onCopyAsNew}
                onDeleteInitiated={onDeleteInitiated}
                onRelistListing={onRelistListing}
                onEdit={onEdit}
            />
            <Box sx={styles.containerDetails}>
                <Box sx={{ flex: 3 }}>
                    <ItemInformation commonDetails={listingDetails.commonDetails} inventory={inventory} />

                    <LiveListingTable activeMarkets={activeMarkets} onViewLiveListing={onViewLiveListing} />
                    <ListingHistory history={history} />
                </Box>

                <Box sx={{ flex: 2 }}>
                    <ListingMedia imageIds={imageIds} />
                </Box>
            </Box>

            <DeleteDraftListingConfirmationModal open={isDeleteModalOpen} onClose={onDeleteModalClose} />
        </Box>
    )
}

const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    containerDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '80px'
    }
}
