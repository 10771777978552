import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'
import {
    useAppSelector,
    selectBulkCrosslistingListingItemsCount,
    selectBulkCrosslistingListingDomains,
    selectBulkCrosslistingSessionStatus,
    createDomainNameList
} from '@foxtail-dev/user-clients'
import { FoxCircleLoader } from '../../components/LoadingCircle'
import { useNavigate } from 'react-router-dom'

type BulkCrosslistListingModalProps = {
    open: boolean
}

export const BulkCrosslistListingModal = ({ open }: BulkCrosslistListingModalProps) => {
    const navigate = useNavigate()

    const numberOfCrosslistingItems = useAppSelector(selectBulkCrosslistingListingItemsCount)
    const destinationDomains = useAppSelector(selectBulkCrosslistingListingDomains)
    const status = useAppSelector(selectBulkCrosslistingSessionStatus)

    const itemHasStr = numberOfCrosslistingItems === 1 ? 'item has' : 'items have'

    const onClose = () => {
        navigate('/app/listings')
    }

    return (
        <FoxModalDialog
            open={open}
            title={'Success!'}
            leftButtonKind={'close'}
            onClose={onClose}
            actions={
                <FoxButton
                    primary
                    text='Go to dashboard'
                    onFoxClick={{ kind: 'internal', to: '/app/listings' }}
                    sx={{ width: '100%', justifyContent: 'center' }}
                />
            }>
            <Box sx={styles.container}>
                <Box sx={styles.containerLoading}>
                    {status === 'listing' ? (
                        <>
                            <FoxCircleLoader size={60} />
                            <FoxTypography variant='h6' sx={styles.textLoading}>
                                Listing {numberOfCrosslistingItems} items to {createDomainNameList(destinationDomains)}
                            </FoxTypography>
                            <FoxTypography variant='subtitle1' sx={styles.textLeavePage} light>
                                You can leave this screen and come back later to check on the progress. Listing may take more than 10 minutes depending on
                                number of listings.
                            </FoxTypography>
                        </>
                    ) : (
                        <FoxTypography variant='h6' sx={styles.textLoading}>
                            Success! Your {numberOfCrosslistingItems} {itemHasStr} been crosslisted to {createDomainNameList(destinationDomains)}
                        </FoxTypography>
                    )}
                </Box>
            </Box>
        </FoxModalDialog>
    )
}

const styles = {
    container: {
        minHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerLoading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1
    },
    textLoading: {
        marginTop: '32px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    },
    button: {
        width: '100%',
        justifyContent: 'center'
    },
    textLeavePage: {
        marginTop: '24px',
        textAlign: 'center',
        marginLeft: '60px',
        marginRight: '60px'
    }
}
