import { Accordion, AccordionSummary, AccordionDetails, AccordionProps, Box, CircularProgress, Divider } from '@mui/material'
import { useMemo, useState } from 'react'
import { ChevronDownIcon } from '../components/icons/ChevronDownIcon'
import { FoxTypography } from '../components/common/FoxTypography'
import { ChevronUpIcon } from '../components/icons/ChevronUpIcon'
import { Colors, selectHydratedQuestsSorted, useAppSelector } from '@foxtail-dev/user-clients'
import { ChecklistAccordionRow } from '../containers/home/CheckListAccordionRow'
import { HydratedUserQuest } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'
import { SchoolHatIcon } from '../components/icons/SchoolHatIcon'
import { questKindToButtonTextMap, questKindToRouteMap } from '../utils/checklistInfo'
import { getTopPriorityIncompleteQuest } from '../utils/getTopPriorityIncompleteQuest'

const ChecklistAccordion: React.FC = () => {
    const quests = useAppSelector(selectHydratedQuestsSorted)

    const totalQuestsCompleted = useMemo(() => {
        return quests.filter((quest) => quest.quest.details.status === 'completed').length
    }, [quests])

    const topPriorityIncompleteQuest = useMemo(() => {
        return getTopPriorityIncompleteQuest(quests)
    }, [quests])

    const [expanded, setExpanded] = useState<boolean>(false)

    const handleChange: AccordionProps['onChange'] = (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded)
    }

    if (quests.length === 0 || !topPriorityIncompleteQuest) {
        return <></>
    }

    return (
        <Box
            style={{
                width: expanded ? '400px' : '220px',
                transition: 'width 0.3s',
                backgroundColor: Colors.light.tertiary,
                borderRadius: '10px',
                padding: expanded ? '2px' : '10px'
            }}>
            <Accordion expanded={expanded} onChange={handleChange} sx={{ backgroundColor: Colors.light.tertiary, boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={
                        expanded ? <ChevronUpIcon width={10} color={Colors.light.background} /> : <ChevronDownIcon width={10} color={Colors.light.background} />
                    }
                    aria-controls='panel-content'
                    id='panel-header'
                    sx={{
                        '& .MuiAccordionSummary-content': {
                            margin: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        },
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            alignSelf: 'center'
                        }
                    }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={styles.containerProgress}>
                                {totalQuestsCompleted > 0 ? (
                                    <>
                                        <CircularProgress
                                            variant='determinate'
                                            value={quests.length > 0 ? (totalQuestsCompleted / quests.length) * 100 : 0}
                                            size={16}
                                            sx={{
                                                color: Colors.light.success,
                                                position: 'relative',
                                                zIndex: 2
                                            }}
                                            thickness={6}
                                        />

                                        <CircularProgress
                                            variant='determinate'
                                            value={100}
                                            size={16}
                                            sx={{
                                                color: Colors.light.divider,
                                                position: 'absolute',
                                                left: 0,
                                                zIndex: 1
                                            }}
                                            thickness={6}
                                        />
                                    </>
                                ) : (
                                    <SchoolHatIcon height={18} width={19} color={Colors.light.background} />
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <FoxTypography variant='body1' sx={{ fontWeight: 500, lineHeight: '32px' }} color={Colors.light.background}>
                                    Get started
                                </FoxTypography>
                            </Box>
                        </Box>
                        {!expanded && (
                            <FoxTypography variant='body2' sx={{ fontSize: '12px', lineHeight: '15px', marginRight: '4px' }} color={Colors.light.background}>
                                {topPriorityIncompleteQuest ? topPriorityIncompleteQuest.data.title : 'Tasks completed'}
                            </FoxTypography>
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {quests.map((quest: HydratedUserQuest, index) => {
                        const questKind = quest.data.kind
                        const isLastQuest = index === quests.length - 1
                        return (
                            <Box key={questKind}>
                                <ChecklistAccordionRow
                                    quest={quest}
                                    isTopPriorityIncompleteQuest={topPriorityIncompleteQuest.data.kind === questKind}
                                    buttonText={questKindToButtonTextMap[questKind]}
                                    route={questKindToRouteMap[questKind]}
                                    setExpanded={setExpanded}
                                />
                                {!isLastQuest && <Divider sx={{ height: '1px' }} />}
                            </Box>
                        )
                    })}
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

const styles = {
    containerProgress: {
        marginRight: '10px',
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
}

export default ChecklistAccordion
