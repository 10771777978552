import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Logger } from '../lib/clients/Logger'
import ReactGA from 'react-ga4'

// TODO: Log query params of both route, not just path name
const useNavigationLogger = () => {
    const location = useLocation()
    const previousLocationRef = useRef(location)
    const timestampRef = useRef(Date.now())

    useEffect(() => {
        const previousRouteName = previousLocationRef.current.pathname
        const currentRouteName = location.pathname

        if (previousRouteName !== currentRouteName) {
            const elapsedTimeInSeconds = (Date.now() - timestampRef.current) / 1000
            timestampRef.current = Date.now()

            Logger.I().log({
                level: 'info',
                payload: {
                    kind: 'NavigationChange',
                    entry: {
                        previousRouteName,
                        currentRouteName,
                        params: location.state,
                        search: location.search,
                        timeOnPreviousRouteInSeconds: elapsedTimeInSeconds
                    }
                },
                message: `User navigated from ${previousRouteName} to ${currentRouteName}`
            })

            ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search })

            previousLocationRef.current = location
        }
    }, [location])
}

export default useNavigationLogger
