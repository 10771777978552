import { Listing, ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { FoxButton } from '../common/FoxButton'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { useNavigate } from 'react-router-dom'

type ListingCreatedNotificationProps = {
    listing?: ListingSchema
    imageId?: string
}

export const ListingCreatedNotification = (props: ListingCreatedNotificationProps): JSX.Element => {
    const { imageId, listing } = props
    const navigate = useNavigate()

    const handleGoToListing = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    if (!listing || !imageId) {
        return <></>
    }

    return (
        <>
            <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ width: '80px', marginTop: '48px' }} />
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {listing.listingDescription.listingDetails.commonDetails?.title} is scheduled for listing!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                Check listing progress by selecting the upcoming listing in the listing tab.
            </FoxTypography>
            <FoxButton text='View listing' onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
