import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { Colors } from '@foxtail-dev/user-clients'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../common/FoxImage'
import { FoxButtonBase } from '../common/FoxButtonBase'

type ListingDefaultZeroStateProps = {}

export const ListingDefaultZeroState = (props: ListingDefaultZeroStateProps) => {
    return (
        <Box sx={{ marginTop: '120px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ height: '130px', flex: 1, justifyContent: 'center', display: 'flex', marginBottom: '44px' }}>
                <FoxImage src={CommonImages.general.zeroListings} style={{ height: '130px' }} />
            </Box>
            <FoxTypography variant='h5' sx={{ marginBottom: '5px' }}>
                No markets connected
            </FoxTypography>
            <FoxTypography light variant='body2' sx={{ lineHeight: '26px', marginBottom: '23px' }}>
                Connect a market in order to set listing defaults
            </FoxTypography>
            <FoxButtonBase onFoxClick={{ kind: 'internal', to: '/app/account/marketplace-connections' }}>
                <FoxTypography variant='subtitle1' sx={{ color: Colors.light.primary }}>
                    + Connect markets
                </FoxTypography>
            </FoxButtonBase>
        </Box>
    )
}
