import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { EtsyConditionalAttribute, EtsyListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import {
    useAppSelector,
    selectCurrentEtsyAttributes,
    sanitizeZodObject,
    RadioOption,
    EtsyWhoMadeItRadioOptions,
    EtsyWhatIsItRadioOptions,
    EtsyWhenDidYouMakeItRadioOptions,
    productionPartnerRequiredWhenDidYouMakeItValues,
    EtsyPhysicalOrDigital,
    EtsyWeightUnitsRadioOptions
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { useFormikContext, getIn } from 'formik'
import React, { useMemo, useState } from 'react'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { FoxTypography } from '../../../../components/common/FoxTypography'
import { sharedStyles } from '../../../../theme/SharedStyling'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { MarketSpecificFieldsProps } from './MarketSpecificFieldsMap'
import { DimensionInputField } from '../../../../components/formik/DimensionInputField'
import { FoxTextField } from '../../../../components/formik/FoxTextField'
import { EtsyCategoryPath } from '../../listingDetails/EtsyCategoryPath'
import { EtsyCategoryModal } from '../../../../modals/domainSpecific/EtsyCategoryModal'
import { EtsyProductionPartnersModal } from '../../../../modals/domainSpecific/EtsyProductionPartnersModal'
import { EtsyShippingProfilesModal } from '../../../../modals/domainSpecific/EtsyShippingProfilesModal'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'

const getEtsyConditionalAttributeValueId = (attributeId: string, attributes?: EtsyConditionalAttribute[]) => {
    if (!attributes || attributes.length === 0) {
        return ''
    }

    const conditionalInputIndex = attributes.findIndex((attribute) => attribute.attributeId === attributeId)
    const conditionalAttributeInput = attributes[conditionalInputIndex]
    if (!conditionalAttributeInput) {
        return ''
    }

    return conditionalAttributeInput.valueId
}

export const EtsyFields = (props: MarketSpecificFieldsProps) => {
    const { values, listingOperationKind } = props
    const { errors, touched } = useFormikContext<ListingDetailsDraft>()

    const [modalOpen, setModalOpen] = useState<string>('none')
    const conditionalAttributes = useAppSelector(selectCurrentEtsyAttributes)

    const etsyDetailsSanitized = useMemo(() => {
        return sanitizeZodObject(values, EtsyListingDetailsDraft)
    }, [values])

    const onCloseModal = () => {
        setModalOpen('none')
    }

    const requiredDetails = etsyDetailsSanitized.requiredDetails

    const hasConditionalAttributeError =
        getIn(errors, 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs') &&
        getIn(touched, 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs')
    const attributeIdErrorList = getIn(errors, 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs')
    const errorAttributes = attributeIdErrorList?.split(',')

    return (
        <>
            <DetailSelectionField
                name='domainSpecificDetails.etsy.requiredDetails.categoryIds'
                label='Category'
                placeholder='Select Category'
                renderValue={(value) => (value ? <EtsyCategoryPath path={value} key={value} /> : null)}
                onDetail={() => setModalOpen('EtsyCategory')}
            />
            <DomainSpecificDetailModal open={'EtsyCategory' === modalOpen} title='Etsy Category' closeModal={onCloseModal}>
                <EtsyCategoryModal closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            {conditionalAttributes &&
                conditionalAttributes.map((attr, i) => {
                    const options = attr.possibleValues.map((v) => RadioOption.parse([v.valueId.toString(), v.value]))
                    const titleSubtext = attr.isRequired ? '' : '(optional)'

                    // Don't show any conditional attributes if there are no options or only one option - if there is one option, we auto select it
                    if (options.length === 0 || options.length === 1) {
                        return null
                    }

                    let hasError: boolean = false
                    if (hasConditionalAttributeError && errorAttributes) {
                        hasError = errorAttributes.includes(attr.attributeId.toString())
                    }
                    return (
                        <Box key={'EtsyConditionalAttribute' + attr.attributeId} sx={{ marginBottom: '28px' }}>
                            <DetailSelectionField
                                key={attr.attributeId}
                                name={`domainSpecificDetails.etsy.optionalDetails.conditionalInputs`}
                                label={attr.attributeName}
                                placeholder={`Select ${attr.attributeDisplayName}`}
                                options={options}
                                onDetail={() => setModalOpen('EtsyConditionalInputs' + attr.attributeId)}
                                titleSubtext={titleSubtext}
                                ignoreError={true}
                                styleOverride={{ marginBottom: 0 }}
                            />
                            <DetailSelectionModal
                                open={modalOpen === 'EtsyConditionalInputs' + attr.attributeId}
                                headerTitle={attr.attributeDisplayName}
                                options={options}
                                paramName={`domainSpecificDetails.etsy.optionalDetails.conditionalInputs`}
                                currentValue={getEtsyConditionalAttributeValueId(
                                    attr.attributeId.toString(),
                                    etsyDetailsSanitized.optionalDetails.conditionalInputs
                                )}
                                isRequired={true}
                                domainName={DomainCommonName.enum.etsy}
                                listingOperationKind={listingOperationKind}
                                closeModal={onCloseModal}
                                etsyAttribute={attr}
                                existingEtsyAttributes={etsyDetailsSanitized.optionalDetails.conditionalInputs ?? []}
                            />
                            {hasError && (
                                <Box sx={styles.containerValidation}>
                                    <FoxTypography variant='body2' danger sx={[styles.textError]}>
                                        Required
                                    </FoxTypography>
                                </Box>
                            )}
                        </Box>
                    )
                })}

            <RadioGroupField
                label='Who made it'
                name='domainSpecificDetails.etsy.requiredDetails.whoMadeIt'
                iconType='Radio'
                options={EtsyWhoMadeItRadioOptions}
                logOnChange
            />
            <RadioGroupField
                label='What is it'
                name='domainSpecificDetails.etsy.requiredDetails.whatIsIt'
                iconType='Radio'
                options={EtsyWhatIsItRadioOptions}
                logOnChange
            />

            <DetailSelectionField
                name='domainSpecificDetails.etsy.requiredDetails.whenDidYouMakeIt'
                label='When was it made'
                placeholder='Select when product was made'
                options={EtsyWhenDidYouMakeItRadioOptions}
                onDetail={() => setModalOpen('EtsyWhenDidYouMakeIt')}
            />
            <DetailSelectionModal
                open={modalOpen === 'EtsyWhenDidYouMakeIt'}
                headerTitle='When was it made'
                options={EtsyWhenDidYouMakeItRadioOptions}
                paramName='whenDidYouMakeIt'
                currentValue={requiredDetails?.whenDidYouMakeIt}
                isRequired={true}
                listingOperationKind={listingOperationKind}
                domainName='etsy'
                closeModal={onCloseModal}
            />

            {requiredDetails?.whoMadeIt === 'someone_else' &&
                requiredDetails.whenDidYouMakeIt &&
                productionPartnerRequiredWhenDidYouMakeItValues.includes(requiredDetails.whenDidYouMakeIt) && (
                    <>
                        <DetailSelectionField
                            name='domainSpecificDetails.etsy.optionalDetails.productionPartner'
                            label='Production partner'
                            placeholder='Select production partner'
                            onDetail={() => setModalOpen('EtsyProductionPartners')}
                        />
                        <DomainSpecificDetailModal open={modalOpen === 'EtsyProductionPartners'} title='Production Partners' closeModal={onCloseModal}>
                            <EtsyProductionPartnersModal listingOperationKind={listingOperationKind} closeModal={onCloseModal} />
                        </DomainSpecificDetailModal>
                    </>
                )}

            <RadioGroupField
                label='Physical or Digital'
                name='domainSpecificDetails.etsy.requiredDetails.physicalOrDigital'
                iconType='Radio'
                options={EtsyPhysicalOrDigital}
                logOnChange
            />

            <DetailSelectionField
                name='domainSpecificDetails.etsy.requiredDetails.shippingProfile'
                label='Shipping Profile'
                placeholder='Select shipping profile'
                onDetail={() => setModalOpen('EtsyShippingProfiles')}
            />
            <DomainSpecificDetailModal open={modalOpen === 'EtsyShippingProfiles'} title='Shipping Profiles' closeModal={onCloseModal}>
                <EtsyShippingProfilesModal listingOperationKind={listingOperationKind} closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            <FoxTypography variant='body2' bold>
                Product Dimensions
            </FoxTypography>
            <Box sx={{ ...sharedStyles.flex, ...{ justifyContent: 'space-between' } }}>
                <Box sx={{ width: '30%' }}>
                    <DimensionInputField name='domainSpecificDetails.etsy.requiredDetails.length' label='Length' logOnBlur suffix='in' />
                </Box>
                <Box sx={{ width: '30%' }}>
                    <DimensionInputField name='domainSpecificDetails.etsy.requiredDetails.width' label='Width' logOnBlur suffix='in' />
                </Box>
                <Box sx={{ width: '30%' }}>
                    <DimensionInputField name='domainSpecificDetails.etsy.requiredDetails.height' label='Height' logOnBlur suffix='in' />
                </Box>
            </Box>

            <FoxTextField
                name='domainSpecificDetails.etsy.requiredDetails.weight'
                label='Weight'
                placeholder='0.00'
                suffix={requiredDetails?.weightUnit || 'oz'}
                logOnBlur
                value={requiredDetails?.weight}
            />
            <RadioGroupField name='domainSpecificDetails.etsy.requiredDetails.weightUnit' iconType='Radio' options={EtsyWeightUnitsRadioOptions} logOnChange />
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
