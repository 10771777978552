import { Box, CircularProgress, Divider } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors, selectHydratedQuestsSorted, useAppSelector } from '@foxtail-dev/user-clients'
import { useEffect, useMemo } from 'react'
import { ChecklistBannerRow } from './CheckListBannerRow'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'
import { questKindToButtonTextMap, questKindToRouteMap } from '../../utils/checklistInfo'
import { getTopPriorityIncompleteQuest } from '../../utils/getTopPriorityIncompleteQuest'
import { SchoolHatIcon } from '../../components/icons/SchoolHatIcon'
import ConfettiExplosion from 'react-confetti-explosion'
import { ConfettiColors } from '../../theme/ConfettiColors'
import React from 'react'

type ChecklistBannerProps = {
    imageSize: 'small' | 'large'
}

export const ChecklistBanner = (props: ChecklistBannerProps): JSX.Element => {
    const { imageSize } = props
    const [isExploding, setIsExploding] = React.useState<boolean>(true)
    useEffect(() => {
        setTimeout(() => {
            setIsExploding(false)
        }, 5000)
    }, [])

    const quests = useAppSelector(selectHydratedQuestsSorted)

    const totalQuestsCompleted = useMemo(() => {
        return quests.filter((quest) => quest.quest.details.status === 'completed').length
    }, [quests])

    const topPriorityIncompleteQuest = useMemo(() => {
        return getTopPriorityIncompleteQuest(quests)
    }, [quests])

    // TODO: Improve completed quests view
    if (quests.length === 0 || !topPriorityIncompleteQuest) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <FoxTypography variant='h4' sx={{ marginTop: '48px', marginBottom: '48px' }}>
                    Congratz!
                </FoxTypography>
                <SchoolHatIcon height={120} width={120} color={Colors.light.primary} />
                <FoxTypography variant='h5' sx={{ marginTop: '48px' }}>
                    {' '}
                    You've graduated the Foxtail Academy
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ marginTop: '12px' }}>
                    {' '}
                    You're well on you way to becoming a reselling super star
                </FoxTypography>
                {isExploding && (
                    <ConfettiExplosion
                        force={0.2}
                        duration={5000}
                        particleCount={400}
                        width={window.innerWidth}
                        colors={ConfettiColors}
                        zIndex={100}
                        style={{
                            position: 'absolute',
                            top: '0px',
                            left: '50%',
                            transform: 'translateX(-50%)'
                        }}
                    />
                )}
            </Box>
        )
    }

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerTitle}>
                <Box sx={styles.containerProgress}>
                    <CircularProgress
                        variant='determinate'
                        value={quests.length > 0 ? (totalQuestsCompleted / quests.length) * 100 : 0}
                        size={16}
                        sx={{
                            color: Colors.light.success,
                            position: 'relative',
                            zIndex: 2
                        }}
                        thickness={6}
                    />

                    <CircularProgress
                        variant='determinate'
                        value={100}
                        size={16}
                        sx={{
                            color: Colors.light.divider,
                            position: 'absolute',
                            left: 0,
                            zIndex: 1
                        }}
                        thickness={6}
                    />
                </Box>
                <FoxTypography variant='h6'>Get started</FoxTypography>
                <FoxTypography light variant='body2' sx={{ lineHeight: '26px', marginLeft: '8px' }}>
                    • {totalQuestsCompleted}/{quests.length} steps
                </FoxTypography>
            </Box>
            <Box sx={styles.containerChecklistItems}>
                <Box sx={{ flex: 3, borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', marginTop: '10px' }}>
                    {quests.map((quest, index) => {
                        const questKind = quest.data.kind
                        const isLastQuest = index === quests.length - 1
                        return (
                            <Box key={questKind}>
                                <ChecklistBannerRow
                                    quest={quest}
                                    isTopPriorityIncompleteQuest={topPriorityIncompleteQuest.data.kind === questKind}
                                    buttonText={questKindToButtonTextMap[questKind]}
                                    route={questKindToRouteMap[questKind]}
                                />
                                {!isLastQuest && <Divider sx={{ height: '1px', marginLeft: '64.23px', marginRight: '66.7px' }} />}
                            </Box>
                        )
                    })}
                </Box>

                <Box sx={styles.containerImage}>
                    <FoxImage
                        src={CommonImages.general.checklistBanner}
                        style={{ height: imageSize === 'large' ? '230px' : '173px', width: imageSize === 'large' ? '310px' : '233px' }}
                    />
                </Box>
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    containerTitle: {
        height: '32px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    containerChecklistItems: {
        height: '294px',
        marginBottom: '49px',
        marginTop: '26px',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        borderRadius: '10px',
        boxShadow: '0px 3px 11px rgba(0, 0, 0, 0.06)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        boxSizing: 'border-box'
    },
    containerImage: {
        flex: 2,
        height: '294px',
        backgroundColor: '#FAF7FA',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
    },
    containerProgress: {
        marginRight: '16.96px',
        display: 'inline-flex',
        position: 'relative',
        alignItems: 'center'
    }
}
