import { MercariShippingOptionRadioOptions } from '@foxtail-dev/user-clients'
import { useState } from 'react'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'
import { MercariAiListingSuggestionDefaultsConfig } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import { RadioBoolGroupField } from '../../../../components/formik/RadioGroupField'

export const MercariListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    const { values } = props
    const suggestionDefaults = values.domainDetails.mercari?.suggestionDefaults
    // as any is fine here because it gets parsed on form submit - we need access to the non-generic version of the config
    const unsafeSuggestionDefaults = suggestionDefaults as any as MercariAiListingSuggestionDefaultsConfig

    const [modalOpen, setModalOpen] = useState<string>('none')
    const onCloseModal = () => {
        setModalOpen('none')
    }

    return (
        <>
            <DetailSelectionField
                name='domainDetails.mercari.suggestionDefaults.requiredDetails.shippingSelection'
                label='Shipping selection'
                placeholder='Select shipping option'
                options={MercariShippingOptionRadioOptions}
                onDetail={() => setModalOpen('MercariShippingOption')}
            />
            <DetailSelectionModal
                open={modalOpen === 'MercariShippingOption'}
                headerTitle={'Shipping Option'}
                options={MercariShippingOptionRadioOptions}
                paramName={'shippingSelection'}
                domainName={'mercari'}
                isRequired={true}
                currentValue={unsafeSuggestionDefaults?.requiredDetails.shippingSelection}
                closeModal={onCloseModal}
                listingOperationKind={'setDefault'}
            />

            <RadioBoolGroupField
                label='Who pays for shipping'
                name='domainDetails.mercari.suggestionDefaults.requiredDetails.freeShipping'
                iconType='Radio'
                options={[
                    [true, "I'll pay"],
                    [false, 'Buyer pays']
                ]}
                logOnChange
            />
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: 5,
        marginTop: 4
    },
    textError: {
        fontSize: 12,
        lineHeight: 16
    }
}
