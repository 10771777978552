import { Box } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon'
import { HydratedUserQuest } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'
import { RadioButtonUncheckedIcon } from '../../components/icons/RadioButtonUnchecked'
import { Colors } from '@foxtail-dev/user-clients'
import { CheckBoxCheckedIcon } from '../../components/icons/CheckBoxChecked'
import { FoxButton } from '../../components/common/FoxButton'
import { useNavigate } from 'react-router-dom'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'

type ChecklistBannerRowProps = {
    quest: HydratedUserQuest
    isTopPriorityIncompleteQuest: boolean
    buttonText: string
    route: string
}

export const ChecklistBannerRow = (props: ChecklistBannerRowProps) => {
    const { quest, isTopPriorityIncompleteQuest, buttonText, route } = props
    const navigate = useNavigate()
    const isCompleted = quest.quest.details.status === 'completed'
    return (
        <>
            <FoxButtonBase
                sx={styles.container}
                onFoxClick={{
                    kind: 'button',
                    onClick: () => {
                        if (!isCompleted) {
                            navigate(route)
                        }
                    }
                }}
            >
                <Box sx={{ marginLeft: '23.73px' }}>
                    {isCompleted ? (
                        <CheckBoxCheckedIcon height={20} width={20} borderRadius={10} color={Colors.light.divider} />
                    ) : (
                        <RadioButtonUncheckedIcon color={Colors.light.lightText} height={20} width={20} />
                    )}
                </Box>
                <FoxTypography
                    variant='subtitle1'
                    light={isCompleted}
                    sx={{ lineHeight: '24px', marginLeft: '20.5px', textDecorationLine: isCompleted ? 'line-through' : 'none' }}
                >
                    {quest.data.title}
                </FoxTypography>
                <FlexGrow />
                {!isTopPriorityIncompleteQuest && !isCompleted && (
                    <Box sx={{ marginRight: '79.89px' }}>
                        <ChevronRightIcon height={10} color='#301A36' />
                    </Box>
                )}
            </FoxButtonBase>
            {isTopPriorityIncompleteQuest && (
                <Box sx={{ marginLeft: '64.23px' }}>
                    <Box sx={{ marginTop: '6px' }}>
                        <FoxTypography>{quest.data.description}</FoxTypography>
                    </Box>
                    <FoxButton
                        sx={{ marginTop: '19px', width: '180px', justifyContent: 'center', marginBottom: '28px' }}
                        primary
                        text={buttonText}
                        onFoxClick={{ kind: 'internal', to: route }}
                    />
                </Box>
            )}
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '48px',
        alignItems: 'center',
        width: '100%'
    }
}
