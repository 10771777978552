import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'

type BulkCrosslistingFinishedGenerationNotificationProps = {
    domains: DomainCommonName[]
}

export const BulkCrosslistingFinishedGenerationNotification = (props: BulkCrosslistingFinishedGenerationNotificationProps): JSX.Element => {
    const { domains } = props

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                {domains.map((domain, index) => {
                    // Don't need margin on the last FoxButton
                    const margin = index === domains.length - 1 ? 0 : 20
                    return (
                        <FoxImage
                            src={CommonImages.markets[domain]}
                            style={{ ...styles.image, ...{ marginRight: margin } }}
                            key={`bulkCrosslistNotificationFoxImage-${domain}`}
                        />
                    )
                })}
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                AI Listing Generation complete!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                Review listings to begin crosslisting.
            </FoxTypography>
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    }
}
