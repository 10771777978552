import React, { useMemo, useState } from 'react'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import {
    FacebookListingDetailsDraft,
    FacebookDeliveryMethod,
    FacebookCondition,
    FacebookShippingOption,
    FacebookWeightUnit,
    FacebookShippingCarrier
} from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { useAppSelector, selectFacebookIsShippingEnabled, sanitizeZodObject } from '@foxtail-dev/user-clients'
import { FoxTypography } from '../../../../components/common/FoxTypography'
import { CheckboxGroupField } from '../../../../components/formik/CheckboxGroupField'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { RadioBoolGroupField, RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { MarketSpecificFieldsProps } from './MarketSpecificFieldsMap'
import { FoxTextField } from '../../../../components/formik/FoxTextField'
import { PriceField } from '../../../../components/formik/PriceField'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { useNavigate } from 'react-router-dom'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { FacebookCategoryModal } from '../../../../modals/domainSpecific/FacebookCategoryModal'
import { FacebookSyncShippingModal } from '../../../../modals/domainSpecific/FacebookSyncShippingModal'

export const FacebookFields = (props: MarketSpecificFieldsProps) => {
    const { values, listingOperationKind } = props

    const [modalOpen, setModalOpen] = useState<string>('none')
    const isShippingEnabled = useAppSelector(selectFacebookIsShippingEnabled)

    const facebookDetails = useMemo(() => {
        return sanitizeZodObject(values, FacebookListingDetailsDraft)
    }, [values])

    const requiredDetails = facebookDetails.requiredDetails
    const optionalDetails = facebookDetails.optionalDetails
    const isShipping = requiredDetails.deliveryMethods?.includes('Shipping')
    const usePrepaidLabel = optionalDetails.shippingOption === 'Use a prepaid shipping label'
    const deliveryMethods: FacebookDeliveryMethod[] = isShippingEnabled ? FacebookDeliveryMethod.options : ['Local pickup']

    const onCloseModal = () => {
        setModalOpen('none')
    }

    // TODO: Go over the text explaining the more complicated fields
    // TODO: Zip code validation
    return (
        <>
            <DetailSelectionField
                name='domainSpecificDetails.facebook.requiredDetails.categoryId'
                label='Category'
                placeholder='Select Category'
                renderValue={(value) => (value ? <FoxTypography variant='body1'>{value}</FoxTypography> : null)}
                onDetail={() => setModalOpen('FacebookCategory')}
            />
            <DomainSpecificDetailModal open={modalOpen === 'FacebookCategory'} title={'Condition'} closeModal={onCloseModal}>
                <FacebookCategoryModal closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            <DetailSelectionField
                name='domainSpecificDetails.facebook.requiredDetails.condition'
                label='Condition'
                placeholder='Select condition'
                options={FacebookCondition.options}
                onDetail={() => setModalOpen('FacebookCondition')}
            />
            <DetailSelectionModal
                open={modalOpen === 'FacebookCondition'}
                headerTitle={'Condition'}
                options={FacebookCondition.options}
                paramName={'condition'}
                domainName={'facebook'}
                isRequired={true}
                currentValue={requiredDetails.condition}
                closeModal={onCloseModal}
                listingOperationKind={listingOperationKind}
            />

            <RadioBoolGroupField
                label='Hide from friends'
                name='domainSpecificDetails.facebook.requiredDetails.hideFromFriends'
                iconType='Radio'
                options={[
                    [true, 'True'],
                    [false, 'False']
                ]}
                logOnChange
            />

            <FoxTextField
                name='domainSpecificDetails.facebook.requiredDetails.postalCode'
                label='Postal code'
                placeholder='Enter postal code'
                value={requiredDetails.postalCode}
                logOnBlur
            />

            <CheckboxGroupField
                name='domainSpecificDetails.facebook.requiredDetails.deliveryMethods'
                label='Delivery methods'
                options={deliveryMethods}
                iconType='Checkbox'
                logOnChange
            />

            {!isShippingEnabled && (
                <>
                    <DetailSelectionField
                        name='none'
                        label='Facebook Shipping'
                        placeholder='Setup Facebook Shipping'
                        onDetail={() => setModalOpen('FacebookShipping')}
                    />
                    <DomainSpecificDetailModal open={modalOpen === 'FacebookShipping'} title={'Facebook Shipping'} closeModal={onCloseModal}>
                        <FacebookSyncShippingModal closeModal={onCloseModal} />
                    </DomainSpecificDetailModal>
                </>
            )}

            <FoxTextField
                name='domainSpecificDetails.facebook.optionalDetails.sku'
                label='Facebook sku'
                placeholder='Enter sku'
                titleSubtext='(optional)'
                value={optionalDetails.sku}
                logOnBlur
            />

            {isShipping && (
                <>
                    <RadioGroupField
                        label='Shipping option'
                        name='domainSpecificDetails.facebook.optionalDetails.shippingOption'
                        iconType='Radio'
                        options={FacebookShippingOption.options}
                        logOnChange
                    />

                    {usePrepaidLabel ? (
                        <>
                            <FoxTextField
                                name='domainSpecificDetails.facebook.optionalDetails.weight'
                                label='Weight'
                                placeholder='0.00'
                                suffix={optionalDetails?.weightUnit || 'oz'}
                                value={optionalDetails.weight}
                                logOnBlur
                            />
                            <RadioGroupField
                                name='domainSpecificDetails.facebook.optionalDetails.weightUnit'
                                label='Weight Unit'
                                iconType='Radio'
                                options={FacebookWeightUnit.options}
                                logOnChange
                            />
                            <RadioGroupField
                                name='domainSpecificDetails.facebook.optionalDetails.shippingCarrier'
                                label='Shipping carrier'
                                iconType='Radio'
                                options={FacebookShippingCarrier.options}
                                logOnChange
                            />
                        </>
                    ) : (
                        <PriceField
                            name='domainSpecificDetails.facebook.optionalDetails.shippingRate'
                            label='Shipping rate'
                            logOnBlur
                            initialValue={optionalDetails.shippingRate}
                        />
                    )}

                    <RadioBoolGroupField
                        label='Free shipping'
                        name='domainSpecificDetails.facebook.optionalDetails.freeShipping'
                        iconType='Radio'
                        options={[
                            [true, 'Seller pays'],
                            [false, 'Buyer pays']
                        ]}
                        logOnChange
                    />

                    <FoxTypography light variant='body1' sx={{ marginBottom: '16px' }}>
                        Save time by letting buyers quickly offer an amount they're willing to pay. You will have the option to accept or decline the offer.
                    </FoxTypography>
                    <RadioBoolGroupField
                        label='Allow offers'
                        name='domainSpecificDetails.facebook.optionalDetails.allowOffers'
                        iconType='Radio'
                        options={[
                            [true, 'True'],
                            [false, 'False']
                        ]}
                        logOnChange
                    />

                    <FoxTypography light variant='body1' sx={{ marginBottom: '16px' }}>
                        Minimum price you will consider. Offers below this price will be automatically declined.
                    </FoxTypography>
                    <PriceField name='domainSpecificDetails.facebook.optionalDetails.minimumOfferPrice' label='Minimum offer price' logOnBlur />
                </>
            )}
        </>
    )
}
