import { Box } from '@mui/material'
import { ChecklistBanner } from '../../containers/home/ChecklistBanner'

export const ProgressChecklistScreen = () => {
    return (
        <Box sx={styles.container}>
            <ChecklistBanner imageSize={'small'} />
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    }
}
