import { HydratedUserQuest } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'

export const getTopPriorityIncompleteQuest = (quests: HydratedUserQuest[]): HydratedUserQuest | undefined => {
    const incompleteQuests = quests.filter((quest) => quest.quest.details.status !== 'completed')
    const firstQuest = incompleteQuests[0]
    if (incompleteQuests.length === 0 || !firstQuest) {
        return undefined
    }

    // Get highest priority incomplete quest
    const topPriorityIncompleteQuest = incompleteQuests.reduce((prevQuest, currentQuest) => {
        if (prevQuest.data.priority < currentQuest.data.priority) {
            return prevQuest
        }
        return currentQuest
    }, firstQuest)

    return topPriorityIncompleteQuest
}
