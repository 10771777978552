import Box from '@mui/material/Box/Box'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxButton } from '../components/common/FoxButton'
import { PageNotFoundIcon } from '../components/icons/PageNotFoundIcon'

export const PageNotFoundScreen = (): JSX.Element => {
    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginTop: '260px', marginBottom: '24px' }}>
                <PageNotFoundIcon />
            </Box>
            <FoxTypography variant='h5' sx={{ marginBottom: '17px' }}>
                Page not found
            </FoxTypography>

            <FoxTypography variant='body1' light sx={{ marginBottom: '24px' }}>
                Sorry we couldn't find what you were looking for.
            </FoxTypography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FoxButton
                    sx={{
                        justifyContent: 'center',
                        marginBottom: '33px',
                        width: '198px',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}
                    primary
                    text='Back to Foxtail'
                    variant='contained'
                    onFoxClick={{ kind: 'internal', to: '/app/home' }}
                />
            </Box>
        </Box>
    )
}
