import React, { useEffect } from 'react'
import Box from '@mui/material/Box/Box'
import { FoxButton } from '../../components/common/FoxButton'
import { OnboardingHeader } from '../../layouts/OnboardingHeader'
import ConfettiExplosion from 'react-confetti-explosion'
import { useNavigate } from 'react-router-dom'
import { SubscriptionFeatureRow } from '../../containers/subscription/SubscriptionFeatureRow'
import { completeSetup, SubscriptionTierInfoMap, useAppDispatch, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { ConfettiColors } from '../../theme/ConfettiColors'
import { useMediaQuery, useTheme } from '@mui/material'
import { Logger } from '../../lib/clients/Logger'

const SignUpCompleteScreen = (): JSX.Element => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const [isExploding, setIsExploding] = React.useState<boolean>(true)
    const [shouldNavigate, setShouldNavigate] = React.useState<boolean>(false)

    const handleGoToDashboard = async () => {
        dispatch(completeSetup())

        try {
            const context = await UserRuntimeContextProvider.getContext()

            await context.userApiClient.setUserHasSeenConfetti()
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'UserAction',
                        entry: {}
                    },
                    message: 'Error setting user has seen confetti'
                },
                error
            )
        }

        setShouldNavigate(true)
    }

    useEffect(() => {
        setTimeout(() => {
            setIsExploding(false)
        }, 5000)
    }, [])

    useEffect(() => {
        if (shouldNavigate) {
            navigate('/app/home')
        }
    }, [shouldNavigate])

    return (
        <Box sx={isMobile ? mobileStyles.container : styles.container}>
            <OnboardingHeader title='Thanks for signing up' subtitle='Start listing on Foxtail with your Free Plan.' />
            {isExploding && (
                <ConfettiExplosion
                    force={0.2}
                    duration={5000}
                    particleCount={800}
                    width={window.innerWidth}
                    colors={ConfettiColors}
                    zIndex={100}
                    style={{
                        position: 'absolute',
                        top: '120px',
                        left: '50%',
                        transform: 'translateX(-50%)'
                    }}
                />
            )}

            <Box sx={isMobile ? mobileStyles.containerFeatureRows : styles.containerFeatureRows}>
                <SubscriptionFeatureRow feature={`Manage up to ${SubscriptionTierInfoMap['freetrial'].listingLimit} listings`} />
                <SubscriptionFeatureRow feature='AI content generation' />
                <SubscriptionFeatureRow feature='Mobile application' />
                <SubscriptionFeatureRow feature='Customer support' />
            </Box>

            <FoxButton
                sx={isMobile ? mobileStyles.button : styles.button}
                primary
                text='Go to dashboard'
                variant='contained'
                onFoxClick={{ kind: 'button', onClick: handleGoToDashboard, preventDoubleClick: true }}
            />
        </Box>
    )
}

const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    containerFeatureRows: {
        marginBottom: '36px'
    },
    containerButton: {
        marginTop: '48px'
    },
    button: {
        justifyContent: 'center',
        marginBottom: '57px',
        width: '474px',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px'
    }
}

const mobileStyles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: '0 16px'
    },
    containerFeatureRows: {
        marginBottom: '24px'
    },
    containerButton: {
        marginTop: '24px'
    },
    button: {
        justifyContent: 'center',
        marginBottom: '24px',
        width: '100%',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px',
        marginTop: '32px'
    }
}

export default SignUpCompleteScreen
