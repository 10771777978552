import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'
import { useAppSelector, selectBulkCrosslistingItemsByKind } from '@foxtail-dev/user-clients'

type BulkCrosslistSubmitConfirmationModalProps = {
    open: boolean
    onClose: (result: boolean) => void
}

export const BulkCrosslistSubmitConfirmationModal = ({ open, onClose }: BulkCrosslistSubmitConfirmationModalProps) => {
    const readyItems = useAppSelector(selectBulkCrosslistingItemsByKind.readyToCrosslist)
    const readyItemsCount = readyItems.length
    const pendingItems = useAppSelector(selectBulkCrosslistingItemsByKind.pendingReview)
    const pendingItemsCount = pendingItems.length
    const failedItems = useAppSelector(selectBulkCrosslistingItemsByKind.failed)
    const failedCount = failedItems.length
    const totalRemainingCount = pendingItemsCount + failedCount

    return (
        <FoxModalDialog
            open={open}
            title={'Confirm submission'}
            leftButtonKind={'close'}
            onClose={() => onClose(false)}
            closeOnBackdropClick={false}
            actions={
                <>
                    <FoxButton
                        primary
                        text='Begin crosslisting'
                        onFoxClick={{ kind: 'button', onClick: () => onClose(true), preventDoubleClick: true }}
                        sx={{ width: '100%', justifyContent: 'center' }}
                    />
                    <FoxButton
                        grey
                        text='Cancel'
                        onFoxClick={{ kind: 'button', onClick: () => onClose(false), preventDoubleClick: true }}
                        sx={{ width: '100%', justifyContent: 'center' }}
                    />
                </>
            }>
            <Box sx={{ minHeight: '120px' }}>
                <FoxTypography variant='body1'>
                    {`Are you sure you want to crosslist ${readyItemsCount} items? You still have ${totalRemainingCount} items that need to be reviewed.`}
                </FoxTypography>
            </Box>
        </FoxModalDialog>
    )
}
