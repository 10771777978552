import { EbayDimensionUnitsRadioOptions, EbayWeightUnitsRadioOptions } from '@foxtail-dev/user-clients'
import { RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'

export const EbayListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    return (
        <>
            <RadioGroupField
                name='domainDetails.ebay.suggestionDefaults.requiredDetails.weightUnit'
                iconType='Radio'
                label='Weight unit'
                options={EbayWeightUnitsRadioOptions}
                logOnChange
            />
            <RadioGroupField
                name='domainDetails.ebay.suggestionDefaults.optionalDetails.dimensionUnit'
                iconType='Radio'
                label='Dimension unit'
                options={EbayDimensionUnitsRadioOptions}
                logOnChange
            />
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: 5,
        marginTop: 4
    },
    textError: {
        fontSize: 12,
        lineHeight: 16
    }
}
