import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { CommonImages } from '../../theme/CommonImages'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'

type ImportFinishedScrapingNotificationProps = {
    domainName: DomainCommonName
    scrapedListingCount: number
}

export const ImportFinishedScrapingNotification = (props: ImportFinishedScrapingNotificationProps): JSX.Element => {
    const { domainName, scrapedListingCount } = props
    const isPlural = scrapedListingCount !== 1

    const itemStr = isPlural ? 'items have been' : 'item has been'
    return (
        <>
            <FoxImage src={CommonImages.markets[domainName]} style={{ ...styles.image }} />
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                Finished finding listings from {Domains[domainName].displayName}!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                {scrapedListingCount} {itemStr} found from {Domains[domainName].displayName}. You may now choose which items to import into Foxtail.
            </FoxTypography>
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    }
}
