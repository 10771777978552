import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { ChevronRightIcon } from '../../components/icons/ChevronRightIcon'
import { HydratedUserQuest } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'
import { Colors } from '@foxtail-dev/user-clients'
import { CheckBoxCheckedIcon } from '../../components/icons/CheckBoxChecked'
import { FoxButton } from '../../components/common/FoxButton'
import { useNavigate } from 'react-router-dom'

type ChecklistAccordionRowProps = {
    quest: HydratedUserQuest
    isTopPriorityIncompleteQuest: boolean
    buttonText: string
    route: string
    setExpanded: (expanded: boolean) => void
}

export const ChecklistAccordionRow = (props: ChecklistAccordionRowProps) => {
    const { quest, isTopPriorityIncompleteQuest, buttonText, route, setExpanded } = props
    const navigate = useNavigate()
    const isCompleted = quest.quest.details.status === 'completed'
    return (
        <>
            <Box sx={styles.container}>
                <Box sx={{}}>
                    {isCompleted ? (
                        <CheckBoxCheckedIcon height={16} width={16} borderRadius={16} color={'rgba(255, 255, 255, 0.3)'} />
                    ) : (
                        <Box
                            sx={{
                                height: '16px',
                                width: '16px',
                                borderWidth: '2px',
                                borderColor: Colors.light.lightText,
                                borderStyle: 'solid',
                                borderRadius: '8px',
                                marginTop: '1px'
                            }}
                        />
                    )}
                </Box>
                <FoxTypography
                    variant='subtitle1'
                    sx={{
                        lineHeight: '20px',
                        marginLeft: '11px',
                        fontSize: '12px',
                        color: isCompleted ? Colors.light.lightText : Colors.light.background,
                        textDecorationLine: isCompleted ? 'line-through' : 'none'
                    }}>
                    {quest.data.title}
                </FoxTypography>
                <FlexGrow />
                {!isTopPriorityIncompleteQuest && !isCompleted && (
                    <Box sx={{ marginRight: '79.89px' }}>
                        <ChevronRightIcon height={10} color='#301A36' />
                    </Box>
                )}
            </Box>
            {isTopPriorityIncompleteQuest && (
                <Box sx={{ marginLeft: '27px' }}>
                    <Box sx={{ marginTop: '6px' }}>
                        <FoxTypography sx={{ lineHeight: '16px', fontSize: '12px' }} color={Colors.light.background}>
                            {quest.data.description}
                        </FoxTypography>
                    </Box>
                    <FoxButton
                        sx={{
                            marginTop: '19px',
                            width: '180px',
                            justifyContent: 'center',
                            marginBottom: '28px',
                            backgroundColor: Colors.light.background,
                            height: '35px',
                            fontSize: '12px',
                            lineHeight: '20px'
                        }}
                        text={buttonText}
                        onFoxClick={{
                            kind: 'button',
                            onClick: () => {
                                setExpanded(false)
                                navigate(route)
                            }
                        }}
                    />
                </Box>
            )}
        </>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: '42px',
        paddingTop: '10px'
    }
}
