import { delayMs, z } from '@foxtail-dev/datacontracts'
import { FoxButton } from '../components/common/FoxButton'
import { useEffect, useState } from 'react'
import { FoxBadge } from '../components/common/FoxBadge'
import { Box, Typography, useTheme } from '@mui/material'
import { FoxCheckbox } from '../components/common/FoxCheckbox'
import { sharedStyles } from '../theme/SharedStyling'
import { Collapsible } from '../components/common/Collapsible'
import { FoxSearchBar } from '../components/common/FoxSearchBar'
import { UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { FoxTextField } from '../components/formik/FoxTextField'
import { CheckboxGroupField } from '../components/formik/CheckboxGroupField'
import { RadioGroupField } from '../components/formik/RadioGroupField'
import { DescriptionField } from '../components/formik/DescriptionField'
import { TagsField } from '../components/formik/TagsField'
import { FoxModalDialog } from '../components/common/FoxModalDialog'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import React from 'react'
import { BulkCrosslistDraftRow } from '../components/bulkcrosslist/BulkCrosslistDraftRow'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { UserEventSource } from '@foxtail-dev/user-clients/dist/lib/UserEventSource'

const DummyUserInfo = z.object({
    name: z.string(),
    age: z.coerce.number(),
    etsyInfo: z.object({
        favoriteColor: z.string(),
        favoriteNumber: z.coerce.number()
    }),
    checkboxGroup: z.string().array(),
    radioGroup: z.string(),
    description: z.string(),
    tags: z.string().array()
})
type DummyUserInfo = z.infer<typeof DummyUserInfo>

export const SandboxScreen = () => {
    const [loading, setLoading] = useState(false)
    const theme = useTheme()

    const [checked, setChecked] = useState(false)

    const onCheck = (_: any, checked: boolean) => {
        setChecked(checked)
    }

    const [searchValue, setSearchValue] = useState('')
    const [isSearching, setIsSearching] = useState(false)

    useEffect(() => {
        if (searchValue === '') {
            setIsSearching(false)
            return
        }

        const searchAsync = async () => {
            setIsSearching(true)
            await delayMs(1000)
            setIsSearching(false)
        }

        searchAsync()
    }, [searchValue])

    const onClick = async () => {
        setLoading(true)
        await delayMs(1000)
        setLoading(false)
    }

    const initialValues: DummyUserInfo = {
        name: '',
        age: 0,
        etsyInfo: {
            favoriteColor: '',
            favoriteNumber: 0
        },
        checkboxGroup: [],
        radioGroup: '',
        description: '',
        tags: ['asd', 'sdfd']
    }

    const onSubmit = async (values: DummyUserInfo, formikHelpers: FormikHelpers<DummyUserInfo>) => {
        const { setFieldError, setFieldTouched } = formikHelpers

        const safeParseResult = DummyUserInfo.safeParse(values)

        if (!safeParseResult.success) {
            safeParseResult.error.issues.forEach((issue) => {
                setFieldError(issue.path.join('.'), issue.message)
                setFieldTouched(issue.path.join('.'))
            })

            return
        }

        await delayMs(1000)
    }

    const [modalOpen, setModalOpen] = useState(false)

    const onOpenCreateListingModal = async () => {
        setModalOpen(true)
    }

    const setModalClosed = () => {
        setModalOpen(false)
    }

    // Dummy data: an array of 1000 items
    const items = Array.from({ length: 1000 }, (_, index) => `Item ${index + 1}`)

    const filteredListingIds: string[] = []
    for (let i = 0; i < 60; i++) {
        filteredListingIds.push('1')
    }

    const renderItem = React.memo(({ index, style }: ListChildComponentProps<string>) => {
        const listingId = filteredListingIds[index] ?? ''

        return (
            <div style={style}>
                <BulkCrosslistDraftRow key={listingId} listingId={listingId} isChecked={false} />
            </div>
        )
    })

    const renderItem2 = React.memo(({ index, style }: ListChildComponentProps<string>) => {
        const listingId = filteredListingIds[index]
        return <div style={style}>{listingId}</div>
    })

    const logBearerToken = async () => {
        const context = await UserRuntimeContextProvider.getContext()
        console.log('Bearer token: ', context.userApiClient.config.accessToken)
    }

    const connectToNewEventSource = async () => {
        const context = await UserRuntimeContextProvider.getContext()
        const accessToken = context.userApiClient.config.accessToken
        const eventSource = new UserEventSource({
            accessToken,
            host: context.userApiClient.config.host
        })

        console.log('Connected!', eventSource)

        eventSource.onError((error) => {
            console.error('Error from UserEventSource:', error)
        })

        eventSource.onEvent((events) => {
            const kinds = events.map((event) => {
                if (event.eventPayload.kind === 'heartbeat') {
                    return 'Heartbeat'
                }

                return event.eventPayload.executedAction.kind
            })

            console.log('Events from UserEventSource:', events)
            console.log('received ' + events.length + ' events of kinds: ' + kinds)
        })
    }

    const connectToExistingEventSource = async () => {
        const context = await UserRuntimeContextProvider.getContext()

        context.eventSource.onError((error) => {
            console.error('Error from UserEventSource:', error)
        })

        context.eventSource.onEvent((events) => {
            const kinds = events.map((event) => {
                if (event.eventPayload.kind === 'heartbeat') {
                    return 'Heartbeat'
                }

                return event.eventPayload.executedAction.kind
            })

            console.log('Events from UserEventSource:', events)
            console.log('received ' + events.length + ' events of kinds: ' + kinds)
        })
    }

    return (
        <Box sx={[sharedStyles.flexColumn, sharedStyles.fullSize]}>
            {/* <Box>
                <FixedSizeList height={500} itemCount={filteredListingIds.length} itemSize={96} width='100%'>
                    {renderItem}
                </FixedSizeList>
                <FixedSizeList height={500} itemCount={filteredListingIds.length} itemSize={20} width='100%'>
                    {renderItem2}
                </FixedSizeList>
            </Box> */}

            <FoxButton primary text='Print bearer token' onFoxClick={{ kind: 'button', onClick: logBearerToken }} />
            <FoxButton primary text='Connect to new event source' onFoxClick={{ kind: 'button', onClick: connectToNewEventSource }} />
            <FoxButton primary text='Connect to existing event source' onFoxClick={{ kind: 'button', onClick: connectToExistingEventSource }} />

            <FoxButton primary text='Click me' onFoxClick={{ kind: 'button', onClick: onClick }} loading={loading} />
            <FoxButton primary text='Open modal' onFoxClick={{ kind: 'button', onClick: onOpenCreateListingModal }} loading={loading} />

            <FoxModalDialog leftButtonKind='close' title='Test Modal' open={modalOpen} onClose={setModalClosed}>
                <Typography variant='body1'>This is a test modal</Typography>
            </FoxModalDialog>

            <FoxBadge color={theme.palette.success.main} textColor='white' text='Beta' />
            <FoxCheckbox iconType={'Checkbox'} checked={checked} label='Check me' onChange={onCheck} />
            <Collapsible label='Open'>
                <FoxButton primary text='Click me' onFoxClick={{ kind: 'button', onClick: onClick }} loading={loading} />
            </Collapsible>
            <FoxSearchBar label={'Search'} value={searchValue} onChange={setSearchValue} loading={isSearching} />

            <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur={false}>
                {({ values, handleSubmit, isSubmitting, touched, errors }: FormikProps<DummyUserInfo>) => {
                    return (
                        <>
                            <FoxTextField name='name' label='Name' value={values.name} textInputProps={{}} />
                            <FoxTextField name='age' label='Age' value={values.age.toString()} textInputProps={{}} />
                            <FoxTextField name='etsyInfo.favoriteColor' label='Favorite Color' value={values.etsyInfo.favoriteColor} textInputProps={{}} />
                            <FoxTextField
                                name='etsyInfo.favoriteNumber'
                                label='Favorite Number'
                                value={values.etsyInfo.favoriteNumber.toString()}
                                textInputProps={{}}
                            />
                            <FoxButton
                                primary
                                variant='contained'
                                size='large'
                                text='Submit'
                                type='submit'
                                loading={isSubmitting}
                                onFoxClick={{ kind: 'button', onClick: async () => handleSubmit() }}
                            />

                            <CheckboxGroupField iconType='Checkbox' name='checkboxGroup' label='Checkbox Group' options={['Option 1', 'Option 2']} />
                            <RadioGroupField iconType='Radio' name='radioGroup' label='Radio Group' options={['Option 1', 'Option 2']} />
                            <DescriptionField name='description' label='Description' showWordCount maxCharacterCount={100} value={values.description} />
                            <TagsField name='tags' label='Tags' limit={5} autogenerateButton handleAutogenerate={async () => {}} />
                        </>
                    )
                }}
            </Formik>
        </Box>
    )
}
