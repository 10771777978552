import { Box } from '@mui/material'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import {
    useAppDispatch,
    useAppSelector,
    selectListingId,
    getSelectedMarkets,
    generateDescription,
    Logger,
    filterTags,
    generateTags,
    loadDomainSpecificDetailsForListing
} from '@foxtail-dev/user-clients'
import { MarketSpecificFieldsLayout } from '../../layouts/MarketSpecificFieldsLayout'
import { MarketSpecificFieldsMap } from '../../containers/forms/domainSpecificFields/createListing/MarketSpecificFieldsMap'
import { DescriptionField } from '../../components/formik/DescriptionField'
import { TagsField } from '../../components/formik/TagsField'
import { generateToast } from '../../lib/clients/ToastClient'
import { useEffect, useState } from 'react'
import { CreateListingSkeleton } from '../../components/skeletons/CreateListingSkeleton'

export type CreateListingDomainSpecificFieldsProps = {
    values: ListingDetailsDraft
    listingId: string
    loadingTags: boolean
    loadingDescription: boolean
    setLoadingTags: React.Dispatch<React.SetStateAction<boolean>>
    setLoadingDescription: React.Dispatch<React.SetStateAction<boolean>>
}

// Within the formik field
export const CreateListingDomainSpecificFields = (props: CreateListingDomainSpecificFieldsProps) => {
    const { values, loadingTags, loadingDescription, setLoadingTags, setLoadingDescription } = props
    const dispatch = useAppDispatch()

    const listingId = useAppSelector(selectListingId)
    const [loadedDomainSpecificDetails, setLoadedDomainSpecificDetails] = useState<boolean>(false)

    const loadDomainSpecificDetailsForListingAsync = async (listingDetails: ListingDetailsDraft) => {
        try {
            await dispatch(loadDomainSpecificDetailsForListing(listingDetails))
            setLoadedDomainSpecificDetails(true)
        } catch (error) {
            Logger.I().log({
                level: 'error',
                message: 'Failed to load domain specific details for listing',
                payload: {
                    kind: 'LoadDomainSpecificDetailsError',
                    entry: {
                        listingId,
                        listingDetails
                    }
                }
            })
        }
    }

    useEffect(() => {
        loadDomainSpecificDetailsForListingAsync(values)
    }, [])

    const onGenerateDescription = async (onChange: (value: string) => void) => {
        try {
            if (loadingDescription === false) {
                setLoadingDescription(true)
                const result = await dispatch(generateDescription(values)).unwrap()
                setLoadingDescription(false)
                const trimmedDescription = result.description.trim()
                Logger.I().log({
                    level: 'info',
                    message: 'User generated description',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            listingId,
                            description: trimmedDescription
                        }
                    }
                })
                onChange(trimmedDescription)
            }
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'threw error while trying to generate description',
                    payload: {
                        kind: 'GenerateDescriptionError',
                        entry: {
                            listingId: listingId,
                            form: 'ReviewListingForm'
                        }
                    }
                },
                error
            )

            setLoadingDescription(false)
        }
    }

    const onGenerateTags = async (existingTags: string[], onChange: (value: string[]) => void) => {
        if (loadingTags === false) {
            try {
                setLoadingTags(true)
                const generatedTags = await dispatch(generateTags(values)).unwrap()
                const newTags = filterTags(generatedTags, existingTags)
                setLoadingTags(false)

                Logger.I().log({
                    level: 'info',
                    message: 'User generated tags',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            listingId,
                            tags: newTags
                        }
                    }
                })

                onChange(newTags)
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'threw error while trying to generate tags',
                        payload: {
                            kind: 'GenerateTagsError',
                            entry: {
                                listingId,
                                form: 'ReviewListingForm',
                                existingTags: existingTags
                            }
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Unable to generate tags' })
                setLoadingTags(false)
            }
        }
    }

    const commonDetails = values.commonDetails
    const selectedMarkets = getSelectedMarkets(values)

    if (!loadedDomainSpecificDetails) {
        return <CreateListingSkeleton />
    }

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                {selectedMarkets.map((domain, index) => {
                    const Fields = MarketSpecificFieldsMap[domain]
                    const isLastField = index === selectedMarkets.length - 1
                    return (
                        <MarketSpecificFieldsLayout key={domain} domain={domain} divider={!isLastField}>
                            <Fields values={values.domainSpecificDetails?.[domain]} listingOperationKind='create' />
                        </MarketSpecificFieldsLayout>
                    )
                })}
            </Box>

            <DescriptionField
                name='commonDetails.description'
                label='Description'
                placeholder='Your description'
                disabled={loadingDescription}
                showWordCount={true}
                maxCharacterCount={1000}
                autogenerateButton
                handleAutogenerate={onGenerateDescription}
                logOnBlur
                value={commonDetails?.description}
            />

            <TagsField
                name='commonDetails.tags'
                label='Tags'
                placeholder='Add tags'
                autogenerateButton
                handleAutogenerate={onGenerateTags}
                isGenerating={loadingTags}
                limit={5}
            />
        </>
    )
}
