import { Box } from '@mui/material'
import { FoxImage } from '../components/common/FoxImage'
import { CommonImages } from '../theme/CommonImages'
import { FoxTypography } from '../components/common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Colors, selectSubscription, useAppSelector, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { FoxButton } from '../components/common/FoxButton'
import { CheckIcon } from '../components/icons/CheckIcon'
import { FoxtailLogoAndNameIcon } from '../components/icons/FoxtailLogoAndName'
import { useAuth0 } from '@auth0/auth0-react'
import { generateToast } from '../lib/clients/ToastClient'
import { useEffect, useState } from 'react'
import { UserSubscriptionTier } from '@foxtail-dev/datacontracts/dist/lib/schemas/subscriptions/UserSubscription'
import { useNavigate } from 'react-router-dom'
import { createLogoutUrl } from '../utils/createLogoutUrl'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'
import RewardfulClient from '../lib/clients/RewardfulClient'

export const InactiveSubscriptionScreen = () => {
    const { logout } = useAuth0()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const subscription = useAppSelector(selectSubscription)

    useEffect(() => {
        if (subscription && subscription.kind === 'active') {
            navigate('/app/home')
        }
    }, [subscription])

    const onSubscribe = async (subscriptionKind: UserSubscriptionTier) => {
        if (isLoading) {
            return
        }
        setIsLoading(true)

        try {
            const context = await UserRuntimeContextProvider.getContext()
            const referralId = await RewardfulClient.getReferralId()
            const { sessionUrl } = await context.userApiClient.stripe.createCheckoutSession({
                cancelUrl: `${window.location.origin}/app/account/subscription`,
                successUrl: `${window.location.origin}/app/account/subscription`,
                subscriptionTier: subscriptionKind,
                referralId
            })
            window.open(sessionUrl)

            setIsLoading(false)
        } catch (error) {
            generateToast({ kind: 'error', message: 'Subscription error. Please contact support' })
            setIsLoading(false)
        }
    }

    return (
        <Box
            sx={{
                background: 'white',
                height: '100vh',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Box sx={{ marginTop: '37px', marginBottom: '60px' }}>
                    <FoxtailLogoAndNameIcon height={26} width={120} />
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex', marginBottom: '14px' }}>
                    <FoxTypography sx={{ fontSize: '35px', fontWeight: 500, marginBottom: '14px', marginRight: '10px' }}>The Future of</FoxTypography>
                    <FoxTypography sx={{ color: Colors.light.primary, fontSize: '35px', fontWeight: 500 }}>Reselling</FoxTypography>
                </Box>
                <FoxTypography sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 400 }}>
                    Your subscription has expired. Resubscribe to continue using Foxtail.
                </FoxTypography>

                <Box sx={{ marginTop: '51px', marginBottom: '96px', display: 'flex', flexDirection: 'row' }}>
                    <Box
                        sx={{
                            height: '545px',
                            width: '342px',
                            borderWidth: '1px',
                            borderColor: Colors.light.divider,
                            borderStyle: 'solid',
                            borderRadius: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginRight: '25px',
                            boxShadow: '0px 32px 84px rgba(0, 0, 0, 0.09)'
                        }}>
                        <FoxTypography sx={{ marginTop: '37px', marginBottom: '5px', lineHeight: '41px', fontSize: '36px', fontWeight: 700 }}>
                            Basic
                        </FoxTypography>
                        <FoxTypography sx={{ marginBottom: '14px', lineHeight: '26.6px' }}>Perfect for the casual seller</FoxTypography>
                        <Box sx={{ height: '60px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '28.6px' }}>
                            <FoxTypography sx={{ fontSize: '28.8px', fontWeight: 700, marginRight: '13.7px' }}>$</FoxTypography>
                            <FoxTypography sx={{ fontSize: '54px', fontWeight: 700, marginRight: '11px' }}>29</FoxTypography>
                            <FoxTypography light sx={{ fontSize: '28.8px', fontWeight: 400 }}>
                                /month
                            </FoxTypography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '25px' }}>
                            <FoxTypography sx={{ lineHeight: '26px', fontSize: '16px', marginRight: '4px' }}>Up to</FoxTypography>
                            <FoxTypography sx={{ lineHeight: '26px', fontSize: '16px', fontWeight: 700 }}>300 Listings</FoxTypography>
                        </Box>
                        <FoxButton
                            text='Get Basic'
                            primary
                            sx={{ color: 'white', width: '269px', justifyContent: 'center', borderRadius: '26.5px', marginBottom: '29px' }}
                            onFoxClick={{ kind: 'button', onClick: async () => await onSubscribe(UserSubscriptionTier.Enum.basic), preventDoubleClick: true }}
                        />
                        <Box sx={{ width: '227px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginBottom: '23px', fontWeight: 700 }}>Plus</FoxTypography>

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>AI features</FoxTypography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>Import listings</FoxTypography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>Bulk crosslisting</FoxTypography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: '545px',
                            width: '342px',
                            borderWidth: '1px',
                            borderColor: Colors.light.divider,
                            borderStyle: 'solid',
                            borderRadius: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginRight: '25px',
                            boxShadow: '0px 32px 84px rgba(0, 0, 0, 0.09)'
                        }}>
                        <FoxTypography sx={{ marginTop: '37px', marginBottom: '5px', lineHeight: '41px', fontSize: '36px', fontWeight: 700 }}>
                            Premium
                        </FoxTypography>
                        <FoxTypography sx={{ marginBottom: '14px', lineHeight: '26.6px' }}>Perfect for the serious seller</FoxTypography>
                        <Box sx={{ height: '60px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '28.6px' }}>
                            <FoxTypography sx={{ fontSize: '28.8px', fontWeight: 700, marginRight: '13.7px' }}>$</FoxTypography>
                            <FoxTypography sx={{ fontSize: '54px', fontWeight: 700, marginRight: '11px' }}>49</FoxTypography>
                            <FoxTypography light sx={{ fontSize: '28.8px', fontWeight: 400 }}>
                                /month
                            </FoxTypography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '25px' }}>
                            <FoxTypography sx={{ lineHeight: '26px', fontSize: '16px', marginRight: '4px' }}>Up to</FoxTypography>
                            <FoxTypography sx={{ lineHeight: '26px', fontSize: '16px', fontWeight: 700 }}>1000 Listings</FoxTypography>
                        </Box>
                        <FoxButton
                            text='Get Premium'
                            primary
                            sx={{ color: 'white', width: '269px', justifyContent: 'center', borderRadius: '26.5px', marginBottom: '29px' }}
                            onFoxClick={{ kind: 'button', onClick: async () => await onSubscribe(UserSubscriptionTier.Enum.premium), preventDoubleClick: true }}
                        />
                        <Box sx={{ width: '227px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginBottom: '23px', fontWeight: 700 }}>Plus</FoxTypography>

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>AI features</FoxTypography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>Import listings</FoxTypography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>Bulk crosslisting</FoxTypography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            height: '545px',
                            width: '342px',
                            borderWidth: '1px',
                            borderColor: Colors.light.divider,
                            borderStyle: 'solid',
                            borderRadius: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            boxShadow: '0px 32px 84px rgba(0, 0, 0, 0.09)'
                        }}>
                        <FoxTypography sx={{ marginTop: '37px', marginBottom: '5px', lineHeight: '41px', fontSize: '36px', fontWeight: 700 }}>
                            Pro
                        </FoxTypography>
                        <FoxTypography sx={{ marginBottom: '14px', lineHeight: '26.6px' }}>Perfect for the power seller</FoxTypography>
                        <Box sx={{ height: '60px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '28.6px' }}>
                            <FoxTypography sx={{ fontSize: '28.8px', fontWeight: 700, marginRight: '13.7px' }}>$</FoxTypography>
                            <FoxTypography sx={{ fontSize: '54px', fontWeight: 700, marginRight: '11px' }}>99</FoxTypography>
                            <FoxTypography light sx={{ fontSize: '28.8px', fontWeight: 400 }}>
                                /month
                            </FoxTypography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '25px' }}>
                            <FoxTypography sx={{ lineHeight: '26px', fontSize: '16px', marginRight: '4px' }}>Up to</FoxTypography>
                            <FoxTypography sx={{ lineHeight: '26px', fontSize: '16px', fontWeight: 700 }}>2500 Listings</FoxTypography>
                        </Box>
                        <FoxButton
                            text='Get Pro'
                            primary
                            sx={{ color: 'white', width: '269px', justifyContent: 'center', borderRadius: '26.5px', marginBottom: '29px' }}
                            onFoxClick={{ kind: 'button', onClick: async () => await onSubscribe(UserSubscriptionTier.Enum.pro), preventDoubleClick: true }}
                        />
                        <Box sx={{ width: '227px', display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginBottom: '23px', fontWeight: 700 }}>Plus</FoxTypography>

                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>AI features</FoxTypography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '14px' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>Import listings</FoxTypography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CheckIcon color={Colors.light.tertiary} height={11.84} width={13.84} />
                                <FoxTypography sx={{ fontSize: '16px', lineHeight: '26px', marginLeft: '10.5px' }}>Bulk crosslisting</FoxTypography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <FoxTypography light sx={{ fontSize: '15px' }}>
                    Increase sales from sites where you're already listing
                </FoxTypography>
                <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '48px' }}>
                    {Object.values(DomainCommonName.Values).map((domainName) => {
                        return (
                            <Box key={'DomainImg-' + domainName}>
                                <Box key={domainName} sx={{ marginRight: '17.5px', height: '68px', width: '68px', borderRadius: '10px', position: 'relative' }}>
                                    <FoxImage
                                        src={CommonImages.markets[DomainCommonName.parse(domainName)]}
                                        alt={domainName}
                                        style={{ borderRadius: '10px', height: '100%', width: '100%' }}
                                    />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
                <Box sx={{ height: '60px' }} />
                <FoxButton
                    onFoxClick={{
                        kind: 'button',
                        onClick: async () => {
                            await logout({
                                logoutParams: {
                                    returnTo: createLogoutUrl(window.location.origin, 'logout')
                                },
                                clientId: FoxtailWebConfig.config.auth0.clientId
                            })
                        },
                        preventDoubleClick: true
                    }}
                    grey
                    text='Log out'
                    sx={{ width: '269px', justifyContent: 'center', borderRadius: '26.5px', marginBottom: '29px' }}
                />
            </Box>
        </Box>
    )
}
