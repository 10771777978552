import { UserEngagementBoosterKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'
import React from 'react'
import { PoshmarkShareClosetFields } from './concretes/PoshmarkShareClosetFields'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { PoshmarkMakeOfferToLikersFields } from './concretes/PoshmarkMakeOfferToLikersFields'

export const EngagementBoosterSpecificFieldsMap: Record<UserEngagementBoosterKind, React.FC<EngagementBoosterSpecificFieldsProps>> = {
    poshmarkShareCloset: PoshmarkShareClosetFields,
    poshmarkMakeOfferToLikers: PoshmarkMakeOfferToLikersFields,
    relistStale: () => <div>Relist Stale</div>
} as const

export const EngagementBoosterToDomainMap: Record<UserEngagementBoosterKind, DomainCommonName> = {
    [UserEngagementBoosterKind.Enum.poshmarkShareCloset]: DomainCommonName.Enum.poshmark,
    [UserEngagementBoosterKind.Enum.poshmarkMakeOfferToLikers]: DomainCommonName.Enum.poshmark,
    [UserEngagementBoosterKind.Enum.relistStale]: DomainCommonName.Enum.poshmark
} as const

export const EngagementBoosterToTitleMap: Record<UserEngagementBoosterKind, string> = {
    [UserEngagementBoosterKind.Enum.poshmarkShareCloset]: 'Poshmark share closet',
    [UserEngagementBoosterKind.Enum.poshmarkMakeOfferToLikers]: 'Poshmark make offer to likers',
    relistStale: 'Relist stale'
} as const

// TODO: need help article id
export const EngagementBoosterToHelpArticleMap: Record<UserEngagementBoosterKind, string | undefined> = {
    [UserEngagementBoosterKind.Enum.poshmarkShareCloset]: '9750397',
    [UserEngagementBoosterKind.Enum.poshmarkMakeOfferToLikers]: '9750397',
    relistStale: undefined
}

export type EngagementBoosterSpecificFieldsProps = {}
