import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Colors, selectEngagementBoostersConfig, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxImage } from '../../components/common/FoxImage'
import { FoxTypography } from '../../components/common/FoxTypography'
import { CommonImages } from '../../theme/CommonImages'
import { EngagementBoosterRow } from './EngagementBoosterRow'
import { UserEngagementBoosterKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/engagementBoosters/engagementBoosters.exports'

export type MarketSpecificBoostersProps = {
    domainName: DomainCommonName
    onEditEngagementBooster: (boosterName: UserEngagementBoosterKind) => void
}

export const EngagementBoosterDomainSection = ({ domainName, onEditEngagementBooster }: MarketSpecificBoostersProps) => {
    const engagementBoosterConfig = useAppSelector(selectEngagementBoostersConfig)
    const poshmarkShareClosetConfig = engagementBoosterConfig?.poshmarkShareCloset
    const poshmarkMakeOfferToLikers = engagementBoosterConfig?.poshmarkMakeOfferToLikers

    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerTitleRow}>
                <Box sx={styles.containerDomainIcon}>
                    <FoxImage src={CommonImages.markets[domainName]} alt={Domains[domainName].displayName} style={styles.domainIcon} />
                </Box>
                <FoxTypography sx={{ marginTop: '14px' }} variant='subtitle1'>
                    {Domains[domainName].displayName}
                </FoxTypography>
            </Box>
            <Box sx={styles.containerBoosterRows}>
                <EngagementBoosterRow
                    boosterName='Share your closet'
                    isActive={poshmarkShareClosetConfig?.shouldShareUsersCloset ?? false}
                    editEngagementBooster={() => {
                        onEditEngagementBooster(UserEngagementBoosterKind.Enum.poshmarkShareCloset)
                    }}
                    showDivider={true}
                />
                <EngagementBoosterRow
                    boosterName='Make offer to likers'
                    isActive={poshmarkMakeOfferToLikers?.shouldMakeOfferToLikers ?? false}
                    editEngagementBooster={() => {
                        onEditEngagementBooster(UserEngagementBoosterKind.Enum.poshmarkMakeOfferToLikers)
                    }}
                    showDivider={false}
                />
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '24px'
    },
    containerTitleRow: {
        display: 'flex',
        flexDirection: 'row',
        height: '48px',
        marginBottom: '10px'
    },
    containerBoosterRows: {
        borderRadius: '10px',
        borderWidth: '1px',
        borderColor: Colors.light.divider,
        borderStyle: 'solid',
        maxWidth: '649px'
    },
    containerDomainIcon: {
        height: '24px',
        width: '24px',
        marginLeft: '12px',
        marginTop: '13px',
        marginRight: '14px',
        borderRadius: '10px'
    },
    domainIcon: {
        borderRadius: '10px',
        height: '24px',
        width: '24px'
    }
}
