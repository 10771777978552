import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'

type GenerateMainTextResult = { title: string; subtitle: string }

type GenerateMainTextParams = {
    marketsNeedingSetup: DomainCommonName[]
    marketsNeedingLinking: DomainCommonName[]
}

export const generateMainTextForConnectAndSetupMarketsBeforeListing = ({
    marketsNeedingSetup,
    marketsNeedingLinking
}: GenerateMainTextParams): GenerateMainTextResult => {
    if (marketsNeedingLinking.length === 1 && marketsNeedingLinking[0]) {
        return {
            title: `${Domains[marketsNeedingLinking[0]].displayName} has not completed linking`,
            subtitle: `You can save the draft and publish once ${
                Domains[marketsNeedingLinking[0]].displayName
            } finishes linking. Contact support if your markets are not linking.`
        }
    } else if (marketsNeedingLinking.length > 1) {
        return {
            title: 'Selected markets still linking',
            subtitle: 'You can save the draft and publish once your markets finish linking. Contact support if your markets are not linking.'
        }
    } else if (marketsNeedingSetup.length === 1 && marketsNeedingSetup[0]) {
        return {
            title: `${Domains[marketsNeedingSetup[0]].displayName} requires setup`,
            subtitle: 'Go to the market connection screen to finish setting up your market. Contact support if you need help.'
        }
    } else {
        // case marketsNeedingSetup.length > 1
        return {
            title: `Market setup required`,
            subtitle: 'Go to the market connection screen to finish setting up your markets. Contact support if you need help.'
        }
    }
}
