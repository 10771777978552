import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import user from '@foxtail-dev/user-clients/dist/lib/redux/reducers/user'
import { useNavigate } from 'react-router-dom'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'

type ListingMarketRequiresAuthenticationNotificationProps = {
    domainName: DomainCommonName
}
export const ListingMarketRequiresAuthenticationNotification = (props: ListingMarketRequiresAuthenticationNotificationProps): JSX.Element => {
    const { domainName } = props

    const navigate = useNavigate()
    const handleGoToMarketConnections = () => {
        navigate('/app/account/market-connections')
    }

    return (
        <>
            <FoxImage src={CommonImages.markets[domainName]} style={styles.image} />
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {Domains[domainName].displayName} is not linked
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginLeft: '80px', marginRight: '80px' }}>
                For Foxtail to continue managing listings on {Domains[domainName].displayName} you must link your account.
            </FoxTypography>
            <FoxButton text='Connect markets' onFoxClick={{ kind: 'button', onClick: handleGoToMarketConnections }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
