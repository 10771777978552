import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { useAppDispatch, getEbayCategorySuggestions, getCategorySuggestions, Logger } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { sharedStyles } from '../../../theme/SharedStyling'
import { FoxSearchBar } from '../../../components/common/FoxSearchBar'
import { SkeletonCategoryRows } from '../../../components/skeletons/SkeletonCategoryRows'
import { CategorySimilarityMatch } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'

export type CategorySelectionProps = {
    title: string
    renderItem: (item: CategorySimilarityMatch) => JSX.Element
    defaultAlwaysVisible?: CategorySimilarityMatch
    domain: DomainCommonName
}

export const CategorySelection = ({ title, renderItem, defaultAlwaysVisible, domain }: CategorySelectionProps) => {
    const dispatch = useAppDispatch()
    const [search, setSearch] = useState<string>('')
    const [items, setItems] = useState<CategorySimilarityMatch[]>([])
    const [searching, setSearching] = useState<boolean>(false)
    const timerRef = useRef<NodeJS.Timeout | null>(null) // Use ref to ensure we have up-to-date timer value + it doesn't cause a re-render

    useEffect(() => {
        if (search !== '' && search.length > 2) {
            // once backend is updated consider removing the search.length > 2
            setSearching(true)
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }

            timerRef.current = setTimeout(async () => {
                try {
                    if (domain === 'ebay') {
                        const searchResultRaw = await dispatch(
                            getEbayCategorySuggestions({
                                domainToSearch: domain,
                                currentCategoryInputText: search
                            })
                        ).unwrap()
                        const searchResult: CategorySimilarityMatch[] = searchResultRaw.map((r: { id: string; name: string }) => {
                            return {
                                categoryFullname: r.name,
                                categoryId: r.id,
                                categoryNameArray: [r.name],
                                similarityScore: 1
                            }
                        })

                        setItems(searchResult)
                        setSearching(false)
                    } else {
                        const t0 = performance.now()
                        const searchResult = await dispatch(
                            getCategorySuggestions({
                                domainToSearch: domain,
                                currentCategoryInputText: search
                            })
                        ).unwrap()

                        // Remove duplicates
                        const uniqueSuggestions = searchResult.filter(
                            (v, i, a) => a.findIndex((t) => t.categoryId === v.categoryId || t.categoryFullname === v.categoryFullname) === i
                        )
                        const t1 = performance.now()
                        if (t1 - t0 > 3000) {
                            Logger.I().log({
                                level: 'warning',
                                message: `Category Search took TOO LONG by our standards. CategorySuggestions took ${(t1 - t0) / 1000} seconds.`,
                                payload: {
                                    kind: 'CategorySearchTooLong',
                                    entry: {
                                        domainToSearch: domain,
                                        currentCategoryInputText: search,
                                        searchResult: searchResult,
                                        time: (t1 - t0) / 1000
                                    }
                                }
                            })
                        }
                        setItems(uniqueSuggestions)
                    }
                } catch (error) {
                    Logger.I().log(
                        {
                            level: 'error',
                            message: `Category Search failed`,
                            payload: {
                                kind: 'CategorySearchFailed',
                                entry: {
                                    domainToSearch: domain,
                                    currentCategoryInputText: search
                                }
                            }
                        },
                        error
                    )
                    // TODO: Bring this back once backend category error is fixed for node does not exist. Consider just removing the toast
                    // generateToast({ message: 'Category search failed, please try again' });
                } finally {
                    setSearching(false)
                }
            }, 500)
        } else {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
            setItems([])
            setSearching(false)
        }

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
    }, [search])

    // TODO: Make option to search for brands instead
    return (
        <Box sentry-label='CategorySelection'>
            <FoxSearchBar
                autoFocus
                label={`Search ${title}`}
                value={search}
                onChange={setSearch}
                loading={searching}
                fullWidth
                sx={{ marginTop: '16px', marginBottom: '16px' }}
            />

            {items.length === 0 && !defaultAlwaysVisible && !searching ? (
                <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginTop: '140px' }}>
                    {search.length > 2 ? 'No categories found' : `Start typing to list categories`}
                </FoxTypography>
            ) : searching || items.length === 0 ? (
                <SkeletonCategoryRows />
            ) : (
                <>
                    {defaultAlwaysVisible && renderItem(defaultAlwaysVisible)}
                    {items.map((i) => renderItem(i))}
                </>
            )}
        </Box>
    )
}

const styles = {
    header: {
        height: 75,
        width: '100%',
        paddingBottom: 27,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    textContainer: {
        flex: 2,
        alignItems: 'center'
    },
    containerOptions: {
        width: '100%'
    }
}
