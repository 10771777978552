import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxButton } from '../../components/common/FoxButton'
import { Box } from '@mui/material'
import { FoxImage } from '../../components/common/FoxImage'
import { CommonImages } from '../../theme/CommonImages'

type MustManageSubscriptionOnAppleDevicesModalProps = {
    open: boolean
    onClose: () => void
}

export const MustManageSubscriptionOnAppleDevicesModal = ({ open, onClose }: MustManageSubscriptionOnAppleDevicesModalProps) => {
    return (
        <FoxModalDialog
            open={open}
            title={`Subscription management`}
            leftButtonKind={'back'}
            onClose={onClose}
            closeOnBackdropClick
            actions={
                <Box sx={{ display: 'flex' }}>
                    <FoxButton
                        text={'Go back'}
                        onFoxClick={{
                            onClick: async () => {
                                onClose()
                            },
                            kind: 'button'
                        }}
                        grey
                        sx={{ display: 'flex', justifyContent: 'center', marginLeft: '24px' }}
                        variant='contained'
                    />
                </Box>
            }>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <FoxTypography variant='body1'>
                    Because you have an apple subscription you can only manage your subscription in the IOS settings on an apple device
                </FoxTypography>
            </Box>
        </FoxModalDialog>
    )
}
