import {
    useAppDispatch,
    useAppSelector,
    selectBulkCrosslistingSessionId,
    selectBulkCrosslistingSuccessfullyGeneratedListingIds,
    selectBulkCrosslistingHasRunExtraRules,
    selectBulkCrosslistingFailedGenerationListingIds,
    getBulkCrosslistingSessionPopulated,
    getListingsForReview,
    runBulkCrosslistingExtraRules,
    Logger,
    Colors
} from '@foxtail-dev/user-clients'
import { Divider, Box } from '@mui/material'
import { useState, useEffect, useCallback } from 'react'
import { BulkCrosslistReviewSummaryRow } from '../../components/bulkcrosslist/BulkCrosslistReviewSummaryRow'
import { CreateListingSkeleton } from '../../components/skeletons/CreateListingSkeleton'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { CheckBoxCheckedIcon } from '../../components/icons/CheckBoxChecked'
import { Error } from '@mui/icons-material'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

type BulkCrosslistReviewSummaryModalProps = {
    open: boolean
    onClose: () => void
}

export const BulkCrosslistReviewSummaryModal = ({ open, onClose }: BulkCrosslistReviewSummaryModalProps) => {
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const bulkCrosslistingSessionId = useAppSelector(selectBulkCrosslistingSessionId)
    const successfulListingIds = useAppSelector(selectBulkCrosslistingSuccessfullyGeneratedListingIds)
    const successCount = successfulListingIds.length
    const hasRunExtraRules = useAppSelector(selectBulkCrosslistingHasRunExtraRules)
    const failedListingIds = useAppSelector(selectBulkCrosslistingFailedGenerationListingIds)
    const failedCount = failedListingIds.length
    const totalCount = successCount + failedCount

    const getPopulatedSessionAndRunExtraRules = async () => {
        setIsLoading(true)
        try {
            const populatedSession = await dispatch(getBulkCrosslistingSessionPopulated()).unwrap()
            const listingSchemas = await dispatch(getListingsForReview()).unwrap()
            const extraRuleResponses = await dispatch(runBulkCrosslistingExtraRules(populatedSession)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'Ran extra rules for bulk crosslist session',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        totalListingsCount: listingSchemas.length,
                        failedExtraRulesPerListing: extraRuleResponses,
                        bulkCrosslistingSessionId
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to run extra rules',
                    payload: {
                        kind: 'BulkCrosslistExtraRulesError',
                        entry: {
                            bulkCrosslistingSessionId
                        }
                    }
                },
                error
            )
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (hasRunExtraRules) {
            return
        }

        getPopulatedSessionAndRunExtraRules()
    }, [hasRunExtraRules])

    const renderFailedItem = useCallback(
        ({ index, style }: ListChildComponentProps<string>) => {
            const listingId = failedListingIds[index] ?? ''
            return (
                <div style={style}>
                    <BulkCrosslistReviewSummaryRow key={listingId} listingId={listingId} />
                </div>
            )
        },
        [failedListingIds]
    )

    const renderSuccessfulItem = useCallback(
        ({ index, style }: ListChildComponentProps<string>) => {
            const listingId = successfulListingIds[index] ?? ''
            return (
                <div style={style}>
                    <BulkCrosslistReviewSummaryRow key={listingId} listingId={listingId} />
                </div>
            )
        },
        [successfulListingIds]
    )

    // TODO: Add icon for success / error
    return (
        <FoxModalDialog
            open={open}
            title={'Summary'}
            leftButtonKind={'close'}
            onClose={onClose}
            actions={
                <FoxButton
                    primary
                    text='Continue'
                    onFoxClick={{ kind: 'button', onClick: onClose, preventDoubleClick: true }}
                    sx={{ width: '100%', justifyContent: 'center' }}
                />
            }>
            {isLoading ? (
                <>
                    <CreateListingSkeleton />
                </>
            ) : (
                <>
                    <Box sx={styles.containerSubtext}>
                        <FoxTypography variant='body2' sx={styles.overviewText}>
                            Successfully generated details for {successfulListingIds.length} of {totalCount} listings. You can approve, reject, or modify the
                            details of any listings.
                        </FoxTypography>
                    </Box>
                    {failedCount > 0 && (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#F5F5F5', paddingLeft: '8px', borderRadius: '10px' }}>
                                <Error height={24} width={24} />
                                <FoxTypography variant='body2' sx={styles.text}>
                                    Errored - {failedCount} of {totalCount}
                                </FoxTypography>
                            </Box>
                            <FixedSizeList height={550} itemCount={failedListingIds.length} itemSize={96} width='100%'>
                                {renderFailedItem}
                            </FixedSizeList>
                            <Divider />
                        </>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#F5F5F5', paddingLeft: '8px', borderRadius: '10px' }}>
                        <CheckBoxCheckedIcon height={24} width={24} color={Colors.light.success} />
                        <FoxTypography variant='body2' sx={styles.text}>
                            Success - {successCount} of {totalCount}
                        </FoxTypography>
                    </Box>
                    <FixedSizeList height={550} itemCount={successfulListingIds.length} itemSize={96} width='100%'>
                        {renderSuccessfulItem}
                    </FixedSizeList>
                </>
            )}
        </FoxModalDialog>
    )
}

const styles = {
    containerSubtext: {
        marginTop: '16px',
        paddingHorizontal: '16px',
        marginBottom: '16px'
    },
    text: {
        textAlign: 'left',
        marginLeft: '12px',
        backgroundColor: '#F5F5F5',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: '500'
    },
    buttonContainerBottom: {
        marginBottom: '32px',
        marginTop: '32px'
    },
    categoryHeader: {
        backgroundColor: '#F5F5F5'
    },
    overviewText: {
        textAlign: 'left'
    }
}
