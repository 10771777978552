import { Box, Divider } from '@mui/material'
import { FoxTextField } from '../../components/formik/FoxTextField'
import { MarketSelectionField } from '../../components/formik/MarketSelectionField'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { PhotosField } from '../../components/formik/PhotosField'
import { useState } from 'react'
import { ImageEditParams, ImageModalParams, PhotoEditModal } from './PhotoEditModal'
import { PriceField } from '../../components/formik/PriceField'
import { FoxTypography } from '../../components/common/FoxTypography'
import { TitleField } from '../../components/formik/TitleField'

export type CreateListingCommonFieldsProps = {
    values: ListingDetailsDraft
    listingId: string
}

// Within the formik field
export const CreateListingCommonFields = (props: CreateListingCommonFieldsProps) => {
    const { values, listingId } = props

    const commonDetails = values.commonDetails
    const imageIds = values.imageIds ?? []

    const [imageModalParams, setImageModalParams] = useState<ImageModalParams>({
        open: false,
        imageId: '',
        uri: ''
    })

    const handleEditImage = (params: ImageEditParams) => {
        setImageModalParams({ ...params, open: true })
    }

    const clearImageModalParams = () => {
        setImageModalParams({ open: false, imageId: '', uri: '' })
    }

    return (
        <>
            <MarketSelectionField clearOnToggleOff={true} />

            <FoxTypography variant={'body2'} sx={{ ...styles.subSectionHeader }} bold>
                Info
            </FoxTypography>
            <Box sx={{ ...styles.subSectionContainer }}>
                <TitleField
                    name='commonDetails.title'
                    label='Title'
                    value={commonDetails?.title}
                    inputStyle={{ width: '100%' }}
                    logOnBlur
                    maxCharacterCount={80}
                    showWordCount={true}
                />
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <PriceField
                        name='commonDetails.price'
                        label='Price'
                        sx={{ flexGrow: 1 }}
                        inputStyle={{ paddingRight: '16px', width: '100%' }}
                        initialValue={commonDetails?.price?.toString()}
                    />
                    <FoxTextField
                        name='commonDetails.quantity'
                        label='Quantity'
                        value={commonDetails?.quantity?.toString()}
                        labelVariant='body1'
                        sx={{ flexGrow: 1 }}
                        inputStyle={{ width: '100%' }}
                        placeholder='0'
                    />
                </Box>
            </Box>

            <PhotosField name='imageIds' onEdit={handleEditImage} selectionLimit={24} />

            <PhotoEditModal {...imageModalParams} onClose={clearImageModalParams} existingImageIds={imageIds} />

            <FoxTextField
                name='foxtailSku'
                label='Foxtail Sku'
                value={values.foxtailSku}
                labelVariant='body1'
                inputStyle={{ width: '100%' }}
                titleSubtext='(optional)'
                placeholder='Your Foxtail Sku'
            />
            <Divider sx={{ marginBottom: '16px' }} />
        </>
    )
}

const styles = {
    subSectionHeader: {
        marginTop: '24px',
        marginBottom: '12px'
    },
    subSectionContainer: {
        paddingLeft: '8px'
    },
    actionButtonContainer: {
        marginTop: '12px',
        display: 'flex'
    }
}
