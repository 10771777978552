import { useMemo } from 'react'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FadingText } from '../../components/listing/AiLoadingText'
import { getAiLoadingWords } from '../../utils/getAiLoadingWords'
import { FoxTypography } from '../../components/common/FoxTypography'
import { useNavigate } from 'react-router-dom'
import { FoxProgressSpinner } from '../../components/common/FoxProgressSpinner'

type AiGenerateModalProps = {
    open: boolean
}

export const AiGenerateModal = ({ open }: AiGenerateModalProps) => {
    const navigate = useNavigate()
    const texts = useMemo(() => {
        return getAiLoadingWords()
    }, [open])

    const onClose = () => {
        navigate('/app/listings?tab=draft')
    }

    return (
        <FoxModalDialog
            open={open}
            onClose={onClose}
            leftButtonKind='close'
            title='Autofill listing details with AI'
            sx={{ padding: '0px', margin: '0px' }}
            removeOverlay
            actions={
                <FoxButton
                    text='Go to listings'
                    grey
                    onFoxClick={{ kind: 'internal', to: '/app/listings?tab=draft' }}
                    sx={{ width: '141px', display: 'flex', justifyContent: 'center' }}
                />
            }>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '48px',
                    alignItems: 'center'
                }}>
                <FoxProgressSpinner visible={open} />
                <Box
                    sx={{
                        marginTop: '36px',
                        marginBottom: '24px',
                        height: '36px',
                        width: '256px'
                    }}>
                    <FadingText texts={texts} />
                </Box>
            </Box>
            <FoxTypography variant='body1' light sx={{ width: '100%', textAlign: 'center' }}>
                You may leave this page, your listing will be saved to drafts.
            </FoxTypography>
        </FoxModalDialog>
    )
}
