import { Box, Divider } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors } from '../../theme/Colors'
import { DomainCommonName, time } from '@foxtail-dev/datacontracts'
import { CommonImages } from '../../theme/CommonImages'
import {
    checkProgressCompletion,
    getEbayLoginUrl,
    Logger,
    MarketInfo,
    selectIsExtensionInstalled,
    selectUserDomainDetails,
    useAppDispatch,
    useAppSelector,
    UserRuntimeContextProvider
} from '@foxtail-dev/user-clients'
import { useState } from 'react'
import { FoxImage } from '../../components/common/FoxImage'
import { AccountRequirementsModalMap } from './AccountRequirementsModal'
import { SetupNeededModal } from '../../modals/account/marketConnections/SetupNeededModal'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'
import { ExtensionInstallModal } from '../../modals/account/marketConnections/ExtensionInstallModal'

type ConnectAMarketplaceRowProps = {
    domainName: DomainCommonName
    setupRequired: boolean
    setEbayInventoryLocationModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export const ConnectAMarketplaceRow = ({ domainName, setupRequired, setEbayInventoryLocationModalOpen }: ConnectAMarketplaceRowProps): JSX.Element => {
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [setupRequiredModalOpen, setSetupRequiredModalOpen] = useState<boolean>(false)
    const [extensionInstallModalOpen, setExtensionInstallModalOpen] = useState<boolean>(false)
    const domainDetails = useAppSelector(selectUserDomainDetails)
    const isChromeExtensionInstalled = useAppSelector(selectIsExtensionInstalled)

    const connectMarket = async () => {
        const context = await UserRuntimeContextProvider.getContext()
        if (domainName === 'ebay') {
            const url = await dispatch(getEbayLoginUrl()).unwrap()
            await context.extensionClient.requestSignInToDomain({ domain: domainName, domainSignInUrl: url })
        } else {
            try {
                setIsLoading(true)
                await context.extensionClient.requestSignInToDomain({ domain: domainName })
                dispatch(checkProgressCompletion({ requestedQuestKinds: ['connectYourMarkets'], delayInMs: time.seconds(60) }))
                // TODO update marking linking start to awaiting sign in on web page after 5 mins if still in awaiting sign in reset it to not linked
                setIsLoading(false)
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        payload: {
                            kind: 'RequestSignInToDomain',
                            entry: {
                                domain: domainName
                            }
                        }
                    },
                    error
                )
                setIsLoading(false)
            }
        }
    }

    const onSetupRequired = async () => {
        if (domainName === 'ebay') {
            const hasMerchantLocationKey =
                domainDetails && domainDetails['ebay']?.fundamentals.merchantLocationKey && domainDetails['ebay']?.fundamentals.merchantLocationKey !== ''
            if (!hasMerchantLocationKey) {
                setEbayInventoryLocationModalOpen(true)
            } else {
                setSetupRequiredModalOpen(true)
            }
        } else {
            setSetupRequiredModalOpen(true)
        }
    }

    const onCloseSetupRequiredModal = (result: boolean) => {
        setSetupRequiredModalOpen(false)
        if (result) {
            connectMarket()
        }
    }

    const onConnect = async () => {
        if (!isChromeExtensionInstalled) {
            setExtensionInstallModalOpen(true)
            return
        }

        if (domainName === 'etsy' || domainName === 'mercari' || domainName === 'depop' || domainName === 'ebay') {
            setModalOpen(true)
        } else {
            await connectMarket()
        }
    }

    const closeModal = (result: boolean) => {
        setModalOpen(false)

        if (result) {
            connectMarket()
        }
    }

    const onCloseExtensionInstallModal = () => {
        setExtensionInstallModalOpen(false)
    }

    return (
        <>
            <Box sx={{ flexDirection: 'row', display: 'flex', height: 96, marginLeft: '31px' }}>
                <Box
                    sx={{
                        height: '52px',
                        width: '52px',
                        marginLeft: '15px',
                        marginTop: '18px',
                        marginRight: '26px',
                        borderRadius: '10px'
                    }}>
                    <FoxImage src={CommonImages.markets[domainName]} alt={domainName} style={{ borderRadius: '10px', height: '52px', width: '52px' }} />
                </Box>
                <Box sx={{ marginTop: '24px', marginBottom: '30px' }}>
                    <FoxTypography sx={{ marginBottom: '2px' }}>{MarketInfo[domainName].displayName}</FoxTypography>
                    <FoxTypography>{MarketInfo[domainName].url}</FoxTypography>
                </Box>
                {domainName === 'facebook' && (
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Box
                            sx={{
                                borderRadius: '10px',
                                height: '20px',
                                background: 'linear-gradient(84.45deg, #E84855 0%, #E87155 100%)',
                                marginLeft: '30px',
                                marginRight: '10px',
                                width: '50px',
                                justifyContent: 'center',
                                display: 'flex'
                            }}>
                            <FoxTypography color={Colors.light.background}>beta</FoxTypography>
                        </Box>
                    </Box>
                )}
                <FlexGrow />
                {setupRequired ? (
                    <FoxButtonBase
                        onFoxClick={{ kind: 'button', onClick: onSetupRequired }}
                        sx={{
                            height: '32px',
                            width: '120px',
                            borderRadius: '10px',
                            backgroundColor: 'rgb(255, 179, 15, 0.1)',
                            display: 'flex',
                            marginTop: '32px',
                            marginRight: '20px'
                        }}>
                        <FoxTypography
                            sx={{ marginTop: '7px', marginLeft: '18px', fontSize: '12px', lineHeight: '16px', fontWeight: 500, color: Colors.light.yellow }}>
                            Setup required
                        </FoxTypography>
                    </FoxButtonBase>
                ) : (
                    <FoxButtonBase
                        onFoxClick={{ kind: 'button', onClick: onConnect }}
                        sx={{
                            height: '32px',
                            width: '83px',
                            borderRadius: '10px',
                            backgroundColor: Colors.light.unfocusedInput,
                            display: 'flex',
                            marginTop: '32px',
                            marginRight: '20px'
                        }}>
                        <FoxTypography sx={{ marginTop: '7px', marginLeft: '18px', fontSize: '12px', lineHeight: '16px', fontWeight: 500 }}>
                            Connect
                        </FoxTypography>
                    </FoxButtonBase>
                )}
            </Box>
            <Divider sx={{ marginLeft: '31px' }} />
            {(domainName === 'etsy' || domainName === 'mercari' || domainName === 'depop' || domainName === 'ebay') &&
                AccountRequirementsModalMap[domainName]({
                    open: modalOpen,
                    onClose: closeModal
                })}

            <SetupNeededModal onClose={onCloseSetupRequiredModal} open={setupRequiredModalOpen} domainName={domainName} />
            <ExtensionInstallModal onClose={onCloseExtensionInstallModal} open={extensionInstallModalOpen} />
        </>
    )
}

const styles = {}
