import { UserQuestKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'

export const questKindToRouteMap: Record<UserQuestKind, string> = {
    connectYourMarkets: '/app/account/marketplace-connections',
    listAnItem: '/app/create-listing/new',
    importExistingListings: '/app/import-listing'
}

export const questKindToButtonTextMap: Record<UserQuestKind, string> = {
    connectYourMarkets: 'Connect marketplaces',
    listAnItem: 'Crosslist',
    importExistingListings: 'Import listings'
}
