import { useState } from 'react'
import { FacebookDeliveryMethod, FacebookShippingOption, FacebookShippingCarrier } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { useAppSelector, selectFacebookIsShippingEnabled } from '@foxtail-dev/user-clients'
import { FoxTypography } from '../../../../components/common/FoxTypography'
import { CheckboxGroupField } from '../../../../components/formik/CheckboxGroupField'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { RadioBoolGroupField, RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { PriceField } from '../../../../components/formik/PriceField'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { FacebookSyncShippingModal } from '../../../../modals/domainSpecific/FacebookSyncShippingModal'
import { ListingDefaultFieldsMapProps } from './ListingDefaultsFieldsMap'
import { FacebookAiListingSuggestionDefaultsConfig } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import { NumberField } from '../../../../components/formik/NumberField'

export const FacebookListingDefaultFields = (props: ListingDefaultFieldsMapProps) => {
    const { values } = props

    const [modalOpen, setModalOpen] = useState<string>('none')

    const onCloseModal = () => {
        setModalOpen('none')
    }

    const isShippingEnabled = useAppSelector(selectFacebookIsShippingEnabled)

    const suggestionDefaults = values.domainDetails.facebook?.suggestionDefaults

    // as any is fine here because it gets parsed on form submit - we need access to the non-generic version of the config
    const unsafeSuggestionDefaults = suggestionDefaults as any as FacebookAiListingSuggestionDefaultsConfig

    const isShipping = unsafeSuggestionDefaults?.requiredDetails.deliveryMethods?.includes('Shipping')
    const usePrepaidLabel = unsafeSuggestionDefaults?.optionalDetails.shippingOption === 'Use a prepaid shipping label'
    const deliveryMethods: FacebookDeliveryMethod[] = isShippingEnabled ? FacebookDeliveryMethod.options : ['Local pickup']

    // TODO: Zip code validation
    return (
        <>
            <NumberField
                name='domainDetails.facebook.suggestionDefaults.requiredDetails.postalCode'
                label='Postal code'
                placeholder='Enter postal code'
                initialValue={unsafeSuggestionDefaults?.requiredDetails.postalCode}
                logOnBlur
            />
            <RadioBoolGroupField
                label='Hide from friends'
                name='domainDetails.facebook.suggestionDefaults.requiredDetails.hideFromFriends'
                iconType='Radio'
                options={[
                    [true, 'True'],
                    [false, 'False']
                ]}
                logOnChange
            />

            <CheckboxGroupField
                name='domainDetails.facebook.suggestionDefaults.requiredDetails.deliveryMethods'
                label='Delivery methods'
                options={deliveryMethods}
                iconType='Checkbox'
                logOnChange
            />

            {!isShippingEnabled && (
                <>
                    <DetailSelectionField
                        name='none'
                        label='Facebook Shipping'
                        placeholder='Setup Facebook Shipping'
                        onDetail={() => setModalOpen('FacebookShipping')}
                    />
                    <DomainSpecificDetailModal open={modalOpen === 'FacebookShipping'} title={'Facebook Shipping'} closeModal={onCloseModal}>
                        <FacebookSyncShippingModal closeModal={onCloseModal} />
                    </DomainSpecificDetailModal>
                </>
            )}

            {isShipping && (
                <>
                    <RadioGroupField
                        label='Shipping option'
                        name='domainDetails.facebook.suggestionDefaults.optionalDetails.shippingOption'
                        iconType='Radio'
                        options={FacebookShippingOption.options}
                        logOnChange
                    />

                    {usePrepaidLabel ? (
                        <RadioGroupField
                            name='domainDetails.facebook.suggestionDefaults.optionalDetails.shippingCarrier'
                            label='Shipping carrier'
                            iconType='Radio'
                            options={FacebookShippingCarrier.options}
                            logOnChange
                        />
                    ) : (
                        <PriceField
                            name='domainDetails.facebook.suggestionDefaults.optionalDetails.shippingRate'
                            label='Shipping rate'
                            logOnBlur
                            initialValue={unsafeSuggestionDefaults?.optionalDetails.shippingRate}
                        />
                    )}

                    <RadioBoolGroupField
                        label='Free shipping'
                        name='domainDetails.facebook.suggestionDefaults.optionalDetails.freeShipping'
                        iconType='Radio'
                        options={[
                            [true, 'Seller pays'],
                            [false, 'Buyer pays']
                        ]}
                        logOnChange
                    />

                    <FoxTypography light variant='body1' sx={{ marginBottom: '16px' }}>
                        Save time by letting buyers quickly offer an amount they're willing to pay. You will have the option to accept or decline the offer.
                    </FoxTypography>
                    <RadioBoolGroupField
                        label='Allow offers'
                        name='domainDetails.facebook.suggestionDefaults.optionalDetails.allowOffers'
                        iconType='Radio'
                        options={[
                            [true, 'True'],
                            [false, 'False']
                        ]}
                        logOnChange
                    />
                </>
            )}
        </>
    )
}
