import { Box, Link } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { generateMainTextForConnectAndSetupMarketsBeforeListing } from '../../utils/generateMainTextForConnectAndSetupMarketsBeforeListingModal'
import { useNavigate } from 'react-router-dom'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'

export type ConnectAndSetupMarketsBeforeListingModalProps = {
    open: boolean
    onClose: (params: { result: boolean; currentValues: ListingDetailsDraft }) => Promise<void>
    marketsNeedingSetup: DomainCommonName[]
    marketsNeedingLinking: DomainCommonName[]
    currentValues: ListingDetailsDraft
}

export const ConnectAndSetupMarketsBeforeListingModal = ({
    open,
    onClose,
    marketsNeedingLinking,
    marketsNeedingSetup,
    currentValues
}: ConnectAndSetupMarketsBeforeListingModalProps) => {
    const { subtitle, title } = generateMainTextForConnectAndSetupMarketsBeforeListing({ marketsNeedingLinking, marketsNeedingSetup })
    const navigate = useNavigate()

    return (
        <FoxModalDialog
            open={open}
            title={'Market Connections'}
            leftButtonKind={'close'}
            onClose={() => onClose({ currentValues, result: false })}
            closeOnBackdropClick>
            <Box sx={{ textAlign: 'center', width: '100%' }}>
                <FoxTypography variant='subtitle1' sx={{ marginBottom: '40px', marginTop: '40px' }}>
                    {title}
                </FoxTypography>
                <FoxTypography variant='body2' sx={{ marginBottom: '40px' }}>
                    {subtitle}
                </FoxTypography>
                <FoxButton
                    primary
                    onFoxClick={{
                        kind: 'button',
                        onClick: async () => {
                            await onClose({ currentValues, result: true })
                        },
                        preventDoubleClick: true
                    }}
                    text={'Save as draft'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
                <FoxButton
                    grey
                    onFoxClick={{
                        kind: 'button',
                        onClick: async () => {
                            onClose({ currentValues, result: false })
                            navigate('/app/account/market-connections')
                        }
                    }}
                    text={'Go to market connections'}
                    sx={{ marginTop: '24px', width: '100%', justifyContent: 'center' }}
                />
            </Box>
        </FoxModalDialog>
    )
}
