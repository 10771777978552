import {
    Logger,
    useAppDispatch,
    useAppSelector,
    selectListingDetails,
    selectListingId,
    uploadImage,
    addImageIds,
    SUPPORTED_IMAGE_TYPES,
    Colors
} from '@foxtail-dev/user-clients'
import React, { useEffect, useState } from 'react'
import { generateToast } from '../../lib/clients/ToastClient'
import { sharedStyles } from '../../theme/SharedStyling'
import { FormikField } from './FormikField'
import { Box, CircularProgress, useTheme } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import ProductImage, { ImageEditParams } from '../upload/ProductImage'
import ProductImagePlaceholder from '../upload/ProductImagePlaceholder'
import { FoxButton } from '../common/FoxButton'
import { FileRejection, DropEvent, ErrorCode } from 'react-dropzone'
import { AddImageDropzone } from '../upload/AddImageDropzone'
import { validateImageResolutions } from '../../utils/imageValidation'
import { FlexGrow } from '../common/FlexGrow'
import { FoxIconButton } from '../common/FoxIconButton'
import { HelpIcon } from '../icons/HelpIcon'

export type PhotosFieldProps = {
    name: string
    selectionLimit: number
    onEdit: (params: ImageEditParams) => void
}

export const PhotosField = ({ name, onEdit, selectionLimit }: PhotosFieldProps) => {
    const dispatch = useAppDispatch()
    const listingDetails = useAppSelector(selectListingDetails)
    const listingId = useAppSelector(selectListingId)
    const [uploadingImages, setUploadingImages] = useState<boolean>(false)
    const [pendingCount, setPendingCount] = useState<number>(0)
    const [selectingCovershot, setSelectingCovershot] = useState<boolean>(false)
    const [photoLoadingButNoneAddedToRedux, setPhotoLoadingButNoneAddedToRedux] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null) // Set error separately from redux so we can let the images that succeeded go through while still showing the errors

    const theme = useTheme()
    const containerStyle = {
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius
    }
    useEffect(() => {
        setSelectingCovershot(false)
    }, [listingDetails])

    const onSelectCovershot = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User hit select covershot button',
            payload: {
                kind: 'UserAction',
                entry: {
                    listingId: listingId,
                    selectingCovershot: !selectingCovershot
                }
            }
        })
        setSelectingCovershot(!selectingCovershot)
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <FoxTypography variant='body2' bold>
                    Photos
                </FoxTypography>
                <FoxIconButton
                    onFoxClick={{ kind: 'external', href: 'https://help.foxtail.ai/en/articles/8660553-how-do-i-manage-my-photos' }}
                    sx={{ padding: 0, marginLeft: '8px' }}>
                    <HelpIcon color={Colors.light.lightText} height={16} width={16} />
                </FoxIconButton>

                <FlexGrow />
                {listingDetails.imageIds && listingDetails.imageIds.length > 1 && (
                    <FoxButton
                        onFoxClick={{ kind: 'button', onClick: onSelectCovershot }}
                        variant='outlined'
                        sx={{ justifyContent: 'center', width: '160px', borderRadius: '20px' }}
                        text={selectingCovershot ? 'Cancel' : 'Select covershot'}
                        grey
                    />
                )}
            </Box>
            <FormikField
                name={name}
                render={({ value, onChange }) => {
                    const currentImageIds: string[] = value || []

                    const limit = selectionLimit - currentImageIds.length - pendingCount
                    const onAddImage = async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
                        setError(null)
                        Logger.I().log({
                            level: 'info',
                            message: 'User is beginning to upload photos',
                            payload: {
                                kind: 'UserAction',
                                entry: {
                                    listingId: listingId
                                }
                            }
                        })
                        setUploadingImages(true)
                        setPhotoLoadingButNoneAddedToRedux(true)

                        let error: string | null = null

                        if (limit <= 0) {
                            Logger.I().log({
                                level: 'info',
                                message: 'User reached photo upload limit',
                                payload: {
                                    kind: 'UserAction',
                                    entry: {
                                        listingId: listingId
                                    }
                                }
                            })
                            setError('Reached photo upload limit')
                            return
                        }

                        const { acceptedFiles: resolutionAcceptedFiles, rejectedFiles: resolutionRejectedFiles } = await validateImageResolutions(
                            acceptedFiles,
                            500,
                            500
                        )

                        const allRejectedFiles = [...fileRejections, ...resolutionRejectedFiles]

                        if (allRejectedFiles.length > 0) {
                            // If too many files are uploaded, stop, we don't know which images to choose from. Let the user try again
                            const tooManyFilesUploaded = allRejectedFiles.some((rejection) =>
                                rejection.errors.some((error) => error.code === ErrorCode.TooManyFiles)
                            )

                            if (tooManyFilesUploaded) {
                                error = `Too many files were uploaded. You may only upload a limit of ${selectionLimit} images.`
                                setError(error)
                                return
                            }

                            // If certain files are rejected, let the user know, but continue uploading the rest that passed
                            const rejectionCount = allRejectedFiles.length
                            const supportedImageTypeStr = SUPPORTED_IMAGE_TYPES.join(', ')

                            const errorMessagePart = rejectionCount === 1 ? 'file was rejected' : 'files were rejected'
                            error = `${rejectionCount} ${errorMessagePart}. Images must be in the following formats: ${supportedImageTypeStr}, less than 10MB in size, and have a minimum resolution of 500px by 500px.`
                            setError(error)
                        }

                        const finalAcceptedFiles = resolutionAcceptedFiles

                        try {
                            setPendingCount((p) => p + finalAcceptedFiles.length)

                            const imageLocators = await Promise.all(
                                finalAcceptedFiles.map(async (file) => {
                                    return dispatch(uploadImage({ kind: 'web', file })).unwrap()
                                })
                            )
                            const imageIds = imageLocators.map((locator) => locator.id)

                            dispatch(addImageIds(imageIds))

                            setPendingCount((p) => p - finalAcceptedFiles.length)
                            onChange([...currentImageIds, ...imageIds])

                            Logger.I().log({
                                level: 'info',
                                message: 'User uploaded photos',
                                payload: {
                                    kind: 'UserAction',
                                    entry: {
                                        listingId: listingId,
                                        imageLocators: imageLocators,
                                        errorCount: allRejectedFiles.length
                                    }
                                }
                            })
                        } catch (error) {
                            generateToast({ kind: 'info', message: 'unable to upload images' })
                            Logger.I().log(
                                {
                                    level: 'error',
                                    message: 'threw error while trying to upload images in PhotosField.tsx',
                                    payload: {
                                        kind: 'PhotosFieldException',
                                        entry: {
                                            listingId: listingId
                                        }
                                    }
                                },
                                error
                            )
                        }

                        setPhotoLoadingButNoneAddedToRedux(false)
                        setUploadingImages(false)
                    }

                    // TODO: Implement styling
                    return (
                        <Box sx={{ marginTop: '17px' }}>
                            {currentImageIds.length === 0 && pendingCount === 0 && (
                                <Box sx={{ marginBottom: 2 }}>
                                    {limit >= 0 ? (
                                        <AddImageDropzone onAddImage={onAddImage} limit={limit} />
                                    ) : (
                                        <Box sx={[containerStyle, styles.container]}>
                                            <FoxTypography variant='body1'>You have reached the maximum number of photos</FoxTypography>
                                        </Box>
                                    )}
                                </Box>
                            )}

                            <Box sx={{ display: 'flex', width: '100%' }}>
                                {currentImageIds.length > 0 || pendingCount > 0 ? (
                                    <Box sx={{ display: 'flex', marginBottom: '8px', flexWrap: 'wrap' }}>
                                        {currentImageIds.map((imgId, index) => (
                                            <ProductImage
                                                key={imgId}
                                                sx={styles.photo}
                                                imageId={imgId}
                                                onEdit={onEdit}
                                                description={index === 0 ? 'Covershot' : ''}
                                                isCoverShot={index === 0}
                                                selectingCovershot={selectingCovershot}
                                                setSelectingCovershot={setSelectingCovershot}
                                                existingImageIds={currentImageIds}
                                            />
                                        ))}
                                        {Array(pendingCount)
                                            .fill('pending')
                                            .map((p, i) => (
                                                <ProductImagePlaceholder key={`pending_${i}`} sx={styles.photo} />
                                            ))}
                                        <AddImageDropzone onAddImage={onAddImage} limit={limit} small />
                                    </Box>
                                ) : photoLoadingButNoneAddedToRedux ? (
                                    <Box sx={[containerStyle, styles.container]}>
                                        <CircularProgress size='large' />
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </Box>
                            {error && (
                                <FoxTypography variant='body1' color='error'>
                                    {error}
                                </FoxTypography>
                            )}
                        </Box>
                    )
                }}
            />
        </>
    )
}

const styles = {
    photo: {
        marginRight: '12px'
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        height: '80px',
        width: '80px',
        padding: '20px',
        borderWidth: '2px'
    }
}
