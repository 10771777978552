import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'
import { useNavigate } from 'react-router-dom'

type ImportAwaitingApprovalNotificationProps = {
    domainName: DomainCommonName
}

export const ImportAwaitingApprovalNotification = (props: ImportAwaitingApprovalNotificationProps): JSX.Element => {
    const { domainName } = props
    const navigate = useNavigate()
    const handleGoToImport = () => {
        navigate('/app/import-listing')
    }

    return (
        <>
            <FoxImage src={CommonImages.markets[domainName]} style={styles.image} />
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {Domains[domainName].displayName} import is ready to review!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginLeft: '50px', marginRight: '50px' }}>
                You can now decide which {Domains[domainName].displayName} listings you would like to import into Foxtail
            </FoxTypography>
            <FoxButton text='Go to import' onFoxClick={{ kind: 'button', onClick: handleGoToImport }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
