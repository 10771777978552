import { AppDispatch, setCredentials, useAppDispatch, UserRuntimeContext, UserRuntimeContextProvider } from '@foxtail-dev/user-clients'
import { useEffect } from 'react'

declare global {
    interface Window {
        foxGodMode: {
            signInAsUser: (bearerToken: string) => void
            getContext: () => Promise<UserRuntimeContext>
            getBearerToken: () => Promise<string>
            loaded: boolean
        }
    }
}

const signInAsUser = (dispatch: AppDispatch, bearerToken: string) => {
    dispatch(setCredentials({ credentials: { accessToken: bearerToken } }))
}

const getContext = async () => {
    return UserRuntimeContextProvider.getContext()
}

const getBearerToken = async () => {
    const context = await getContext()
    return context.userApiClient.config.accessToken
}

export const FOX_GOD_MODE = () => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        window.foxGodMode = {
            signInAsUser: (bearerToken: string) => signInAsUser(dispatch, bearerToken),
            getContext,
            getBearerToken,
            loaded: true
        }
    }, [])

    return null
}
