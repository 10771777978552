import { NotificationDetails, SubscriptionExpiredNotificationDetails } from '@foxtail-dev/datacontracts/dist/lib/schemas/notifications/NotificationDetails'
import { Logger } from '../lib/clients/Logger'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { useAppSelector, getListingById, useAppDispatch, selectUser } from '@foxtail-dev/user-clients'
import React, { useEffect, useCallback } from 'react'
import { generateToast } from '../lib/clients/ToastClient'
import { assert, DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Box } from '@mui/material'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxImage } from '../components/common/FoxImage'
import { CommonImages } from '../theme/CommonImages'
import { FoxListingImage } from '../components/common/FoxListingImage'
import { FoxButton } from '../components/common/FoxButton'
import { useNavigate } from 'react-router-dom'
import * as Intercom from '@intercom/messenger-js-sdk'
import { ImportAwaitingApprovalNotification } from '../components/notifications/ImportAwaitingApprovalNotification'
import { ListingMarketSuccessfulLinkNotification } from '../components/notifications/ListingMarketSuccessfulLinkNotification'
import { ListingCreatedNotification } from '../components/notifications/ListingCreatedNotification'
import { ListingSuccessfulPublishNotification } from '../components/notifications/ListingSuccessfulPublishNotification'
import { ListingRelistedItemNotification } from '../components/notifications/ListingRelistedItemNotification'
import { ListingSuccessfulImportNotification } from '../components/notifications/ListingSuccessfulImportNotification'
import { ListingFailedToCreateNotification } from '../components/notifications/ListingFailedToCreateNotification'
import { ListingFailedToRelistNotification } from '../components/notifications/ListingFailedToRelistNotification'
import { ImportFinishedScrapingNotification } from '../components/notifications/ImportFinishedScrapingNotification'
import { BulkCrosslistingFinishedGenerationNotification } from '../components/notifications/BulkCrosslistingFinishedGenerationNotification'
import { ListingMarketRequiresAuthenticationNotification } from '../components/notifications/ListingMarketRequiresAuthentication'
import { ListingDeletedItemNotification } from '../components/notifications/ListingDeletedItemNotificaiton'
import { ListingFailedToDelistNotification } from '../components/notifications/ListingFailedToDelistNotification'
import { ListingMarketSetupRequiredNotification } from '../components/notifications/ListingMarketSetupRequired'
import { ListingRememberToPostListingsNotification } from '../components/notifications/ListingRememberToPostListingsNotification'
import { ListingSoldItemNotification } from '../components/notifications/ListingSoldItemNotificaiton'
import { ListingSoldOutNotification } from '../components/notifications/ListingSoldOutNotification'
import { SubscriptionExpiredNotification } from '../components/notifications/SubscriptionExpiredNotification'
import { SubscriptionFreeTrialNearlyExpiredNotification } from '../components/notifications/SubscriptionFreeTrialNearlyExpiredNotification'
import { SubscriptionNearlyExpiredNotification } from '../components/notifications/SubscriptionNearlyExpiredNotification'
import { SubscriptionQuotaNearlyExceededNotification } from '../components/notifications/SubscriptionQuotaNearlyExceededNotification'
import { DefaultNotification } from '../components/notifications/DefaultNotification'

type NotificationInfoProps = {
    notificationDetails: NotificationDetails
    notificationBody: string
}

// TODO: This (+ the ios implementation) needs a rewrite. Need to create a set of a few components and then map each notification to a component
export const NotificationInfo = ({ notificationDetails, notificationBody }: NotificationInfoProps) => {
    const dispatch = useAppDispatch()
    const [listing, setListing] = React.useState<ListingSchema | undefined>(undefined)

    const imageIds = listing?.listingDescription.listingDetails.imageIds
    const imageId = imageIds ? imageIds[0] : undefined

    const getListingByIdAsync = async (listingId: string) => {
        try {
            const listing = await dispatch(getListingById(listingId)).unwrap()
            setListing(listing)
        } catch (error) {
            logError('Failed to get listing by id', error)
            generateToast({ kind: 'info', message: 'Error finding listing' })
        }
    }

    useEffect(() => {
        const notificationKind = notificationDetails.kind
        // TODO: Do this in a better way. Listing all of the notification kinds that have listingIds
        if (
            notificationKind === 'listing/created' ||
            notificationKind === 'listing/deleted-item' ||
            notificationKind === 'listing/failed-to-create' ||
            notificationKind === 'listing/failed-to-delist' ||
            notificationKind === 'listing/failed-to-relist' ||
            notificationKind === 'listing/relisted-item' ||
            notificationKind === 'listing/sold-item' ||
            notificationKind === 'listing/sold-out' ||
            notificationKind === 'listing/successful-import' ||
            notificationKind === 'listing/successful-publish'
        ) {
            getListingByIdAsync(notificationDetails.listingId)
        }
    }, [notificationDetails])

    const logError = useCallback(
        (message: string, error?: any) => {
            Logger.I().log(
                {
                    level: 'error',
                    message: message,
                    payload: {
                        kind: 'NotificationInfoError',
                        entry: {
                            notificationDetails,
                            notificationBody
                        }
                    }
                },
                error
            )
        },
        [notificationDetails]
    )

    switch (notificationDetails.kind) {
        case 'listing/market-successful-link':
            return <ListingMarketSuccessfulLinkNotification domainName={notificationDetails.domainName} />
        case 'listing/created':
            return <ListingCreatedNotification imageId={imageId} listing={listing} />
        case 'listing/successful-publish':
            return (
                <ListingSuccessfulPublishNotification
                    listingInstanceId={notificationDetails.listingInstanceId}
                    listing={listing}
                    imageId={imageId}
                    logError={logError}
                />
            )
        case 'listing/relisted-item':
            return (
                <ListingRelistedItemNotification
                    imageId={imageId}
                    listing={listing}
                    listingInstanceId={notificationDetails.listingInstanceId}
                    logError={logError}
                />
            )
        case 'listing/successful-import':
            return <ListingSuccessfulImportNotification domainName={notificationDetails.domainName} imageId={imageId} listing={listing} />
        case 'listing/failed-to-create':
            return <ListingFailedToCreateNotification domainName={notificationDetails.domainName} imageId={imageId} listing={listing} />
        case 'listing/failed-to-relist':
            return <ListingFailedToRelistNotification domainName={notificationDetails.domainName} imageId={imageId} listing={listing} />
        case 'importListing/finishedScraping':
            return (
                <ImportFinishedScrapingNotification
                    domainName={notificationDetails.domainName}
                    scrapedListingCount={notificationDetails.scrapedListingItemCount}
                />
            )
        case 'bulkCrosslisting/finishedGeneration':
            return <BulkCrosslistingFinishedGenerationNotification domains={notificationDetails.selectedCrosslistingDomains} />
        case 'importListing/awaiting-approval':
            return <ImportAwaitingApprovalNotification domainName={notificationDetails.domain} />
        case 'listing/market-requires-authentication':
            return <ListingMarketRequiresAuthenticationNotification domainName={notificationDetails.domainName} />
        case 'listing/deleted-item':
            return (
                <ListingDeletedItemNotification
                    imageId={imageId}
                    listing={listing}
                    listingInstanceId={notificationDetails.listingInstanceId}
                    logError={logError}
                />
            )
        case 'listing/failed-to-delist':
            return <ListingFailedToDelistNotification domainName={notificationDetails.domainName} imageId={imageId} listing={listing} />
        case 'listing/market-setup-required':
            return <ListingMarketSetupRequiredNotification domainName={notificationDetails.domainName} />
        case 'listing/remember-to-post-listings':
            return <ListingRememberToPostListingsNotification />
        case 'listing/sold-item':
            return (
                <ListingSoldItemNotification
                    listing={listing}
                    imageId={imageId}
                    logError={logError}
                    listingInstanceId={notificationDetails.listingInstanceId}
                />
            )

        case 'listing/sold-out':
            return <ListingSoldOutNotification listing={listing} imageId={imageId} />
        case 'subscription/expired':
            return <SubscriptionExpiredNotification />
        case 'subscription/free-trial-nearly-expired':
            return <SubscriptionFreeTrialNearlyExpiredNotification />
        case 'subscription/nearly-expired':
            return <SubscriptionNearlyExpiredNotification />
        case 'subscription/quota-nearly-exceeded':
            return <SubscriptionQuotaNearlyExceededNotification />
        default:
            return <DefaultNotification notificationBody={notificationBody} />
    }
}
