import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { handleContactUs } from '../../utils/handleContactUs'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { ListingsIcon } from '../icons/ListingsIcon'
import { Colors } from '@foxtail-dev/user-clients'
import * as Intercom from '@intercom/messenger-js-sdk'

type ListingMarketSetupRequiredNotificationProps = {
    domainName: DomainCommonName
}

export const ListingMarketSetupRequiredNotification = (props: ListingMarketSetupRequiredNotificationProps): JSX.Element => {
    const { domainName } = props
    const handleHelpDocs = () => {
        Intercom.showArticle('8054964')
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <FoxImage src={CommonImages.markets[domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                <Box sx={{ marginTop: '32px', display: 'flex', alignItems: 'center', height: '80px', width: '80px', justifyContent: 'center' }}>
                    <ListingsIcon color={Colors.light.tertiary} height={80} width={80} />
                </Box>
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {Domains[domainName].displayName} is not fully setup
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginLeft: '80px', marginRight: '80px' }}>
                Your {Domains[domainName].displayName} account requires additional setup before Foxtail can manage you listings. See the help docs or contact
                support if you need help on what is required.
            </FoxTypography>
            <FoxButton text='Help docs' onFoxClick={{ kind: 'button', onClick: handleHelpDocs }} primary sx={styles.button} />
            <FoxButton text='Contact us' onFoxClick={{ kind: 'button', onClick: handleContactUs }} grey sx={styles.buttonContactUs} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    },
    buttonContactUs: {
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
