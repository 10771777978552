import { DomainCommonName, Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { ListingInstance } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingInstance'

type ListingSuccessfulImportNotificationProps = {
    listing?: ListingSchema
    imageId?: string
    domainName: DomainCommonName
}

export const ListingSuccessfulImportNotification = (props: ListingSuccessfulImportNotificationProps): JSX.Element => {
    const { listing, imageId, domainName } = props
    const navigate = useNavigate()
    const handleGoToListing = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    if (!listing || !imageId || listing.listingDescription.kind === 'draft') {
        return <></>
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FoxImage src={CommonImages.markets[domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {listing.listingDescription.listingDetails.commonDetails?.title} has been imported from {Domains[domainName].displayName}!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                You can now edit or crosslist this item to other marketplaces from the listing tab.
            </FoxTypography>
            <FoxButton text='View listing' onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
