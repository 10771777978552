import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { handleContactUs } from '../../utils/handleContactUs'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'

type DefaultNotificationProps = {
    notificationBody: string
}

export const DefaultNotification = (props: DefaultNotificationProps): JSX.Element => {
    const { notificationBody } = props

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FoxImage src={CommonImages.general.foxtail} style={{ ...styles.image }} />
            </Box>
            <FoxTypography variant='body2' sx={styles.text}>
                {notificationBody}
            </FoxTypography>
            <FoxButton grey text='Contact us' onFoxClick={{ kind: 'button', onClick: handleContactUs }} sx={styles.button} />
        </>
    )
}

const styles = {
    text: {
        textAlign: 'center',
        marginTop: '48px',
        marginLeft: '40px',
        marginRight: '40px'
    },
    image: {
        height: '140px',
        width: '140px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
