import { useEffect, useState } from 'react'
import { FoxTextField } from './FoxTextField'
import { useFormikContext } from 'formik'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { Logger } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxTypography } from '../common/FoxTypography'
import { FoxButton } from '../common/FoxButton'
import { AutoAwesome } from '@mui/icons-material'
import { FlexGrow } from '../common/FlexGrow'
import { sharedStyles } from '../../theme/SharedStyling'

export type DescriptionFieldProps = {
    name: string
    value?: string
    label?: string
    showWordCount?: boolean
    maxCharacterCount?: number
    placeholder?: string
    isLoading?: boolean
    disabled?: boolean
    logOnBlur?: boolean
    autogenerateButton?: boolean
    handleAutogenerate?: (onChange: (value: string) => void) => Promise<void>
}

export const DescriptionField = ({
    name,
    value = '',
    label,
    placeholder,
    isLoading,
    showWordCount,
    maxCharacterCount,
    disabled = false,
    logOnBlur,
    autogenerateButton,
    handleAutogenerate
}: DescriptionFieldProps) => {
    const [characterCount, setCharacterCount] = useState<number>(0)

    const { values, setFieldError, setFieldTouched, setFieldValue, setErrors } = useFormikContext<ListingDetailsDraft>()
    const description = values.commonDetails?.description

    useEffect(() => {
        setCharacterCount(description?.length || 0)
    }, [description])

    const isCharacterCountExceeded = !!maxCharacterCount && characterCount > maxCharacterCount

    const onBlur = (value: string): string => {
        if (logOnBlur) {
            Logger.I().log({
                level: 'info',
                message: 'User entered a description',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        name,
                        descriptionLength: value.length
                    }
                }
            })
        }
        return value
    }

    const onClear = async () => {
        Logger.I().log({
            level: 'info',
            message: 'User cleared description',
            payload: {
                kind: 'UserAction'
            }
        })

        // TODO: See if we can get the ref from the TextFields somehow and call that instead of setting each of the fields manually
        setFieldTouched('commonDetails.description', false)
        setFieldError('commonDetails.description', '')
        setErrors({})
        setFieldValue('commonDetails.description', '')
        setCharacterCount(0)
    }

    // TODO: See if we can get the ref from the TextFields somehow and call that instead of setting each of the fields manually
    const onChange = (value: string) => {
        setFieldTouched('commonDetails.description', true)
        setFieldError('commonDetails.description', '')
        setErrors({})
        setFieldValue('commonDetails.description', value)
        setCharacterCount(value.length)
    }

    return (
        <>
            <Box sx={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                <FoxTypography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {label}
                </FoxTypography>
                <FlexGrow />
                {autogenerateButton && handleAutogenerate && (
                    <FoxButton
                        variant='contained'
                        disabled={isLoading}
                        onFoxClick={{ kind: 'button', onClick: async () => await handleAutogenerate(onChange), preventDoubleClick: true }}
                        text={!!description ? 'AI complete' : 'AI generate'}
                        sx={{ marginRight: '16px', fontWeight: 'bold' }}
                        startIcon={<AutoAwesome />}
                        grey
                    />
                )}

                <FoxButton
                    variant='contained'
                    disabled={isLoading}
                    onFoxClick={{ kind: 'button', onClick: onClear }}
                    text={'Clear'}
                    sx={{ fontWeight: 'bold' }}
                    grey
                />
            </Box>
            <FoxTextField
                textInputProps={{
                    multiline: true,
                    rows: 7,
                    sx: {
                        width: '100%',
                        ...sharedStyles.textField
                    }
                }}
                placeholder='Add description'
                value={value}
                disabled={disabled}
                onBlur={onBlur}
                additionalOnChange={(value: string) => setCharacterCount(value.length)}
                name={name}
            />
            {showWordCount && (
                <Box sx={styles.wordCountContainer}>
                    <FoxTypography variant='body1' light={!isCharacterCountExceeded} danger={isCharacterCountExceeded}>
                        character count ({characterCount} / {maxCharacterCount})
                    </FoxTypography>
                </Box>
            )}
        </>
    )
}

const styles = {
    wordCountContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}
