import { useEffect, useState } from 'react'
import { FoxTextField } from './FoxTextField'
import { useFormikContext } from 'formik'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { Logger } from '@foxtail-dev/user-clients'
import { TextFieldProps } from '@mui/material'
import { Colors } from '../../theme/Colors'

export type TitleFieldProps = {
    name: string
    value?: string
    label?: string
    showWordCount?: boolean
    maxCharacterCount?: number
    disabled?: boolean
    logOnBlur?: boolean
    inputStyle?: TextFieldProps['sx']
}

export const TitleField = ({ name, value = '', label, showWordCount, maxCharacterCount, disabled = false, logOnBlur, inputStyle }: TitleFieldProps) => {
    const [characterCount, setCharacterCount] = useState<number>(0)

    const { values } = useFormikContext<ListingDetailsDraft>()
    const title = values.commonDetails?.title

    useEffect(() => {
        setCharacterCount(title?.length || 0)
    }, [title])

    const isCharacterCountExceeded = !!maxCharacterCount && characterCount > maxCharacterCount

    const onBlur = (value: string): string => {
        if (logOnBlur) {
            Logger.I().log({
                level: 'info',
                message: 'User entered a title',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        name,
                        titleLength: value.length
                    }
                }
            })
        }
        return value
    }

    return (
        <FoxTextField
            label={label}
            placeholder='Your title'
            value={value}
            disabled={disabled}
            onBlur={onBlur}
            additionalOnChange={(value: string) => setCharacterCount(value.length)}
            name={name}
            inputStyle={inputStyle}
            suffix={showWordCount ? `${characterCount}/${maxCharacterCount}` : undefined}
            suffixStyle={{ color: isCharacterCountExceeded ? Colors.light.danger : Colors.light.lightText }}
            suffixVariant='body1'
        />
    )
}
