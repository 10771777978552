import React, { useState } from 'react'
import { Colors, Logger, selectUser, selectUserInfo, updateUserInfo, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { FoxTypography } from '../../components/common/FoxTypography'
import { AccountHeader } from '../../layouts/AccountHeader'
import { FoxTextField } from '../../components/formik/FoxTextField'
import { Form, Formik, FormikHelpers } from 'formik'
import { FoxButton } from '../../components/common/FoxButton'
import { generateToast } from '../../lib/clients/ToastClient'
import { z } from '@foxtail-dev/datacontracts'
import { AreYouSureYouWantToDeleteYourAccountModal } from '../../modals/account/AreYouSureYouWantToDeleteYourAccountModal'
import { set } from 'lodash'
import { FlexGrow } from '../../components/common/FlexGrow'

export const AccountBasicInfoSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    phoneNumber: z.string()
})

export type AccountBasicInfoSchema = z.infer<typeof AccountBasicInfoSchema>

const ProfileScreen: React.FC = () => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)

    const userInfo = useAppSelector(selectUserInfo)
    const [areYouSureYouWantToDeleteYourAccountModal, setAreYouSureYouWantToDeleteYourAccountModalOpen] = useState<boolean>(false)
    const onSubmit = async (values: AccountBasicInfoSchema, actions: FormikHelpers<AccountBasicInfoSchema>) => {
        try {
            await dispatch(
                updateUserInfo({
                    firstName: values.firstName,
                    lastName: values.lastName
                })
            ).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User updated basic info',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        values: values
                    }
                }
            })
            generateToast({ kind: 'success', message: 'Successfully updated basic info' })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Unable to update user info',
                    payload: {
                        kind: 'UpdateUserInfoError',
                        entry: {
                            values: values
                        }
                    }
                },
                error
            )

            generateToast({ kind: 'error', message: 'Unable to update user info', subText: 'Please try again later' })
        }
    }

    const onDeleteAccount = async () => {
        setAreYouSureYouWantToDeleteYourAccountModalOpen(true)
    }

    const closeAreYouSureYouWantToDeleteYourAccountModal = () => {
        setAreYouSureYouWantToDeleteYourAccountModalOpen(false)
    }

    return (
        <Box sx={styles.container}>
            <AccountHeader title='Profile' />
            <Box sx={{ marginBottom: '32px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '32px' }}>
                    <FoxTypography sx={{ marginBottom: '12px' }} variant='subtitle1'>
                        Basic info
                    </FoxTypography>
                </Box>
                <Divider sx={{ marginLeft: '31px' }} />
                <Formik
                    initialValues={{
                        email: userInfo?.email ?? '',
                        firstName: userInfo?.firstName ?? '',
                        lastName: userInfo?.lastName ?? '',
                        phoneNumber: userInfo?.phoneNumber ?? ''
                    }}
                    onSubmit={onSubmit}
                    validateOnBlur={false}>
                    {(formikProps) => {
                        const { isSubmitting, values } = formikProps
                        return (
                            <Form>
                                <Box sx={{ marginLeft: '32px' }}>
                                    <Box
                                        sx={[
                                            styles.containerTextFieldBase,
                                            { marginBottom: '29px', marginTop: '19px', display: 'flex', flexDirection: 'row' }
                                        ]}>
                                        <Box sx={{ flex: 1, marginRight: '28.43px' }}>
                                            <FoxTextField
                                                inputStyle={{ width: '100%' }}
                                                name='firstName'
                                                label='First name'
                                                value={values.firstName}
                                                placeholder='First name'
                                            />
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <FoxTextField
                                                inputStyle={{ width: '100%' }}
                                                name='lastName'
                                                label='Last name'
                                                value={values.lastName}
                                                placeholder='Last name'
                                            />
                                        </Box>
                                    </Box>
                                    <FoxTextField
                                        inputStyle={{ width: '100%' }}
                                        value={values.email}
                                        name='email'
                                        label='Email'
                                        placeholder='Email'
                                        disabled={true}
                                    />
                                    <FoxTextField
                                        inputStyle={{ width: '100%' }}
                                        value={values.phoneNumber}
                                        name='phoneNumber'
                                        label='Phone number'
                                        placeholder='Phone number'
                                        disabled={true}
                                    />
                                </Box>
                                <Box style={styles.containerButton}>
                                    <FoxButton
                                        sx={{ color: Colors.light.lightText, marginLeft: '24px', fontFamily: 'Ubuntu-Regular' }}
                                        onFoxClick={{ kind: 'button', onClick: onDeleteAccount, preventDoubleClick: true }}
                                        text='Delete account'
                                    />
                                    <FlexGrow />
                                    <FoxButton
                                        sx={styles.button}
                                        primary
                                        text='Save changes'
                                        variant='contained'
                                        type='submit'
                                        onFoxClick={{ kind: 'button', onClick: async () => {} }}
                                        loading={isSubmitting}
                                    />
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
                <AreYouSureYouWantToDeleteYourAccountModal
                    open={areYouSureYouWantToDeleteYourAccountModal}
                    onClose={closeAreYouSureYouWantToDeleteYourAccountModal}
                />
            </Box>
        </Box>
    )
}

const styles = {
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginRight: '260px'
    },
    containerButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '44px'
    },
    button: {
        justifyContent: 'center',
        width: '141px',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px'
    },
    containerTextFieldBase: {
        height: '73px',
        display: 'flex'
    }
}

export default ProfileScreen
