import { Colors } from '@foxtail-dev/user-clients'
import { Box, Menu, MenuItem } from '@mui/material'
import { FlexGrow } from '../../common/FlexGrow'
import { FoxButton } from '../../common/FoxButton'
import { FoxTypography } from '../../common/FoxTypography'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { CopyAsNewIcon } from '../../icons/CopyAsNewIcon'
import { DeleteIcon } from '../../icons/DeleteIcon'
import { EditIcon } from '../../icons/EditIcon'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { ListingKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingKind'
import { Refresh } from '@mui/icons-material'
import { useMemo } from 'react'
import { ListingRowAction } from '../BaseListingRow'

type ListingSummaryTitleRowProps = {
    commonDetails: ListingSchema['listingDescription']['listingDetails']['commonDetails']
    listingId: string
    listingKind: ListingKind
    isUntracked: boolean
    anchorEl: null | HTMLElement
    open: boolean
    handleClick: (e: React.MouseEvent<HTMLAnchorElement>) => void
    onEdit: () => void
    onCopyAsNew: () => void
    handleClose: () => void
    onDeleteInitiated: () => void
    onRelistListing: () => void
}

// Listings that are on the summary screen and reach this row are assumed to be either active or completed, not draft
export const ListingSummaryTitleRow = (props: ListingSummaryTitleRowProps) => {
    const {
        commonDetails,
        listingId,
        listingKind,
        isUntracked,
        anchorEl,
        open,
        onEdit,
        onCopyAsNew,
        handleClose,
        handleClick,
        onDeleteInitiated,
        onRelistListing
    } = props

    const actions: ListingRowAction[] = useMemo(() => {
        if (listingKind === 'active') {
            if (isUntracked) {
                return [
                    { label: 'Edit', onAction: onEdit, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Quick relist', onAction: onRelistListing, icon: <Refresh height={24} width={24} /> },
                    { label: 'Delete', onAction: onDeleteInitiated, icon: <DeleteIcon height={24} width={24} /> }
                ]
            } else {
                return [
                    { label: 'Edit', onAction: onEdit, icon: <EditIcon height={24} width={24} /> },
                    { label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> },
                    { label: 'Delete', onAction: onDeleteInitiated, icon: <DeleteIcon height={24} width={24} /> }
                ]
            }
        } else {
            return [{ label: 'Copy as new', onAction: onCopyAsNew, icon: <CopyAsNewIcon height={24} width={24} /> }]
        }
    }, [listingKind, isUntracked])

    return (
        <>
            <Box sx={styles.container}>
                <Box sx={styles.containerTitle}>
                    <FoxTypography variant='h5'>{commonDetails?.title}</FoxTypography>
                </Box>
                <FlexGrow />
                <Box sx={styles.containerButtonActions}>
                    <FoxButton
                        onFoxClick={{
                            kind: 'button',
                            onClick: async (e) => {
                                handleClick(e)
                            }
                        }}
                        text='Actions'
                        sx={styles.buttonActions}
                        primary
                        endIcon={<ChevronDownIcon color={Colors.light.background} />}
                    />
                </Box>
            </Box>

            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                slotProps={{
                    paper: {
                        sx: styles.menu
                    }
                }}>
                {actions.map((action, index) => (
                    <MenuItem key={index} onClick={action.onAction}>
                        <Box sx={styles.containerMenuRow}>
                            <Box sx={styles.iconMenu}>{action.icon}</Box>
                            <FoxTypography sx={styles.textMenu}>{action.label}</FoxTypography>
                        </Box>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

const styles = {
    container: {
        height: '48px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '36px'
    },
    containerMenuRow: {
        display: 'flex',
        flexDirection: 'row',
        height: '36px'
    },
    containerButtonActions: {
        width: '141px'
    },
    containerTitle: {
        marginTop: '2px'
    },
    buttonActions: {
        width: '100%',
        justifyContent: 'center',
        color: 'white'
    },
    menu: {
        width: '204px',
        maxHeight: '400px',
        borderRadius: '10px',
        overflow: 'visible'
    },
    iconMenu: {
        marginLeft: '21px',
        marginTop: '8px',
        marginRight: '22px'
    },
    textMenu: {
        marginTop: '6px'
    }
}
