import { useAppSelector, selectLoggedInDomains } from '@foxtail-dev/user-clients'
import { Box, Skeleton } from '@mui/material'
import { FlexGrow } from '../common/FlexGrow'

export const ImportListingSkeleton = () => {
    const loggedInMarkets = useAppSelector(selectLoggedInDomains)

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '600px', padding: '20px' }}>
            <Skeleton animation='wave' variant='rounded' sx={{ width: '56px', height: '20px', marginTop: '24px' }} />

            {Array.from({ length: loggedInMarkets.length }, (_, index) => {
                return (
                    <Box key={'createListingSkeleton' + index} sx={{ flexDirection: 'row', display: 'flex', marginTop: '26px', alignItems: 'center' }}>
                        <Skeleton animation='wave' variant='rounded' sx={{ width: '22px', height: '22px' }} />
                        <Skeleton animation='wave' variant='rounded' sx={{ width: '257px', height: '26px', marginLeft: '28px' }} />
                    </Box>
                )
            })}

            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '26px', alignItems: 'center' }}>
                <Skeleton animation='wave' variant='rounded' sx={{ width: '22px', height: '22px' }} />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '464px', height: '26px', marginLeft: '28px' }} />
                <FlexGrow />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '10px', height: '17px' }} />
            </Box>
            <FlexGrow />

            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginTop: '16px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginBottom: '18px'
                }}
            >
                <Skeleton animation='wave' variant='rounded' sx={{ width: '141px', height: '48px', marginLeft: '17px', borderRadius: '10px' }} />
            </Box>
        </Box>
    )
}
