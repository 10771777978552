import { Logger } from '@foxtail-dev/user-clients'
import * as Intercom from '@intercom/messenger-js-sdk'

export const handleContactUs = async () => {
    Logger.I().log({
        level: 'info',
        message: 'User clicked on contact us button',
        payload: {
            kind: 'UserAction',
            entry: {
                screen: 'NotificationInfo'
            }
        }
    })
    Intercom.show()
}
