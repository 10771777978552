import { Colors } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { ListingsIcon } from '../icons/ListingsIcon'
import { useNavigate } from 'react-router-dom'

type ListingRememberToPostListingsNotificationProps = {}

export const ListingRememberToPostListingsNotification = (props: ListingRememberToPostListingsNotificationProps): JSX.Element => {
    const navigate = useNavigate()

    const handleCreateListing = async () => {
        navigate('/app/create-listing/new')
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ marginTop: '32px', display: 'flex', alignItems: 'center', height: '80px', width: '80px', justifyContent: 'center' }}>
                    <ListingsIcon color={Colors.light.tertiary} height={80} width={80} />
                </Box>
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                Create a listing!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginLeft: '80px', marginRight: '80px' }}>
                Your Foxtail account is linked and ready to create listings! Click the button below to create your first listing.
            </FoxTypography>
            <FoxButton text='Create listing' onFoxClick={{ kind: 'button', onClick: handleCreateListing }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
