import { Box, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { FoxButton } from '../common/FoxButton'
import { ChevronDownIcon } from '../icons/ChevronDownIcon'
import { FoxCheckbox } from '../common/FoxCheckbox'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'

type ListingsMarketFilterMenuProps = {
    availableMarkets: DomainCommonName[]
    activeMarkets: DomainCommonName[]
    updateActiveMarkets: (activeMarket: DomainCommonName) => void
}

export const ListingsMarketFilterMenu = ({ availableMarkets, activeMarkets, updateActiveMarkets }: ListingsMarketFilterMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
        event.stopPropagation()
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleChecked = (event: React.ChangeEvent<Element>, market: DomainCommonName) => {
        event.stopPropagation()

        updateActiveMarkets(market)
    }

    return (
        <Box>
            <FoxButton
                id='ListingsMarketFilterMenuButton'
                variant='outlined'
                text={'Filter by markets'}
                onFoxClick={{ kind: 'button', onClick: handleClick }}
                endIcon={<ChevronDownIcon />}
            />
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'ListingsMarketFilterMenuButton'
                }}
                slotProps={{
                    paper: {
                        sx: {
                            width: '200px',
                            maxHeight: '400px',
                            borderRadius: '10px',
                            overflow: 'visible'
                        }
                    }
                }}>
                {availableMarkets.map((market) => {
                    const isActive = activeMarkets.includes(market)

                    return (
                        <MenuItem key={market} disableRipple>
                            <Box sx={{ display: 'flex', flexDirection: 'row', height: '36px' }}>
                                <FoxCheckbox
                                    checked={isActive}
                                    iconType={'Checkbox'}
                                    onChange={(event: React.ChangeEvent<Element>) => handleChecked(event, market)}
                                    label={Domains[market].displayName}
                                />
                            </Box>
                        </MenuItem>
                    )
                })}
            </Menu>
        </Box>
    )
}

const styles = {
    disabled: { opacity: 0.7 }
}
