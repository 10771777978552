import { FoxTextField } from './FoxTextField'
import { useMemo, useState } from 'react'
import { BoxProps, TextFieldProps, TypographyVariant } from '@mui/material'

type NumberFieldProps = {
    name: string
    label?: string
    labelVariant?: TypographyVariant
    disabled?: boolean
    logOnBlur?: boolean
    placeholder?: string
    transformText?: (value: string) => string
    transformDisplayedText?: (value: string) => string
    sx?: BoxProps['sx']
    inputStyle?: TextFieldProps['sx']
    initialValue?: string
    suffix?: string
}

export const NumberField = ({
    name,
    label,
    disabled,
    logOnBlur,
    sx,
    inputStyle,
    initialValue,
    transformText,
    transformDisplayedText,
    suffix,
    placeholder = '0',
    labelVariant = 'body1'
}: NumberFieldProps) => {
    const [value, setValue] = useState<string | undefined>(initialValue)

    // Remove any non-numeric characters besides .
    const combinedTransformText = (inputtedValue: string): string => {
        let newValue = inputtedValue.replace(/[^0-9.]/g, '')

        if (transformText) {
            newValue = transformText(newValue)
        }

        setValue(newValue)
        return newValue
    }

    const displayedValue = useMemo(() => {
        if (transformDisplayedText) {
            return transformDisplayedText(value || '')
        }

        return value
    }, [transformDisplayedText, value])

    return (
        <FoxTextField
            name={name}
            label={label}
            labelVariant={labelVariant}
            placeholder={placeholder}
            inputStyle={{ ...inputStyle }}
            value={displayedValue}
            transformText={combinedTransformText}
            disabled={disabled}
            logOnBlur={logOnBlur}
            suffix={suffix}
            sx={{ ...sx }}
        />
    )
}
