import { Colors, selectActiveListingCount, selectListingsLimit, useAppSelector } from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { ProfileIcon } from '../icons/ProfileIcon'

type SubscriptionQuotaNearlyExceededNotificationProps = {}

export const SubscriptionQuotaNearlyExceededNotification = (props: SubscriptionQuotaNearlyExceededNotificationProps): JSX.Element => {
    const {} = props
    const currentActiveListingCount = useAppSelector(selectActiveListingCount)
    const listingsLimit = useAppSelector(selectListingsLimit)

    const navigate = useNavigate()

    const handleSubscription = async () => {
        navigate('/app/account/subscription')
    }

    return (
        <>
            <Box sx={{ marginTop: '32px', display: 'flex', alignItems: 'center', height: '80px', width: '80px', justifyContent: 'center' }}>
                <ProfileIcon color={Colors.light.tertiary} height={80} width={80} />
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                You've almost reached your listing quota!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginLeft: '80px', marginRight: '80px' }}>
                You're managing {currentActiveListingCount} of {listingsLimit} listings. Upgrade your subscription to manage more listings.
            </FoxTypography>
            <FoxButton text='Manage subscription' onFoxClick={{ kind: 'button', onClick: handleSubscription }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
