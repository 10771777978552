import { Box } from '@mui/material'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { handleContactUs } from '../../utils/handleContactUs'

type ListingFailedToCreateNotificationProps = {
    domainName: DomainCommonName
    listing?: ListingSchema
    imageId?: string
}

export const ListingFailedToCreateNotification = (props: ListingFailedToCreateNotificationProps): JSX.Element => {
    const { domainName, imageId, listing } = props

    if (!listing || !imageId) {
        return <></>
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FoxImage src={CommonImages.markets[domainName]} style={{ ...styles.image, ...{ marginRight: '40px' } }} />
                <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                Unable to publish {listing?.listingDescription.listingDetails.commonDetails?.title}
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                Our development team has been notified of this and will check into it as soon as possible.
            </FoxTypography>
            <FoxButton grey text='Contact us' onFoxClick={{ kind: 'button', onClick: handleContactUs }} sx={{ ...styles.button }} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
