import React, { useEffect } from 'react'
import Box from '@mui/material/Box/Box'
import { FoxButton } from '../../components/common/FoxButton'
import { OnboardingHeader } from '../../layouts/OnboardingHeader'
import { Form, Formik, FormikHelpers } from 'formik'
import { beginVerification, Logger, selectUserVerificationStatus, submitVerification, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { useLocation, useNavigate } from 'react-router-dom'
import { assert, z } from '@foxtail-dev/datacontracts'
import { FoxTextField } from '../../components/formik/FoxTextField'
import { OnboardingSignOutButton } from '../../components/onboarding/OnboardingSignOutButton'
import { FoxButtonBase } from '../../components/common/FoxButtonBase'
import { FoxTypography } from '../../components/common/FoxTypography'
import { useMediaQuery, useTheme } from '@mui/material'
import { generateToast } from '../../lib/clients/ToastClient'

export const VerifyPhoneNumberSchema = z.object({
    code: z.string() // @todo double check to enforce 6 digits
})

export type VerifyPhoneNumberSchema = z.infer<typeof VerifyPhoneNumberSchema>

export const initialValues: VerifyPhoneNumberSchema = {
    code: ''
}

export const VerifyPhoneNumberScreen = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const location = useLocation()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [hasResentCode, setHasResentCode] = React.useState(false)
    const [shouldGoToBasicInfo, setShouldGoToBasicInfo] = React.useState(false)
    const userVerificationStatus = useAppSelector(selectUserVerificationStatus)

    let phoneNumber: string | undefined
    if (location.state.phoneNumber) {
        phoneNumber = location.state.phoneNumber
    } else if (userVerificationStatus?.kind === 'verificationTextSent') {
        phoneNumber = userVerificationStatus?.phoneNumber
    }

    const onSubmit = async (values: VerifyPhoneNumberSchema, actions: FormikHelpers<VerifyPhoneNumberSchema>) => {
        try {
            const response = await dispatch(submitVerification(values.code)).unwrap()

            if (response.wasSuccessfullyExecuted && response.user && response.user.verificationStatus.kind === 'verified') {
                Logger.I().log({
                    level: 'info',
                    message: 'User verified phone number',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            values: values
                        }
                    }
                })
                setShouldGoToBasicInfo(true)
            } else {
                actions.setErrors({ code: 'Invalid verification code' })
            }
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'submitVerification threw in the VerifyPhoneNumberScreen',
                    payload: {
                        kind: 'VerifyPhoneNumberError',
                        entry: {
                            values
                        }
                    }
                },
                error
            )
            actions.setFieldError('code', 'Error submitting code')
        }
    }

    const onResendCode = async () => {
        try {
            assert(phoneNumber, 'Phone number not found')
            const verificationStarted = await dispatch(beginVerification(phoneNumber)).unwrap()

            Logger.I().log({
                level: 'info',
                message: 'User submitted a phone number to begin verification',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        phoneNumber,
                        verificationStarted
                    }
                }
            })

            if (!verificationStarted) {
                generateToast({ kind: 'error', message: 'Verification failed, please try again' })
                return
            }

            setHasResentCode(true)
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'threw error while trying to submit phone number',
                    payload: {
                        kind: 'EnterPhoneNumberScreen',
                        entry: {
                            phoneNumber
                        }
                    }
                },
                error
            )
        }
    }

    useEffect(() => {
        if (!phoneNumber) {
            generateToast({ kind: 'error', message: 'Phone number not found' })
        }
    }, [phoneNumber])

    useEffect(() => {
        if (shouldGoToBasicInfo) {
            navigate('/basic-info')
        }
    }, [shouldGoToBasicInfo])

    return (
        <Box sx={isMobile ? mobileStyles.container : styles.container}>
            <OnboardingHeader title='One time code' subtitle={`We sent a code to ${phoneNumber}`} />
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => {
                    const { isSubmitting, values } = formikProps
                    return (
                        <Form>
                            <Box sx={isMobile ? mobileStyles.containerForm : styles.containerForm}>
                                <FoxTextField
                                    inputStyle={isMobile ? mobileStyles.textField : styles.textField}
                                    name='code'
                                    value={values.code}
                                    label='Verification code'
                                    placeholder='Enter code'
                                />
                                <Box sx={{ flexGrow: 1 }} />
                                <FoxButton
                                    sx={isMobile ? mobileStyles.buttonNext : styles.buttonNext}
                                    primary
                                    text='Next'
                                    variant='contained'
                                    onFoxClick={{ kind: 'button', onClick: async () => {} }}
                                    type='submit'
                                    loading={isSubmitting}
                                />
                                <FoxButton
                                    sx={isMobile ? mobileStyles.buttonGoBack : styles.buttonGoBack}
                                    grey
                                    text='Enter a different phone number'
                                    variant='contained'
                                    onFoxClick={{ kind: 'internal', to: '/enter-phone-number' }}
                                />
                                <FoxButtonBase
                                    onFoxClick={{ kind: 'button', onClick: onResendCode, preventDoubleClick: true }}
                                    sx={{ marginTop: '8px', marginBottom: '48px' }}>
                                    <FoxTypography variant='body1' light>
                                        {hasResentCode ? 'Code resent! Resend code again' : 'Resend code'}
                                    </FoxTypography>
                                </FoxButtonBase>

                                <OnboardingSignOutButton />
                            </Box>
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}

const styles = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        radius: '10px',
        width: '471px'
    },
    containerForm: {
        width: '474px',
        display: 'flex',
        flexDirection: 'column'
    },
    buttonNext: {
        justifyContent: 'center',
        width: '474px',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px',
        marginBottom: '8px'
    },
    buttonGoBack: {
        justifyContent: 'center',
        width: '474px',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px'
    }
}

const mobileStyles = {
    container: {
        justifyContent: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '13px',
        marginRight: '14px'
    },
    textField: {
        radius: '10px',
        width: '100%'
    },
    containerForm: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    buttonNext: {
        justifyContent: 'center',
        width: '100%',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px',
        marginTop: '32px',
        marginBottom: '8px'
    },
    buttonGoBack: {
        justifyContent: 'center',
        width: '100%',
        radius: '10px',
        fontWeight: 700,
        fontSize: '16px'
    }
}
