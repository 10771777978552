import { getDayName } from './getDayName'

export type FormatXAxisLabelFromTimeRangeParams = {
    timeRange: string
    value: Date
}
export const formatXAxisLabelFromTimeRange = (params: FormatXAxisLabelFromTimeRangeParams): string => {
    const { timeRange, value } = params
    if (timeRange === 'week') {
        return getDayName(value).toString().slice(0, 3)
    } else if (timeRange === 'month') {
        const dayOfTheMonth = value.getDate()
        const month = value.getMonth()
        return `${month}/${dayOfTheMonth}`
    } else if (timeRange === 'year') {
        const monthName = value.toLocaleString('default', { month: 'short' })
        return monthName
    } else {
        throw new Error('Invalid time range')
    }
}
