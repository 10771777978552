import { Logger } from '@foxtail-dev/user-clients'
import { TextField, TextFieldProps, TypographyProps, TypographyVariant, useTheme } from '@mui/material'
import { forwardRef } from 'react'
import { FormikField } from './FormikField'
import { FoxTypography } from '../common/FoxTypography'
import { sharedStyles } from '../../theme/SharedStyling'

export type FoxTextFieldProps = {
    name: string
    label?: string
    value?: string
    labelVariant?: TypographyVariant
    disabled?: boolean
    textInputProps?: TextFieldProps
    transformText?: (value: string) => string
    inputStyle?: TextFieldProps['sx']
    actionLabel?: string
    onAction?: () => Promise<any>
    onBlur?: (value: string) => string
    prefix?: string
    suffix?: string
    placeholder?: string
    titleSubtext?: string
    logOnBlur?: boolean
    additionalOnChange?: (value: string) => void
    sx?: TextFieldProps['sx']
    prefixStyle?: TypographyProps['sx']
    prefixVariant?: TypographyVariant
    suffixStyle?: TypographyProps['sx']
    suffixVariant?: TypographyVariant
}

// TODO: Investigate what happens if we get rid of the default value for value = ''. It's forcing us to pass in the values from formik ourselves
export const FoxTextField = forwardRef<HTMLInputElement, FoxTextFieldProps>(
    (
        {
            label,
            disabled,
            name,
            transformText,
            textInputProps,
            inputStyle,
            value = '',
            onBlur,
            labelVariant = 'body2',
            prefix,
            suffix,
            logOnBlur,
            additionalOnChange,
            placeholder,
            prefixStyle,
            prefixVariant = 'body2',
            suffixStyle,
            suffixVariant = 'body2',
            ...rest
        }: FoxTextFieldProps,
        ref
    ) => {
        const theme = useTheme()

        return (
            <FormikField
                name={name}
                label={label}
                labelVariant={labelVariant}
                render={({ touched, error, value: formikValue, onChange, onBlur: onFormikBlur }) => {
                    // Passed in value takes precedence over formik value
                    let finalValue: string
                    if (value !== null && value !== undefined) {
                        finalValue = value
                    } else {
                        finalValue = formikValue
                    }

                    return (
                        <TextField
                            ref={ref}
                            size={'small'}
                            sx={{
                                ...styles.textInput,
                                ...inputStyle,
                                ...sharedStyles.textField
                            }}
                            variant='outlined'
                            error={touched && !!error}
                            onChange={(e) => {
                                const newText = e.target.value

                                if (additionalOnChange) {
                                    additionalOnChange(newText)
                                }
                                const newValue = transformText ? transformText(newText) : newText
                                onChange(newValue)
                            }}
                            onBlur={(e) => {
                                let newValue = finalValue
                                if (onBlur && finalValue) {
                                    const previousValue = finalValue
                                    newValue = onBlur(previousValue)
                                    if (newValue !== previousValue) {
                                        onChange(newValue)
                                    }
                                }
                                if (logOnBlur) {
                                    Logger.I().log({
                                        level: 'info',
                                        message: 'User input value into field',
                                        payload: {
                                            kind: 'UserAction',
                                            entry: {
                                                fieldName: name,
                                                value: newValue
                                            }
                                        }
                                    })
                                }
                                onFormikBlur(e)
                            }}
                            value={finalValue}
                            disabled={disabled}
                            autoCorrect={'on'} // TODO:
                            InputProps={{
                                startAdornment: prefix && (
                                    <FoxTypography variant={prefixVariant} sx={{ ...styles.prefix, ...prefixStyle }}>
                                        {prefix}
                                    </FoxTypography>
                                ),
                                endAdornment: suffix && (
                                    <FoxTypography variant={suffixVariant} sx={{ ...styles.prefix, ...suffixStyle }}>
                                        {suffix}
                                    </FoxTypography>
                                ),
                                placeholder
                            }}
                            sentry-label={`TextInput-${name}`}
                            {...textInputProps}
                        />
                    )
                }}
                {...rest}
            />
        )
    }
)

const styles = {
    textInput: {
        height: '36px'
    },
    prefix: {
        height: '36px',
        lineHeight: '36px'
    }
}
