import { useAppSelector, selectListingById, normalizePrice } from '@foxtail-dev/user-clients'
import { BaseListingRow } from '../../../components/listing/BaseListingRow'
import { useFormikContext } from 'formik'
import { EngagementBoostersData } from '../../../screens/engagementBoosters/EngagementBoostersScreen'
import { useCallback, useMemo } from 'react'

type PoshmarkMakeOfferToLikersListingRowProps = {
    listingId: string
}

export const PoshmarkMakeOfferToLikersListingRow = ({ listingId }: PoshmarkMakeOfferToLikersListingRowProps) => {
    const listing = useAppSelector(selectListingById(listingId))
    const listingDetails = listing?.listingDescription.listingDetails
    const imageIds = listingDetails?.imageIds ?? []

    const title = listingDetails?.commonDetails?.title ?? 'Title'
    const price = listingDetails?.commonDetails?.price ?? '0.00'

    const { setFieldValue, setFieldTouched, values } = useFormikContext<EngagementBoostersData>()

    const offerDirective = values.poshmarkMakeOfferToLikers?.offerDirective

    const existingListingIds = (offerDirective?.kind === 'makeOfferForSubsetOfListings' ? offerDirective.listingIds : []) ?? []

    const isChecked = useMemo(() => existingListingIds.includes(listingId), [existingListingIds, listingId])

    const handleChecked = useCallback(
        (checked: boolean) => {
            const field = 'poshmarkMakeOfferToLikers.offerDirective.listingIds'

            let newListingIds: string[] = []
            if (checked) {
                newListingIds = [...existingListingIds, listingId]
            } else {
                newListingIds = existingListingIds.filter((id) => id !== listingId)
            }

            setFieldValue(field, newListingIds)
            setFieldTouched(field)
        },
        [setFieldValue, setFieldTouched, listingId, existingListingIds]
    )

    const handleToggleChecked = useCallback(() => {
        handleChecked(!isChecked)
    }, [isChecked, handleChecked])

    return (
        <BaseListingRow
            imageId={imageIds[0]}
            title={title}
            description={`$${normalizePrice(price)}`}
            onClick={handleToggleChecked}
            isChecked={isChecked}
            onChecked={handleToggleChecked}
        />
    )
}
