import { FormikField } from './FormikField'
import { FoxCheckbox, FoxCheckboxType } from '../common/FoxCheckbox'
import { Logger } from '@foxtail-dev/user-clients'
import Box from '@mui/material/Box'
import { sharedStyles } from '../../theme/SharedStyling'
import { Divider } from '@mui/material'

export type CheckboxOption = string | [string, string]

export type CheckboxGroupFieldProps = {
    name: string
    label?: string
    options: CheckboxOption[]
    iconType: FoxCheckboxType
    logOnChange?: boolean
    ignoreError?: boolean
}

const getLabel = (option: CheckboxOption): string => (typeof option === 'string' ? option : option[1])
const getValue = (option: CheckboxOption): string => (typeof option === 'string' ? option : option[0])
const getChecked = (option: CheckboxOption, value: string[]) => value.includes(getValue(option))
const getNewValue = (currentValue: string[], value: string) =>
    currentValue.includes(value) ? currentValue.filter((v) => v !== value) : [...currentValue, value]

export const CheckboxGroupField = ({ name, label, iconType, options, logOnChange, ignoreError }: CheckboxGroupFieldProps) => {
    return (
        <FormikField
            name={name}
            label={label}
            ignoreError={ignoreError}
            render={({ value = [], onChange }) => (
                <Box sx={{ ...sharedStyles.flexColumn }}>
                    {options.map((option) => (
                        <Box key={getValue(option)} sx={{ height: '48px' }}>
                            <FoxCheckbox
                                key={getValue(option)}
                                iconType={iconType}
                                label={getLabel(option)}
                                checked={getChecked(option, value)}
                                onChange={() => {
                                    const newValue = getNewValue(value, getValue(option))
                                    onChange(newValue)

                                    if (logOnChange) {
                                        Logger.I().log({
                                            level: 'info',
                                            message: 'User selected a checkbox option',
                                            payload: {
                                                kind: 'UserAction',
                                                entry: {
                                                    name,
                                                    value,
                                                    newValue
                                                }
                                            }
                                        })
                                    }
                                }}
                            />
                            <Divider sx={{ marginLeft: '40px' }} />
                        </Box>
                    ))}
                </Box>
            )}
        />
    )
}
