import { DomainCommonName, assert } from '@foxtail-dev/datacontracts'
import { Aspect } from '@foxtail-dev/datacontracts/dist/lib/schemas/domainSpecific/ebay/lib/Aspect'
import { AiListingConfigDetails } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ai/aiListings.exports'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { EbayAspectsDatatype, EtsyConditionalAttribute } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import { TraditionalConditionIds } from '@foxtail-dev/datacontracts/dist/lib/schemas/domainSpecific/ebay/enums/TraditionalConditionIds'
import { EbayAspectMetadata, EbayAspectSelection, MarketSpecificFieldUpdate } from '@foxtail-dev/user-clients'
import { FormikHelpers } from 'formik'

// TODO: Figure out where this should be
// These are essentially copies of the redux actions in listingDetails that make changes directly to formik instead of making certain changes to the redux state
// Partially generated by chatGPT, so need to verify that they all work correctly
export const updateMarketSpecificFieldInFormik = <TMarket extends DomainCommonName>(
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: MarketSpecificFieldUpdate<TMarket>
) => {
    const { paramName, domainName, isRequired, value } = action

    const requiredOptional = isRequired ? 'requiredDetails' : 'optionalDetails'
    const fieldName = `domainSpecificDetails.${domainName}.${requiredOptional}.${paramName}`

    setFieldValue(fieldName, value)
    setFieldTouched(fieldName, true)
}

export const updateListingDefaultFieldInFormik = <TMarket extends DomainCommonName>(
    setFieldValue: FormikHelpers<AiListingConfigDetails>['setFieldValue'],
    setFieldTouched: FormikHelpers<AiListingConfigDetails>['setFieldTouched'],
    action: MarketSpecificFieldUpdate<TMarket>
) => {
    const { paramName, domainName, isRequired, value } = action

    const requiredOptional = isRequired ? 'requiredDetails' : 'optionalDetails'
    const fieldName = `domainDetails.${domainName}.suggestionDefaults.${requiredOptional}.${paramName}`

    setFieldValue(fieldName, value)
    setFieldTouched(fieldName, true)
}

export const updateEbayConditionIdInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: string
) => {
    setFieldValue('domainSpecificDetails.ebay.requiredDetails.conditionId', TraditionalConditionIds.parse(Number.parseInt(action)))
    setFieldTouched('domainSpecificDetails.ebay.requiredDetails.conditionId', true)
}

export const updateEbayFulfillmentPolicyInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: string
) => {
    setFieldValue('domainSpecificDetails.ebay.optionalDetails.fundamentals.policies.fulfillmentPolicyId', action)
    setFieldTouched('domainSpecificDetails.ebay.optionalDetails.fundamentals.policies.fulfillmentPolicyId', true)
}

export const updateEbayAspectInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: EbayAspectSelection
) => {
    const { paramName, value } = action
    const fieldName = `domainSpecificDetails.ebay.requiredDetails.aspects.${paramName}`

    setFieldValue(fieldName, value)
    setFieldTouched(fieldName, true)
}

export const updateEtsyConditionalAttributeInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    attribute: EtsyConditionalAttribute,
    existingAttributes: EtsyConditionalAttribute[]
) => {
    const fieldName = 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs'
    const newAttributes = [...existingAttributes]

    const attributeIndex = newAttributes.findIndex((attr) => attr.propertyId === attribute.propertyId)
    if (attributeIndex === -1) {
        newAttributes.push(attribute)
    } else {
        newAttributes[attributeIndex] = attribute
    }

    setFieldValue(fieldName, newAttributes)
    setFieldTouched(fieldName, true)
}

export const clearEtsyConditionalAttributesInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched']
) => {
    setFieldValue('domainSpecificDetails.etsy.optionalDetails.conditionalInputs', [])
    setFieldTouched('domainSpecificDetails.etsy.optionalDetails.conditionalInputs', true)
}

export const setEtsyConditionalAttributesWithOnlyOneOptionInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: EtsyConditionalAttribute[]
) => {
    const fieldName = 'domainSpecificDetails.etsy.optionalDetails.conditionalInputs'

    setFieldValue(fieldName, action)
    setFieldTouched(fieldName, true)
}

export const clearMercariSizeInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched']
) => {
    setFieldValue('domainSpecificDetails.mercari.requiredDetails.size', undefined)
    setFieldTouched('domainSpecificDetails.mercari.requiredDetails.size', true)
}

export const clearDepopSizeInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched']
) => {
    setFieldValue('domainSpecificDetails.depop.requiredDetails.size', null)
    setFieldTouched('domainSpecificDetails.depop.requiredDetails.size', true)
}

export const clearPoshmarkSizeCategoryNameInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched']
) => {
    setFieldValue('domainSpecificDetails.poshmark.requiredDetails.sizeCategoryName', undefined)
    setFieldTouched('domainSpecificDetails.poshmark.requiredDetails.sizeCategoryName', true)
}

export const clearPoshmarkSizeInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched']
) => {
    setFieldValue('domainSpecificDetails.poshmark.requiredDetails.size', undefined)
    setFieldTouched('domainSpecificDetails.poshmark.requiredDetails.size', true)
}

export const setEbayCategoryAspectsInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: Aspect[],
    setCurrentEbayAspects: (aspects: Record<string, EbayAspectMetadata>) => void
) => {
    const fieldName = 'domainSpecificDetails.ebay.requiredDetails.aspects'

    setFieldValue(fieldName, {})
    setFieldTouched(fieldName, true)

    const currentEbayAspects: Record<string, EbayAspectMetadata> = {}

    action.forEach((aspect) => {
        // Strip out aspects with no values, and set aspects with only one value
        if (aspect.aspectConstraint.aspectMode === 'SELECTION_ONLY') {
            if (!aspect.aspectValues || aspect.aspectValues.length === 0) {
                return
            }

            const aspectValues = aspect.aspectValues.map((value) => value.localizedValue)

            currentEbayAspects[aspect.localizedAspectName] = {
                name: aspect.localizedAspectName,
                values: aspectValues,
                constraint: aspect.aspectConstraint
            }

            // If there's only one option, automatically set it, otherwise leave it blank so the user can decide
            const value = aspectValues.length > 1 ? '' : aspectValues[0]

            setFieldValue(`${fieldName}.${aspect.localizedAspectName}`, value)
            setFieldTouched(`${fieldName}.${aspect.localizedAspectName}`, true)
        } else {
            currentEbayAspects[aspect.localizedAspectName] = {
                name: aspect.localizedAspectName,
                values: [],
                constraint: aspect.aspectConstraint
            }

            setFieldValue(`${fieldName}.${aspect.localizedAspectName}`, '')
            setFieldTouched(`${fieldName}.${aspect.localizedAspectName}`, true)
        }
    })

    // TODO: Need to set the current aspects in the redux state
    setCurrentEbayAspects(currentEbayAspects)
}

export const setCurrentListingEbayCategoryAspectsInFormik = (
    setFieldValue: FormikHelpers<ListingDetailsDraft>['setFieldValue'],
    setFieldTouched: FormikHelpers<ListingDetailsDraft>['setFieldTouched'],
    action: { name: string; value: EbayAspectsDatatype }[]
) => {
    const fieldName = 'domainSpecificDetails.ebay.requiredDetails.aspects'

    setFieldValue(fieldName, {})
    setFieldTouched(fieldName, true)

    action.forEach((aspect) => {
        setFieldValue(`${fieldName}.${aspect.name}`, aspect.value)
        setFieldTouched(`${fieldName}.${aspect.name}`, true)
    })
}
