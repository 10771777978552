import { Logger } from './Logger'

const getReferralId = (): Promise<string | undefined> => {
    return new Promise((resolve) => {
        try {
            // Rewardful suggests that we use
            // window.rewardful('ready', () => {})
            // But this doesn't work in case of an adblocker - the ready block never fires.
            // Instead, we just try it and if it hasn't loaded then oh well.
            const referralId = window.Rewardful?.referral

            // Stripe doesn't like it when we pass an empty referral ID, so we pass undefined instead.
            if (referralId === '') {
                resolve(undefined)
            }
            resolve(window.Rewardful?.referral)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    payload: {
                        kind: 'RewardfulError'
                    },
                    message: 'Could not get referral ID'
                },
                error
            )
            resolve(undefined)
        }
    })
}

export default {
    getReferralId
}
