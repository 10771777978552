import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box/Box'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxButton } from '../components/common/FoxButton'
import { Link, useTheme, useMediaQuery } from '@mui/material'
import { OnboardingHeader } from '../layouts/OnboardingHeader'
import { useLocation } from 'react-router-dom'
import { generateToast } from '../lib/clients/ToastClient'
import { Logger } from '@foxtail-dev/user-clients'

type Auth0ScreenHint = 'login' | 'signup'

export const SignInScreen = (): JSX.Element => {
    const { loginWithRedirect } = useAuth0()
    const theme = useTheme()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const source = queryParams.get('source')

    const isMobile = useMediaQuery('(max-width:600px)')

    const onLogin = async (screenHint: Auth0ScreenHint) => {
        try {
            await loginWithRedirect({
                authorizationParams: {
                    screen_hint: screenHint
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error while loginWithRedirect',
                    payload: {
                        kind: 'onLoginError'
                    }
                },
                error
            )
            generateToast({
                kind: 'error',
                message: 'Unable to login at this time'
            })
        }
    }

    useEffect(() => {
        if (source === 'logout') {
            generateToast({
                kind: 'info',
                message: 'You have been logged out'
            })
        } else if (source === 'accountDeletion') {
            generateToast({
                kind: 'info',
                message: 'Account deleted ',
                subText: 'You may create a new account using the same email address in approximately 5 minutes'
            })
        }
    }, [source])

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', flex: 1, display: 'flex', flexDirection: 'column', padding: isMobile ? '16px' : '0' }}>
            <OnboardingHeader title='Welcome back' subtitle='Sign into your account' />
            <Box sx={{ display: 'flex', flexDirection: 'column', width: isMobile ? '100%' : 'auto', alignItems: 'center' }}>
                <FoxButton
                    sx={{
                        justifyContent: 'center',
                        marginBottom: '33px',
                        width: isMobile ? '100%' : '474px',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}
                    primary
                    text='Log in'
                    variant='contained'
                    onFoxClick={{
                        kind: 'button',
                        onClick: async () => {
                            await onLogin('login')
                        },
                        preventDoubleClick: true
                    }}
                />
            </Box>
            <FoxTypography>
                Need an account?{' '}
                <Link sx={{ cursor: 'pointer' }} fontStyle={{ color: theme.palette.text.primary }} onClick={() => onLogin('signup')}>
                    Sign up
                </Link>
            </FoxTypography>
        </Box>
    )
}
