import React, { useMemo, useState } from 'react'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { EbayListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/listings.exports'
import {
    sanitizeZodObject,
    useAppSelector,
    selectCurrentEbayAspects,
    currentEbayCategoryAspectNamesSelector,
    selectCurrentEbayConditions,
    selectCurrentEbayFulfillmentPolicies,
    RadioOption,
    EbayDimensionUnitsRadioOptions,
    EbayWeightUnitsRadioOptions,
    makeCustomFoxtailPolicyPretty,
    ebayWeightUnitLabelMap
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { useFormikContext, getIn } from 'formik'
import { DetailSelectionModal } from '../../../../modals/listingDetails/DetailSelectionModal'
import { FoxTypography } from '../../../../components/common/FoxTypography'
import { sharedStyles } from '../../../../theme/SharedStyling'
import { DetailSelectionField } from '../../../../components/formik/DetailSelectionField'
import { FoxTextField } from '../../../../components/formik/FoxTextField'
import { RadioGroupField } from '../../../../components/formik/RadioGroupField'
import { MarketSpecificFieldsProps } from './MarketSpecificFieldsMap'
import { TraditionalConditionIds } from '@foxtail-dev/datacontracts/dist/lib/schemas/domainSpecific/ebay/enums/TraditionalConditionIds'
import { LongCategoryPath } from '../../listingDetails/LongCategoryPath'
import { DimensionInputField } from '../../../../components/formik/DimensionInputField'
import { EbayCategoryScreen } from '../../../../modals/domainSpecific/EbayCategoryModal'
import { DomainSpecificDetailModal } from '../DomainSpecificDetailModal'
import { EbayFulfillmentPolicyModal } from '../../../../modals/domainSpecific/EbayFulfillmentPolicyModal'

export const EbayFields = (props: MarketSpecificFieldsProps) => {
    const { values, listingOperationKind } = props

    const ebayDetails = useMemo(() => {
        return sanitizeZodObject(values, EbayListingDetailsDraft)
    }, [values])

    const requiredDetails = ebayDetails.requiredDetails

    const { errors, touched } = useFormikContext<ListingDetailsDraft>()
    const [modalOpen, setModalOpen] = useState<string>('none')

    const onCloseModal = () => {
        setModalOpen('none')
    }

    const currentCategoryAspects = useAppSelector(selectCurrentEbayAspects)
    const currentCategoryAspectNames = useAppSelector(currentEbayCategoryAspectNamesSelector)
    const currentEbayConditions = useAppSelector(selectCurrentEbayConditions)
    const currentEbayPolicies = useAppSelector(selectCurrentEbayFulfillmentPolicies)

    return (
        <>
            <DetailSelectionField
                name='domainSpecificDetails.ebay.requiredDetails.category'
                label='Category'
                renderValue={(value) => (value ? <LongCategoryPath path={value.name ? value.name.split(', ') : ''} key={value} /> : null)}
                placeholder='Select Category'
                onDetail={() => setModalOpen('EbayCategory')}
            />
            <DomainSpecificDetailModal open={'EbayCategory' === modalOpen} title='Select Category' closeModal={onCloseModal}>
                <EbayCategoryScreen closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            {currentEbayConditions.length > 0 && (
                <>
                    <DetailSelectionField
                        name='domainSpecificDetails.ebay.requiredDetails.conditionId'
                        label='Condition'
                        placeholder='Select condition'
                        options={currentEbayConditions}
                        onDetail={() => setModalOpen('EbayCondition')}
                        renderValue={(value: TraditionalConditionIds) => {
                            if (value) {
                                const currentSelection = currentEbayConditions.find((c) => c[0] === value.toString())
                                if (currentSelection) {
                                    return <FoxTypography variant='body1'>{currentSelection[1]}</FoxTypography>
                                }
                            }
                            return (
                                <FoxTypography light variant='body1'>
                                    Select condition
                                </FoxTypography>
                            )
                        }}
                    />
                    <DetailSelectionModal
                        open={modalOpen === 'EbayCondition'}
                        headerTitle='Condition'
                        options={currentEbayConditions}
                        isRequired
                        paramName='conditionId'
                        listingOperationKind={listingOperationKind}
                        closeModal={onCloseModal}
                        currentValue={requiredDetails.conditionId ? requiredDetails.conditionId.toString() : 'Select condition'}
                        domainName={DomainCommonName.enum.ebay}
                    />
                </>
            )}

            {currentCategoryAspectNames.length > 0 &&
                currentCategoryAspectNames.map((aspectName) => {
                    const currentAspect = currentCategoryAspects[aspectName]
                    if (!currentAspect) {
                        return null
                    }

                    const selectionOnly = currentAspect.constraint.aspectMode === 'SELECTION_ONLY'

                    if (selectionOnly && currentAspect.values.length <= 1) {
                        return null
                    }

                    const options = currentAspect.values.map((v) => RadioOption.parse([v, v]))
                    const currentValue = requiredDetails.aspects?.[aspectName]
                    const strValue = currentValue ? currentValue.toString() : undefined

                    return (
                        <Box key={aspectName}>
                            {selectionOnly ? (
                                <>
                                    <DetailSelectionField
                                        key={aspectName}
                                        name={`domainSpecificDetails.ebay.requiredDetails.aspects.${aspectName}`}
                                        label={aspectName}
                                        placeholder={`Select ${aspectName}`}
                                        options={options}
                                        onDetail={() => setModalOpen('aspect-' + aspectName)}
                                    />

                                    <DetailSelectionModal
                                        open={modalOpen === 'aspect-' + aspectName}
                                        headerTitle={aspectName}
                                        options={options}
                                        paramName={aspectName}
                                        domainName={'ebay'}
                                        isRequired={true}
                                        currentValue={strValue}
                                        closeModal={onCloseModal}
                                        listingOperationKind={listingOperationKind}
                                        isEbayAspect
                                    />
                                </>
                            ) : (
                                <FoxTextField
                                    key={aspectName}
                                    name={`domainSpecificDetails.ebay.requiredDetails.aspects.${aspectName}`}
                                    label={aspectName}
                                    placeholder={`Enter ${aspectName}`}
                                    logOnBlur
                                    value={strValue ?? ''}
                                />
                            )}

                            {getIn(errors, aspectName) && getIn(touched, aspectName) && (
                                <Box sx={styles.containerValidation}>
                                    <FoxTypography variant='body2' danger sx={styles.textError}>
                                        {getIn(errors, aspectName)}
                                    </FoxTypography>
                                </Box>
                            )}
                        </Box>
                    )
                })}

            <DetailSelectionField
                name={`domainSpecificDetails.ebay.optionalDetails.fundamentals.policies.fulfillmentPolicyId`}
                label='Shipping Policy'
                placeholder={`Select shipping policy`}
                options={[]}
                titleSubtext='(optional)'
                onDetail={() => setModalOpen('EbayFulfillmentPolicy')}
                renderValue={(value: string) => {
                    if (value) {
                        const currentSelection = currentEbayPolicies.find((policy) => policy.id === value.toString())
                        if (currentSelection) {
                            return (
                                <FoxTypography variant='body1'>
                                    {currentSelection.name ? makeCustomFoxtailPolicyPretty(currentSelection.name) : currentSelection.id}
                                </FoxTypography>
                            )
                        }
                    }
                    return (
                        <FoxTypography light variant='body1'>
                            Select shipping policy
                        </FoxTypography>
                    )
                }}
            />
            <DomainSpecificDetailModal open={modalOpen === 'EbayFulfillmentPolicy'} title={'Shipping Policy'} closeModal={onCloseModal}>
                <EbayFulfillmentPolicyModal closeModal={onCloseModal} />
            </DomainSpecificDetailModal>

            <FoxTypography variant='body2' bold>
                Product Dimensions
            </FoxTypography>
            <Box sx={{ ...sharedStyles.flex, ...{ justifyContent: 'space-between' } }}>
                <Box sx={{ width: '30%' }}>
                    <DimensionInputField
                        name='domainSpecificDetails.ebay.optionalDetails.length'
                        label='Length'
                        logOnBlur
                        suffix={ebayDetails.optionalDetails.dimensionUnit === 'INCH' ? 'in' : 'ft'}
                        initialValue={ebayDetails.optionalDetails.length}
                    />
                </Box>
                <Box sx={{ width: '30%' }}>
                    <DimensionInputField
                        name='domainSpecificDetails.ebay.optionalDetails.width'
                        label='Width'
                        logOnBlur
                        suffix={ebayDetails.optionalDetails.dimensionUnit === 'INCH' ? 'in' : 'ft'}
                        initialValue={ebayDetails.optionalDetails.width}
                    />
                </Box>
                <Box sx={{ width: '30%' }}>
                    <DimensionInputField
                        name='domainSpecificDetails.ebay.optionalDetails.height'
                        label='Height'
                        logOnBlur
                        suffix={ebayDetails.optionalDetails.dimensionUnit === 'INCH' ? 'in' : 'ft'}
                        initialValue={ebayDetails.optionalDetails.height}
                    />
                </Box>
            </Box>

            <RadioGroupField
                name='domainSpecificDetails.ebay.optionalDetails.dimensionUnit'
                iconType='Radio'
                options={EbayDimensionUnitsRadioOptions}
                logOnChange
            />

            <FoxTextField
                name='domainSpecificDetails.ebay.requiredDetails.weight'
                label='Weight'
                placeholder='0.00'
                suffix={requiredDetails?.weightUnit ? ebayWeightUnitLabelMap[requiredDetails?.weightUnit] : 'oz'}
                value={requiredDetails?.weight}
                logOnBlur
            />

            <RadioGroupField name='domainSpecificDetails.ebay.requiredDetails.weightUnit' iconType='Radio' options={EbayWeightUnitsRadioOptions} logOnChange />
        </>
    )
}

const styles = {
    containerValidation: {
        marginLeft: 5,
        marginTop: 4
    },
    textError: {
        fontSize: 12,
        lineHeight: 16
    }
}
