import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import user, { selectUser } from '@foxtail-dev/user-clients/dist/lib/redux/reducers/user'
import { CommonImages } from '../../theme/CommonImages'
import { FoxButton } from '../common/FoxButton'
import { FoxImage } from '../common/FoxImage'
import { FoxTypography } from '../common/FoxTypography'
import { UserSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/User'
import { useAppSelector } from '@foxtail-dev/user-clients'
import { useNavigate } from 'react-router-dom'

type ListingMarketSuccessfulLinkNotificationProps = {
    domainName: DomainCommonName
}

export const ListingMarketSuccessfulLinkNotification = (props: ListingMarketSuccessfulLinkNotificationProps): JSX.Element => {
    const { domainName } = props
    const user = useAppSelector(selectUser)
    const navigate = useNavigate()

    const handleCreateListing = async () => {
        navigate('/app/create-listing/new')
    }

    return (
        <>
            <FoxImage src={CommonImages.markets[domainName]} style={styles.image} />
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '10px', marginRight: '10px' }}>
                {Domains[domainName].displayName} is now linked!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px' }}>
                You can now cross list items to {Domains[domainName].displayName} using your account{' '}
                {user?.domainDetails[domainName as DomainCommonName]?.accountName}
            </FoxTypography>
            <FoxButton text='Create a listing now' onFoxClick={{ kind: 'button', onClick: handleCreateListing }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
