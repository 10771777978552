import { useFormikContext } from 'formik'
import { EngagementBoosterSpecificFieldsProps } from '../EngagementBoosterSpecificFieldsMap'
import { EngagementBoostersData } from '../../../screens/engagementBoosters/EngagementBoostersScreen'
import { RadioBoolGroupField, RadioGroupField } from '../../../components/formik/RadioGroupField'
import { getPageOfListings, PoshmarkMakeOfferToLikersOfferDirectiveRadioOptions, selectUserId, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { useCallback, useEffect, useState } from 'react'
import { Logger } from '../../../lib/clients/Logger'
import { AdvancedListingQueries_SearchRequest } from '@foxtail-dev/datacontracts/dist/lib/schemas/communications/http/UserApiTypes'
import { Collapsible } from '../../../components/common/Collapsible'
import { PoshmarkMakeOfferToLikersListingRow } from './PoshmarkMakeOfferToLikersListingRow'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { round } from 'lodash'
import { Divider } from '@mui/material'
import { SkeletonCategoryRows } from '../../../components/skeletons/SkeletonCategoryRows'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { NumberField } from '../../../components/formik/NumberField'

export const PoshmarkMakeOfferToLikersFields = (props: EngagementBoosterSpecificFieldsProps) => {
    const dispatch = useAppDispatch()
    const { values } = useFormikContext<EngagementBoostersData>()
    const config = values.poshmarkMakeOfferToLikers

    const userId = useAppSelector(selectUserId) ?? ''

    const shouldShowPoshmarkListings = config?.offerDirective.kind === 'makeOfferForSubsetOfListings'
    const [allPoshmarkListingIds, setAllPoshmarkListingIds] = useState<string[]>([])
    const [isLoadingListings, setIsLoadingListings] = useState(false)

    const offerDiscountInPercent = round((config?.offerDiscount ?? 0) * 100, 2)

    const convertOfferPeriodToHours = (valueInMs: string) => {
        const number = parseFloat(valueInMs)
        if (isNaN(number)) {
            return '0'
        }

        return round((config?.periodMs ?? 0) / 1000 / 60 / 60, 2).toString()
    }

    const convertOfferPeriodToMs = (value: string) => {
        const number = parseFloat(value)
        if (isNaN(number)) {
            return '0'
        }

        return (number * 60 * 60 * 1000).toString()
    }

    const convertOfferDiscountFromPercentToValue = (valueInPercent: string) => {
        const number = parseFloat(valueInPercent)
        if (isNaN(number)) {
            return '0'
        }

        return round(number * 100, 2).toString()
    }

    const convertOfferDiscountToPercent = (value: string) => {
        const number = parseFloat(value)
        if (isNaN(number)) {
            return '0'
        }

        return (number / 100).toString()
    }

    const fetchPoshmarkListingsAsync = async () => {
        setIsLoadingListings(true)
        try {
            const query: AdvancedListingQueries_SearchRequest = {
                pageNumber: 0,
                pageSize: 10000, // We want to grab all, this functionally works
                request: {
                    activeOnAtLeastOneOfDomains: ['poshmark'],
                    attributeKind: 'active',
                    userId
                }
            }

            const result = await dispatch(getPageOfListings(query)).unwrap()

            setAllPoshmarkListingIds(result.listingIds)

            Logger.I().log({
                level: 'info',
                message: 'Fetched poshmark listings',
                payload: {
                    kind: 'FetchedListings',
                    entry: {
                        result,
                        values
                    }
                }
            })
        } catch (error) {
            Logger.I().log({
                level: 'error',
                message: 'Failed to fetch poshmark listings',
                payload: {
                    kind: 'FailedToFetchListings',
                    entry: {
                        values
                    }
                }
            })
        }
        setIsLoadingListings(false)
    }

    useEffect(() => {
        if (!shouldShowPoshmarkListings) {
            return
        }

        fetchPoshmarkListingsAsync()
    }, [shouldShowPoshmarkListings])

    // renderItem for virtualizedLists must be within a useCallback to avoid it getting re-created
    const renderItem = useCallback(
        ({ index, style }: ListChildComponentProps<string>) => {
            const listingId = allPoshmarkListingIds[index] ?? ''

            // This <div> must be here, the style element is required to prevent flickering
            return (
                <div style={style} key={listingId}>
                    <PoshmarkMakeOfferToLikersListingRow key={listingId} listingId={listingId} />
                    <Divider />
                </div>
            )
        },
        [allPoshmarkListingIds]
    )

    return (
        <>
            <RadioBoolGroupField
                name={'poshmarkMakeOfferToLikers.shouldMakeOfferToLikers'}
                options={[
                    [true, 'Yes'],
                    [false, 'No']
                ]}
                iconType={'Radio'}
                label='Make offer to likers?'
                logOnChange
            />

            <RadioGroupField
                iconType='Radio'
                name='poshmarkMakeOfferToLikers.offerDirective.kind'
                label='Offer kind'
                options={PoshmarkMakeOfferToLikersOfferDirectiveRadioOptions}
                logOnChange
            />

            <NumberField
                name={'poshmarkMakeOfferToLikers.offerDiscount'}
                label='Offer discount'
                labelVariant='body1'
                suffix='%'
                transformText={convertOfferDiscountToPercent}
                transformDisplayedText={convertOfferDiscountFromPercentToValue}
                initialValue={config?.offerDiscount.toString()}
                logOnBlur
            />
            <FoxTypography light variant='body1' sx={{ marginBottom: '16px' }}>
                A $100 item with a {offerDiscountInPercent}% discount will be offered at ${100 - offerDiscountInPercent}
            </FoxTypography>

            <NumberField
                name={'poshmarkMakeOfferToLikers.periodMs'}
                label='Offer period'
                labelVariant='body1'
                suffix='hour(s)'
                transformText={convertOfferPeriodToMs}
                transformDisplayedText={convertOfferPeriodToHours}
                initialValue={config?.periodMs.toString()}
                logOnBlur
            />

            {shouldShowPoshmarkListings && (
                <Collapsible label={'Open to select listings'}>
                    {isLoadingListings ? (
                        <SkeletonCategoryRows />
                    ) : (
                        <FixedSizeList height={550} itemCount={allPoshmarkListingIds.length} itemSize={96} width='100%'>
                            {renderItem}
                        </FixedSizeList>
                    )}
                </Collapsible>
            )}
        </>
    )
}
