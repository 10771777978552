import { SvgIcon } from '@mui/material'

type CheckBoxCheckedIconProps = {
    color?: string
    height?: number
    width?: number
    borderRadius?: number
}

export const CheckBoxCheckedIcon = (props: CheckBoxCheckedIconProps) => {
    const height = props.height ?? 24
    const width = props.width ?? 24
    const borderRadius = props.borderRadius ?? 4
    return (
        <SvgIcon viewBox='0 0 17 10' style={{ height, width }}>
            <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
                <title>checkbox-checked</title>
                <g id='App-design-' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                    <g id='New-listing---details' transform='translate(-20.000000, -203.000000)'>
                        <g id='checkbox-checked' transform='translate(21.000000, 204.000000)'>
                            <rect
                                id='Rectangle'
                                stroke={props.color ?? '#301A36'}
                                strokeWidth='2'
                                fill={props.color ?? '#301A36'}
                                x='0'
                                y='0'
                                width='22'
                                height='22'
                                rx={borderRadius}></rect>
                            <path
                                d='M9.38261435,15 C9.27856059,15 9.17739721,14.9805764 9.07912421,14.9417293 C8.98085122,14.9028822 8.89124937,14.8446115 8.81031866,14.7669173 L5.67136354,11.7534909 C5.50950213,11.5981024 5.42857143,11.4094164 5.42857143,11.1874329 C5.42857143,10.9654493 5.50950213,10.7767633 5.67136354,10.6213749 C5.83322494,10.4659864 6.02688055,10.3882922 6.25233037,10.3882922 C6.47778019,10.3882922 6.67721656,10.4659864 6.8506395,10.6213749 L9.38261435,13.0520945 L15.4524171,7.24167562 C15.6142785,7.08628715 15.8079341,7.00581812 16.0333839,7.00026853 C16.2588337,6.99471894 16.4524893,7.07518797 16.6143507,7.24167562 C16.7762122,7.39706409 16.8571429,7.58575009 16.8571429,7.80773362 C16.8571429,8.02971715 16.7762122,8.21840315 16.6143507,8.37379162 L9.95491004,14.7669173 C9.87397933,14.8446115 9.78437748,14.9028822 9.68610449,14.9417293 C9.58783149,14.9805764 9.48666811,15 9.38261435,15 L9.38261435,15 Z'
                                id='Path'
                                stroke='#FFFFFF'
                                fill='#FFFFFF'
                                fillRule='nonzero'></path>
                        </g>
                    </g>
                </g>
            </svg>
        </SvgIcon>
    )
}
