import Box from '@mui/material/Box/Box'
import { FoxButton } from '../../components/common/FoxButton'
import { OnboardingHeader } from '../../layouts/OnboardingHeader'
import { FoxTextField } from '../../components/formik/FoxTextField'
import { z } from '@foxtail-dev/datacontracts'
import {
    beginVerification,
    CountryCode,
    getTwilioNumber,
    isPhoneUnique,
    Logger,
    normalizePhoneNumberInput,
    PhoneNumberString,
    useAppDispatch
} from '@foxtail-dev/user-clients'
import { Form, Formik, FormikHelpers } from 'formik'
import { useNavigate } from 'react-router-dom'
import { OnboardingSignOutButton } from '../../components/onboarding/OnboardingSignOutButton'
import { useMediaQuery, useTheme } from '@mui/material'

// TODO: Use from user-clients
const US_COUNTRY_CODE: CountryCode = '+1 (US)'

export const EnterPhoneNumberSchema = z.object({
    phoneNumber: z.string(),
    countryCode: z.literal(US_COUNTRY_CODE)
})

export type EnterPhoneNumberSchema = z.infer<typeof EnterPhoneNumberSchema>

export const initialValues: EnterPhoneNumberSchema = {
    phoneNumber: '',
    countryCode: US_COUNTRY_CODE
}

export const EnterPhoneNumberScreen = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const onSubmit = async (values: EnterPhoneNumberSchema, actions: FormikHelpers<EnterPhoneNumberSchema>) => {
        const parseResult = PhoneNumberString.safeParse(values.phoneNumber)
        if (parseResult.success === false) {
            actions.setFieldError('phoneNumber', 'Invalid phone number')
            return
        }
        try {
            const phone = getTwilioNumber(values.countryCode, values.phoneNumber)
            const isUniqueResponse = await dispatch(isPhoneUnique(phone)).unwrap()
            if (!isUniqueResponse.isUnique) {
                actions.setFieldError('phoneNumber', 'Phone number already in use on a different account.')
                return
            }

            const verificationStarted = await dispatch(beginVerification(phone)).unwrap()

            if (!verificationStarted) {
                actions.setFieldError('phoneNumber', 'Verification failed, please try again.')
                return
            }

            Logger.I().log({
                level: 'info',
                message: 'User successfully submitted a phone number to begin verification',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        values: values
                    }
                }
            })
            navigate('/verify-phone-number', { state: { phoneNumber: phone } })
        } catch (error: any) {
            actions.setFieldError('phoneNumber', 'Invalid phone number')
            Logger.I().log(
                {
                    level: 'error',
                    message: 'threw error while trying to submit phone number',
                    payload: {
                        kind: 'EnterPhoneNumberScreen',
                        entry: {
                            values
                        }
                    }
                },
                error
            )
        }
    }

    return (
        <Box sx={[styles.container(isMobile), { alignItems: isMobile ? undefined : 'center' }]}>
            <OnboardingHeader title="Let's get started" subtitle="Enter your phone number. You'll receive a text with a code" />

            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => {
                    const { isSubmitting, values } = formikProps
                    return (
                        <Form>
                            <Box sx={styles.containerForm(isMobile)}>
                                <Box sx={styles.containerField(isMobile)}>
                                    <FoxTextField
                                        inputStyle={styles.fieldCountryCode(isMobile)}
                                        name='countryCode'
                                        label='Country code'
                                        value={values.countryCode}
                                        disabled={true}
                                    />
                                    <FoxTextField
                                        inputStyle={styles.fieldPhoneNumber(isMobile)}
                                        name='phoneNumber'
                                        value={values.phoneNumber}
                                        label='Mobile number'
                                        placeholder='Phone number'
                                        transformText={(newValue) => normalizePhoneNumberInput(newValue, values.phoneNumber)}
                                    />
                                </Box>
                                <Box>
                                    <FoxButton
                                        sx={styles.buttonNext(isMobile)}
                                        primary
                                        onFoxClick={{ kind: 'button', onClick: async () => {} }}
                                        type='submit'
                                        text='Next'
                                        variant='contained'
                                        loading={isSubmitting}
                                    />
                                </Box>
                                <OnboardingSignOutButton />
                                <Box sx={{ height: isMobile ? '40px' : '0px' }} />
                            </Box>
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}

const styles = {
    container: (isMobile: boolean) => ({
        justifyContent: 'center',

        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: isMobile ? '16px' : '32px'
    }),
    containerField: (isMobile: boolean) => ({
        flexDirection: 'row',
        display: 'flex',
        flex: 1,
        marginBottom: isMobile ? '16px' : '32px'
    }),
    containerForm: (isMobile: boolean) => ({
        width: isMobile ? '100%' : '474px',
        display: 'flex',
        flexDirection: 'column'
    }),
    fieldCountryCode: (isMobile: boolean) => ({
        marginRight: '28px',
        marginBottom: isMobile ? '16px' : '0',
        width: '105px'
    }),
    fieldPhoneNumber: (isMobile: boolean) => ({
        width: isMobile ? '100%' : '341px'
    }),
    buttonNext: (isMobile: boolean) => ({
        justifyContent: 'center',
        marginBottom: '32px',
        width: '100%'
    })
}
