import { selectLoggedInDomains, useAppSelector } from '@foxtail-dev/user-clients'
import { Box, Skeleton } from '@mui/material'
import { FlexGrow } from '../common/FlexGrow'

export const CreateListingSkeleton = () => {
    const loggedInMarkets = useAppSelector(selectLoggedInDomains)

    return (
        <Box>
            <Skeleton animation='wave' variant='rounded' sx={{ width: '56px', height: '20px', marginTop: '24px' }} />

            {Array.from({ length: loggedInMarkets.length }, (_, index) => {
                return (
                    <Box key={'createListingSkeleton' + index} sx={{ flexDirection: 'row', display: 'flex', marginTop: '26px', alignItems: 'center' }}>
                        <Skeleton animation='wave' variant='rounded' sx={{ width: '22px', height: '22px' }} />
                        <Skeleton animation='wave' variant='rounded' sx={{ width: '257px', height: '26px', marginLeft: '28px' }} />
                    </Box>
                )
            })}

            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '26px', alignItems: 'center' }}>
                <Skeleton animation='wave' variant='rounded' sx={{ width: '22px', height: '22px' }} />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '464px', height: '26px', marginLeft: '28px' }} />
                <FlexGrow />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '10px', height: '17px' }} />
            </Box>
            <Skeleton animation='wave' variant='rounded' sx={{ width: '30px', height: '20px', marginTop: '24px' }} />

            <Skeleton animation='wave' variant='rounded' sx={{ width: '33px', height: '20px', marginLeft: '7px', marginTop: '14px' }} />
            <Skeleton animation='wave' variant='rounded' sx={{ width: '249px', height: '48px', borderRadius: '10px', marginTop: '5px' }} />

            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '14px', alignItems: 'center', marginLeft: '7px' }}>
                <Skeleton animation='wave' variant='rounded' sx={{ width: '33px', height: '20px', marginRight: '243px' }} />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '58px', height: '20px' }} />
            </Box>

            <Box sx={{ flexDirection: 'row', display: 'flex', marginTop: '5px', alignItems: 'center' }}>
                <Skeleton animation='wave' variant='rounded' sx={{ width: '249px', height: '48px', borderRadius: '10px' }} />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '249px', height: '48px', marginLeft: '22px', borderRadius: '10px' }} />
            </Box>

            <Skeleton animation='wave' variant='rounded' sx={{ width: '33px', height: '20px', marginLeft: '7px', marginTop: '14px' }} />
            <Skeleton animation='wave' variant='rounded' sx={{ width: '53px', height: '22px', marginTop: '32px' }} />
            <Skeleton animation='wave' variant='rounded' sx={{ width: '520px', height: '93px', marginTop: '17px', borderRadius: '10px' }} />

            <Skeleton animation='wave' variant='rounded' sx={{ width: '74px', height: '22px', marginTop: '32px' }} />
            <Skeleton animation='wave' variant='rounded' sx={{ width: '520px', height: '48px', marginTop: '17px', borderRadius: '10px' }} />

            <Skeleton animation='wave' variant='rounded' sx={{ width: '100%', height: '1px', marginTop: '22px' }} />

            <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    marginTop: '16px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginBottom: '18px'
                }}
            >
                <Skeleton animation='wave' variant='rounded' sx={{ width: '141px', height: '48px', borderRadius: '10px' }} />
                <Skeleton animation='wave' variant='rounded' sx={{ width: '141px', height: '48px', marginLeft: '17px', borderRadius: '10px' }} />
            </Box>
        </Box>
    )
}
