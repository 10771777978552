import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { FoxButton } from '../common/FoxButton'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { useNavigate } from 'react-router-dom'

type ListingSoldOutNotificationProps = {
    listing?: ListingSchema
    imageId?: string
}

export const ListingSoldOutNotification = (props: ListingSoldOutNotificationProps): JSX.Element => {
    const { imageId, listing } = props
    const navigate = useNavigate()

    const handleGoToListing = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    const handleCopyAsNew = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    if (!listing || !imageId) {
        return <></>
    }

    return (
        <>
            <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ width: '80px', marginTop: '48px' }} />
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '20px', marginRight: '20px' }}>
                {listing.listingDescription.listingDetails.commonDetails?.title} is sold out!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginLeft: '80px', marginRight: '80px' }}>
                You can relist this item by copying it as a new listing or view the listing summary.
            </FoxTypography>
            <FoxButton text='Copy as new' onFoxClick={{ kind: 'button', onClick: async () => handleCopyAsNew(listing._id) }} primary sx={styles.button} />
            <FoxButton
                text='View listing'
                onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }}
                grey
                sx={styles.buttonViewListing}
            />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    },
    buttonViewListing: {
        marginTop: '16px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
