import { FoxError, IFileSystem, coerceToError } from '@foxtail-dev/datacontracts'
import { Logger } from './Logger'

export const BASE_FOXTAIL_DIRECTORY = 'foxtail'
export const IMAGE_DIRECTORY = 'foxtail-images'

type FilepathWrittenTo = string

// TODO update to use indexedDB so that we only need one file system and can support more than 5MB of storage
export class WebDesktopFileSystem implements IFileSystem {
    private directoryHandle: FileSystemDirectoryHandle

    private constructor(directoryHandle: FileSystemDirectoryHandle) {
        this.directoryHandle = directoryHandle
    }

    static create = async (directoryPrefix: string): Promise<WebDesktopFileSystem> => {
        const directoryHandle = await WebDesktopFileSystem.initDirectory(directoryPrefix)
        return new WebDesktopFileSystem(directoryHandle)
    }

    static initDirectory = async (directoryPrefix: string): Promise<FileSystemDirectoryHandle> => {
        try {
            const opfsRoot = await navigator.storage.getDirectory()
            const directoryHandle = await opfsRoot.getDirectoryHandle(directoryPrefix, { create: true })
            return directoryHandle
        } catch (error) {
            throw new FoxError({
                message: `${WebDesktopFileSystem.name} failed to create directory`,
                error,
                serializableObjects: { directoryPrefix }
            })
        }
    }

    private createDirectory = async (directoryName: string): Promise<FileSystemDirectoryHandle> => {
        return await this.getDeepestDirectoryHandle(directoryName, true)
    }

    private getDeepestDirectoryHandle = async (filePath: string, shouldCreate: boolean): Promise<FileSystemDirectoryHandle> => {
        const filePathsWithoutDoubleSlashes = filePath.replace('//', '/')

        const parts = filePathsWithoutDoubleSlashes.split('/')
        let currentDirectoryHandle = this.directoryHandle

        for (const part of parts.slice(0, -1)) {
            // Skip the file name
            currentDirectoryHandle = await currentDirectoryHandle.getDirectoryHandle(part, { create: shouldCreate })
        }
        return currentDirectoryHandle
    }

    private static getBasename = (filePath: string): string => {
        const parts = filePath.split('/')
        const lastPart = parts[parts.length - 1]
        return lastPart ?? ''
    }

    readFile = async (filePath: string): Promise<string> => {
        try {
            const directoryHandle = await this.getDeepestDirectoryHandle(filePath, false)
            const fileName = WebDesktopFileSystem.getBasename(filePath)
            const fileHandle = await directoryHandle.getFileHandle(fileName)
            const file = await fileHandle.getFile()
            return file.text()
        } catch (error) {
            const improvedError = coerceToError(error)
            throw new FoxError({
                message: `${WebDesktopFileSystem.name} failed to read file + ${improvedError.message}`,
                error,
                serializableObjects: { filePath }
            })
        }
    }

    deleteFile = async (filePath: string): Promise<void> => {
        try {
            const directoryHandle = await this.getDeepestDirectoryHandle(filePath, false)
            const fileName = WebDesktopFileSystem.getBasename(filePath)
            await directoryHandle.removeEntry(fileName)
        } catch (error) {
            throw new FoxError({
                message: `${WebDesktopFileSystem.name} failed to delete file`,
                error,
                serializableObjects: { filePath }
            })
        }
    }

    writeFile = async (filePath: string, contents: string): Promise<FilepathWrittenTo> => {
        try {
            const directoryHandle = await this.createDirectory(filePath)
            const fileName = WebDesktopFileSystem.getBasename(filePath)
            const fileHandle = await directoryHandle.getFileHandle(fileName, { create: true })
            const writable = await fileHandle.createWritable()

            await writable.write(contents)
            await writable.close()

            return filePath
        } catch (error) {
            // max error size should be 50kb
            const truncatedContents = contents.slice(0, 50000)
            const isTruncated = contents.length > 50000

            throw new FoxError({
                message: `${WebDesktopFileSystem.name} failed to write file`,
                error,
                serializableObjects: { filePath, contents: truncatedContents, isTruncated }
            })
        }
    }

    exists = async (filePath: string): Promise<boolean> => {
        try {
            const fileName = WebDesktopFileSystem.getBasename(filePath)
            const directoryHandle = await this.getDeepestDirectoryHandle(filePath, false)
            await directoryHandle.getFileHandle(fileName)
            return true
        } catch {
            return false
        }
    }
}

export class WebMobileFileSystem implements IFileSystem {
    private storageKeyPrefix: string

    private constructor(storageKeyPrefix: string) {
        this.storageKeyPrefix = storageKeyPrefix
    }

    static create = async (storageKeyPrefix: string): Promise<WebMobileFileSystem> => {
        return new WebMobileFileSystem(storageKeyPrefix)
    }

    private getStorageKey = (filePath: string): string => {
        if (filePath.includes('/')) {
            const newFilePath = filePath.replace('/', '-')
            return `${this.storageKeyPrefix}-${newFilePath}`
        }
        return `${this.storageKeyPrefix}-${filePath}`
    }

    readFile = async (filePath: string): Promise<string> => {
        try {
            const storageKey = this.getStorageKey(filePath)
            const fileContents = localStorage.getItem(storageKey)
            if (fileContents === null) {
                throw new Error('File not found')
            }
            return fileContents
        } catch (error) {
            throw new FoxError({
                message: `${WebMobileFileSystem.name} failed to read file`,
                error,
                serializableObjects: { filePath }
            })
        }
    }

    deleteFile = async (filePath: string): Promise<void> => {
        try {
            const storageKey = this.getStorageKey(filePath)
            localStorage.removeItem(storageKey)
        } catch (error) {
            throw new FoxError({
                message: `${WebMobileFileSystem.name} failed to delete file`,
                error,
                serializableObjects: { filePath }
            })
        }
    }

    writeFile = async (filePath: string, contents: string): Promise<string> => {
        try {
            const storageKey = this.getStorageKey(filePath)
            localStorage.setItem(storageKey, contents)
            return filePath
        } catch (error) {
            throw new FoxError({
                message: `${WebMobileFileSystem.name} failed to write file`,
                error,
                serializableObjects: { filePath, contents }
            })
        }
    }

    exists = async (filePath: string): Promise<boolean> => {
        try {
            const storageKey = this.getStorageKey(filePath)
            return localStorage.getItem(storageKey) !== null
        } catch {
            return false
        }
    }
}

export const DummyFs: IFileSystem = {
    deleteFile: async () => {},
    exists: async () => false,
    readFile: async () => '{}',
    writeFile: async () => ''
}
