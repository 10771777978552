import { Box } from '@mui/material'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'
import { Colors, selectBulkCrosslistingIsCancelling, useAppSelector } from '@foxtail-dev/user-clients'
import { SaveIcon } from '../../components/icons/SaveIcon'
import { useNavigate } from 'react-router-dom'

type BulkCrosslistCancellingModalProps = {
    open: boolean
}

export const BulkCrosslistCancellingModal = ({ open }: BulkCrosslistCancellingModalProps) => {
    const navigate = useNavigate()

    const isCancelling = useAppSelector(selectBulkCrosslistingIsCancelling)

    const onClose = () => {
        navigate('/app/listings')
    }

    return (
        <FoxModalDialog
            open={open}
            title={'Cancelling'}
            leftButtonKind={'close'}
            onClose={onClose}
            closeOnBackdropClick={true}
            actions={
                <FoxButton
                    grey
                    text='Go to dashboard'
                    onFoxClick={{ kind: 'internal', to: '/app/listings' }}
                    sx={{ width: '100%', justifyContent: 'center' }}
                />
            }>
            <Box sx={styles.container}>
                {isCancelling ? (
                    <>
                        <SaveIcon color={Colors.light.tertiary} height={70} width={200} />
                        <FoxTypography variant='h6' sx={styles.text}>
                            Your bulk crosslist session is cancelling. <br />
                            You can start a new bulk crosslist once it finishes
                        </FoxTypography>
                    </>
                ) : (
                    <>
                        <SaveIcon color={Colors.light.tertiary} height={70} width={200} />
                        <FoxTypography variant='h6' sx={styles.text}>
                            Your bulk crosslist session has been cancelled.
                        </FoxTypography>
                        <FoxButton
                            primary
                            text='Start over'
                            onFoxClick={{ kind: 'internal', to: '/app/bulk-crosslist' }}
                            sx={{ justifyContent: 'center', marginTop: '12px', width: '141px' }}
                        />
                    </>
                )}
            </Box>
        </FoxModalDialog>
    )
}

const styles = {
    container: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    text: {
        color: Colors.light.tertiary,
        marginTop: '48px',
        textAlign: 'center',
        marginLeft: '30px',
        marginRight: '30px'
    }
}
