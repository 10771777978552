import { Colors, Logger } from '@foxtail-dev/user-clients'
import { Box, Link } from '@mui/material'
import { FoxButton } from '../common/FoxButton'
import { FoxTypography } from '../common/FoxTypography'
import { ExtensionIcon } from '../icons/ExtensionIcon'
import * as Intercom from '@intercom/messenger-js-sdk'

export const MustUseChromeToLinkMarketPlaces = (): JSX.Element => {
    const onReadMore = () => {
        Logger.I().log({
            level: 'info',
            message: 'User read more on Must use Chome to link market places',
            payload: {
                kind: 'UserAction',
                entry: {
                    screen: 'MarketConnectionScreen'
                }
            }
        })
        Intercom.showArticle('9471939')
    }
    return (
        <Box sx={styles.container}>
            <Box sx={styles.containerExtensionIcon}>
                <ExtensionIcon height={165} width={316} />
            </Box>
            <FoxTypography variant='subtitle1' sx={styles.textTitle}>
                You must use Google Chrome to link marketplaces
            </FoxTypography>
            <FoxTypography variant='body1' sx={styles.textBody}>
                Please download Google Chrome and log back in
                <br />
                <Link sx={{ cursor: 'pointer' }} onClick={onReadMore}>
                    Read more.
                </Link>
            </FoxTypography>

            <FoxButton
                onFoxClick={{
                    kind: 'external',
                    href: 'https://www.google.com/chrome/browser-tools/'
                }}
                text='Download Chrome'
                primary
                sx={styles.button}
            />
        </Box>
    )
}

const styles = {
    container: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '10px',
        borderColor: Colors.light.divider,
        marginLeft: '32px',
        marginRight: '254px',
        height: '526px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    containerExtensionIcon: {
        marginTop: '80px',
        marginBottom: '40px'
    },
    button: {
        color: 'white',
        marginTop: '32px',
        width: '167px',
        justifyContent: 'center'
    },
    textTitle: {
        lineHeight: '24px',
        marginTop: '38px',
        textAlign: 'center'
    },
    textBody: {
        marginTop: '11px',
        textAlign: 'center',
        marginHorizontal: '32px'
    }
}
